import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Logo from "../../assets/images/pngs/logo.png";
import LogoutSVG from "../../assets/images/svgs/logout.svg"
import HomeSvg from "../../assets/images/svgs/sidemenu/home.svg";
import TripsSvg from "../../assets/images/svgs/sidemenu/trips.svg";
import WalletSvg from "../../assets/images/svgs/sidemenu/wallet.svg";
import SettingsSvg from "../../assets/images/svgs/sidemenu/settings.svg";
import DiscountSvg from "../../assets/images/svgs/sidemenu/discounts.svg";
import HelpCenterSvg from "../../assets/images/svgs/sidemenu/help-center.svg";
import HomeSelectedSvg from "../../assets/images/svgs/sidemenu/home-selected.svg";
import TripsSelectedSvg from "../../assets/images/svgs/sidemenu/trips-selected.svg";
import WalletSelectedSvg from "../../assets/images/svgs/sidemenu/wallet-selected.svg";
import SettingsSelectedSvg from "../../assets/images/svgs/sidemenu/settings-selected.svg";
import DiscountSelectedSvg from "../../assets/images/svgs/sidemenu/discounts-selected.svg";
import HelpCenterSelectedSvg from "../../assets/images/svgs/sidemenu/help-center-selected.svg";

import { TStoreState } from "../../modules/store";
import ActionPromptModal from "../modals/ActionPromptModal";
import { toTitleCase } from "../../utils/helpers/logicHelper";
import { removeAuthentication } from "../../store/slices/auth.slice";
import { MenuTypeInitialStatus } from "../../utils/helpers/constants";
import { ActionPromptEnum, MenuType, SubMenuType } from "../../modules/enums";


type Props = {
  menu: MenuType,
  subMenu?: SubMenuType
};

const SideMenu = ({ menu, subMenu }: Props) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(menu);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [dropdownMenu, setDropdownMenu] = useState(MenuTypeInitialStatus);
  const [logoutRequestRender, setLogoutRequestRender] = useState(false);

  const userData = useSelector((state: TStoreState) => state.auth.userProfile)
  const sideMenuMode = useSelector((state: TStoreState) => state.config.sideMenuMode)
  const handleLogout = async () => removeAuthentication(navigate)
  const helpLink = `https://docs.google.com/document/d/1Jnbep3bjFjpWu3zCFqqqWMRCxNa3jkKt3ri_OtW9fqA`

  useEffect(() => {
    if (!dropdownMenu[active]) handleMenuUpdate(active);
  }, [active]);


  const handleMenuUpdate = (menuOption: MenuType) => {
    if (menuOption === active) setToggleMenu(!toggleMenu);
    else setToggleMenu(true);

    const dropDownData = { ...MenuTypeInitialStatus, [menuOption]: true };
    setActive(menuOption);
    setDropdownMenu(dropDownData);
  }

  const logoutModal = logoutRequestRender ?
    <ActionPromptModal type={ActionPromptEnum.LOGOUT} onchange={() => setLogoutRequestRender(false)}
    setSuccessfulResponse={handleLogout}/> : null;

  return (
    <aside className={`main-sidebar main-sidebar-sticky side-menu ${!sideMenuMode && "d-none d-lg-block"}`}>
      {logoutModal}
      <div className="sidemenu-logo">
        <NavLink className="main-logo" to="/"><img src={Logo} alt="logo" width={70} height={26} /></NavLink>
      </div>
      <div className="main-sidebar-body">
        <ul className="nav">
          <li className={`nav-item ${menu === MenuType.HOME && "active"}`}>
            <NavLink className="nav-link" to="/">
              <span>
                <img src={menu === MenuType.HOME ? HomeSelectedSvg : HomeSvg}
                  alt={MenuType.HOME.toUpperCase()} width={24} height={24} />
                <span>Dashboard</span>
              </span>
            </NavLink>
          </li>
          <li className={`nav-item nav-divider ${menu === MenuType.WALLET && "active"}`}>
            <NavLink className="nav-link" to={`/${MenuType.WALLET}`}>
              <span>
                <img src={menu === MenuType.WALLET ? WalletSelectedSvg : WalletSvg}
                  alt={MenuType.WALLET.toUpperCase()} width={24} height={24} />
                <span>Wallet</span>
              </span>
            </NavLink>
          </li>
          <li className={`nav-item nav-divider ${menu === MenuType.TRIPS && "active"}`}>
            <NavLink className="nav-link" to={`/${MenuType.TRIPS}`}>
              <span>
                <img src={menu === MenuType.TRIPS ? TripsSelectedSvg : TripsSvg}
                  alt={MenuType.TRIPS.toUpperCase()} width={24} height={24} />
                <span>Trips</span>
              </span>
            </NavLink>
          </li>
          {/*
					<li className="nav-item nav-divider" onClick={() => handleMenuUpdate(MenuType.TRIPS)}>
						<p
							className={`nav-link ${menu === MenuType.TRIPS && 'active-parent-menu'} ${(dropdownMenu[MenuType.TRIPS] && toggleMenu) && "show"}`}>
							<span>
								<img src={menu === MenuType.TRIPS ? TripsSelectedSvg : TripsSvg}
										 alt={MenuType.TRIPS.toUpperCase()}/>
								<span>Trips</span>
							</span>

							{(dropdownMenu[MenuType.TRIPS] && toggleMenu) ?
								<i className="zmdi zmdi-chevron-up nav-icon"/> : <i className="zmdi zmdi-chevron-down nav-icon"/>}
						</p>

						<ul className={`nav-submenu list-unstyled ${(dropdownMenu[MenuType.TRIPS] && toggleMenu) ? "show" : "hide"}`}>
							<li
								className={`nav-item ${(menu === MenuType.TRIPS && subMenu === SubMenuType.QUOTE_LEADS) && "active"}`}>
								<NavLink className="nav-submenu-link" to={`/${MenuType.TRIPS}/${SubMenuType.QUOTE_LEADS}`}>
									<span>Quotes Leads</span>
								</NavLink>
							</li>
						</ul>
					</li>
        */}

          <li className={`nav-item ${menu === MenuType.PRICING_CONFIGURATION && "active"}`}>
            <NavLink className="nav-link" to={`/${MenuType.PRICING_CONFIGURATION}`}>
              <span>
                <img src={menu === MenuType.PRICING_CONFIGURATION ? DiscountSelectedSvg : DiscountSvg}
                  alt={MenuType.PRICING_CONFIGURATION.toUpperCase()} width={24} height={24} />
                <span>Pricing & Discounts</span>
              </span>
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="main-sidebar-footer">
        <ul className="nav">
          <li className={`nav-item ${menu === MenuType.SETTINGS && "active"}`}>
            <NavLink className="nav-link" to={`/${MenuType.SETTINGS}`}>
              <span>
                <img src={menu === MenuType.SETTINGS ? SettingsSelectedSvg : SettingsSvg}
                  alt={MenuType.SETTINGS.toUpperCase()} width={20} height={20} />
                <span>Profile & Settings</span>
              </span>
            </NavLink>
          </li>

          <li className={`nav-item ${menu === MenuType.HELP_CENTER && "active"}`}>
            <a className="nav-link" href={helpLink} target="_blank" rel="noreferrer">
              <span>
                <img src={menu === MenuType.HELP_CENTER ? HelpCenterSelectedSvg : HelpCenterSvg}
                  alt={MenuType.HELP_CENTER.toUpperCase()} width={20} height={20} />
                <span>Help Center</span>
              </span>
            </a>
          </li>

          {/*			<li className={`nav-item ${menu === MenuType.REFER_EARN && "active"}`}>
						<NavLink className="nav-link" to={`/${MenuType.REFER_EARN}`}>
							<span>
								<img src={menu === MenuType.REFER_EARN ? ReferSelectedSvg : ReferSvg}
										 alt={MenuType.REFER_EARN.toUpperCase()} width={20} height={20}/>
								<span>Refer Family & Friends</span>
							</span>
						</NavLink>
					</li>*/}
        </ul>

        <div className="profile">
          <div className="main-profile-menu bg-light-orange">
            <h6 className="d-flex align-items-center justify-content-center">
              {(userData && userData['first_name']) ? toTitleCase(userData['first_name'][0]) : "-"}
              {(userData && userData['last_name']) ? toTitleCase(userData['last_name'][0]) : "-"}
            </h6>
          </div>
          <div className="profile-info">
            <p className="fw-bold text-truncate">
              {(userData && userData['first_name']) ? toTitleCase(userData['first_name']) : "-"}
              {" "}
              {(userData && userData['last_name']) ? toTitleCase(userData['last_name']) : "-"}
            </p>
            <p className="text-gray-600 text-truncate">
              {(userData && userData['email']) ? userData['email'].toLowerCase() : "-"}
            </p>
          </div>
          <img src={LogoutSVG} alt="Logout" className="d-flex justify-content-end cursor-pointer"
            onClick={() => setLogoutRequestRender(true)} />
        </div>
      </div>
    </aside>
  )
}

export default SideMenu;