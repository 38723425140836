import { useNavigate } from "react-router-dom";
import NotfoundSVG from "../../../assets/images/svgs/404-Error.svg";
import PrimaryButton from "../../../components/buttons/PrimaryButton";

const Error404 = () => {
	const navigate = useNavigate();

	const handleClick = () => navigate("/");

	return (
		<div className="error-body min-vh-100">
			<img src={NotfoundSVG} alt="NOT-FOUND" width={540} height={350} />
			<p className="error-title">Page not found</p>
			<p className="error-text">
				Oops! The page you're looking for cannot be found. It seems like the
				link might be broken or the page has been removed. Please double-check
				the URL or return to the homepage
			</p>
			<PrimaryButton btn={{
				text: "Go to Dashboard",
				btnClassName: 'px-4 mt-3',
				clickFn: handleClick
			}} />
		</div>
	);
};

export default Error404;
