import { MenuType } from "../../modules/enums";
import { MenuTypeInitialStatusType } from "../../modules/component";

export const AppInfo = {
	APP_NAME: 'truQ WebApp',
	ORG_NAME: 'truQ Services',
	APP_VERSION: 'v1',
	ORGANISATION: 'TRU_Z9WE',
	STAGING_ORGANISATION: 'TRU_H0D4',
	DODGE_BASE_URL: 'https://dodge.truq.it',
	DODGE_STAGING_BASE_URL: 'https://dodge-apis-staging-c2btvqbhlq-uc.a.run.app',
	BACKOFFICE_BASE_URL: `https://backoffice-production-2ric45myja-uc.a.run.app/`,
	BACKOFFICE_STAGING_BASE_URL: `https://backoffice-staging-2ric45myja-uc.a.run.app/`
}

export const AppKeys = {
	TOKEN: 'T7uQM3rCH4nT-0987Tt1V13wMT4-987to89nC0D30f1529CUlpCyvNmm8SCR36Gp8a4r5hyP41k3KAxfC5JU',
	USER_DOM: 'T7uQM3rCH4nT-0987Tt1V13wMT4-R87tvNmm40f48CR33KC0lpCyGp19C6kSxfCa1A8GyPD3952r5hUon85JU',
	GOOGLE_AUTH_KEY: "150388206789-nan9mpjg7q03q5uh21jah794l4iudibq.apps.googleusercontent.com",
	COOKIE_EXPIRY_PERIOD: 1,
}

export const Loading = {
  ERROR: 'ERROR',
  FAILED: 'FAILED',
  CREATED: 'CREATED',
  SUCCESS: 'SUCCESS',
  FETCHING: 'FETCHING',
  NO_CONTENT: 204,
}

export const MenuTypeInitialStatus: Partial<MenuTypeInitialStatusType>  = {
	[MenuType.HOME]: false,
	[MenuType.TRIPS]: false,
	[MenuType.WALLET]: false,
	[MenuType.SETTINGS]: false,
	[MenuType.REFER_EARN]: false,
	[MenuType.HELP_CENTER]: false,
	[MenuType.PRICING_CONFIGURATION]: false,
};