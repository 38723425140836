import { TImage } from "../../modules/component";

type Props = Pick<TImage, 'imgSrc'> & {
  text?: string,
  description: string
};

const EmptyTable = ({ imgSrc, text, description }: Props) => (
  <div className="row">
    <div className="col-12 text-center">
      <img src={imgSrc} width={400} alt="No data found" className="mx-auto d-block"/>
      <h3>{text}</h3>
      <p className="text-muted text-center mb-3">{description}</p>
    </div>
  </div>
);

export default EmptyTable;
