import { useState } from 'react';
import DatePicker from "react-datepicker";

type IDatePickerProps = {
	name?: string,
	label?: string,
	className?: string,
	dateFormat?: string,
	timeFormat?: string,
	placeholder?: string,
	setMinDate?: Date,
	setMaxDate?: Date,
	selected?: Date | null,
	timeIntervals?: number,
	withPortal?: boolean,
	showTimeSelect?: boolean,
	isLabelRequired?: boolean,
	showYearDropdown?: boolean,
	filterTime?: boolean,
	getDate: (value: Date | null) => void,
};

const DatePickerHook = (props: IDatePickerProps) => {
	const [date, setDate] = useState<Date | null>(null);

	const updateGetDate = (date: Date | null) => {
		props.getDate(date);
		setDate(date);
	}

	const filterPassedTime = (time: Date): boolean => {
		const currentDate = new Date();
		const selectedDate = new Date(time);
		return currentDate.getTime() < selectedDate.getTime();
	};


	return (
		<div className="form-floating react-datepicker">
			<DatePicker selected={props.selected ? props.selected : date}
				onChange={(date: Date | null) => updateGetDate(date)}
				className={props.className}
				placeholderText={props.placeholder ? props.placeholder : "Select date"}
				dateFormat={props.dateFormat ? props.dateFormat : "dd/MM/yyyy"}
				timeFormat={props.timeFormat ? props.timeFormat : "HH:mm"}
				timeIntervals={props.timeIntervals ? props.timeIntervals : 10}
				showTimeSelect={props.showTimeSelect}
				showYearDropdown={props.showYearDropdown}
				dropdownMode='select'
				minDate={props.setMinDate}
				maxDate={props.setMaxDate}
				withPortal={props.withPortal}
				filterDate={props.filterTime ? filterPassedTime : undefined}
			/>
			{props.label &&
				<label>
					{props.label}{props.isLabelRequired && <span className="text-danger ms-1">*</span>}
				</label>
			}
		</div>

	);
}

export default DatePickerHook;
