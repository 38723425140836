import { Fragment, useState } from "react";
import { InputEnum } from "../../modules/enums";
import { TnputType } from "../../modules/component";
import EyeSVG from "../../assets/images/svgs/eye.svg";
import { toTitleCase } from "../../utils/helpers/logicHelper";
import EyeHideSVG from "../../assets/images/svgs/eye-slash.svg";
import { PasswordRules } from "../../utils/helpers/objectHelper";

type Props = {
  name: string,
  value: string | undefined,
  isValidatorRequired?: boolean,
  setInput: (value: string) => void,
  setErrorState?: (value: boolean) => void,
}

const PasswordInput = ({ name, value, isValidatorRequired, setInput, setErrorState }: Props) => {
  const [passwordType, updatePasswordType] = useState("password");
  const [validatorState, setValidatorState] = useState(false);

  const togglePassword = (type: TnputType) => {
    updatePasswordType(type);
    const inputElement = document.getElementById(name) as HTMLInputElement;
    if (inputElement) {
      if (passwordType === InputEnum.PASSWORD || passwordType === InputEnum.TEXT) inputElement.type = type;
    }
    updatePasswordType(type);
  }

  const updtePassword = (value: string) => {
    if (isValidatorRequired) {
      for (let rule of PasswordRules) {

        if (!value.match(rule.pattern)) {
          if (isValidatorRequired && setErrorState) setErrorState(true);
          setValidatorState(true);
          break;
        } else {
          if (isValidatorRequired && setErrorState) setErrorState(false);
          setValidatorState(false);
        }
      }
    }
    setInput(value);
  }

  return (
    <Fragment>
      <div className="form-floating mb-4">
        <input type="password" className={`form-control form-lightblue ${(isValidatorRequired && validatorState) && "is-invalid"}`}
          value={value} id={name} placeholder={name}
          onChange={e => updtePassword(e.target.value)} />
        <label>{name ? toTitleCase(name) : ""}</label>
        {passwordType === "password" ?
          <img src={EyeSVG} onClick={() => togglePassword("text")} alt="show-password" className="form-icon" /> :
          <img src={EyeHideSVG} onClick={() => togglePassword("password")} alt="hide-password"
            className="form-icon" />
        }
      </div>
      {(isValidatorRequired && validatorState) &&
        <div className="text-black-5 mb-4">
          <small className="d-block">At least one number</small>
          <small className="d-block">At least 8 characters</small>
          <small className="d-block">At least one uppercase letter</small>
          <small className="d-block">At least one lowercase letter</small>
        </div>
      }
    </Fragment>
  );
}

export default PasswordInput;