import {Fragment, MouseEvent, useState} from "react";
import {NavLink} from "react-router-dom";
import GrayButton from "../buttons/GrayButton";
import Next from "../../assets/images/svgs/right-arrow.svg";
import Previous from "../../assets/images/svgs/left-arrow.svg";

type Props = {
  size: number,
  count: number,
  pageSize: number,
  pageIndex: number,
  setPageIndex: (filter: number) => void
}
const Pagination = ({size, count, pageIndex, pageSize, setPageIndex}: Props) => {

  const [breakPoint, setBreakPoint] = useState(3)
  const [innerRender, setInnerRender] = useState(false)
  const canPrevious = pageIndex > 1, canNext = Math.ceil(count / size) !== pageIndex
  const pageLength = Math.ceil(count / pageSize);

  const goToPageIndex = (event: MouseEvent<HTMLAnchorElement>, index: number) => {
    event.preventDefault();
    if (pageIndex !== index) setPageIndex(index);
  }

  const goToEllipses = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    let newBreakPoint = breakPoint + 1;
    setBreakPoint(newBreakPoint);
    setPageIndex(newBreakPoint);
    setInnerRender(true);
  }

  const createPageRenderButtons = (buttonSize: number) => {
    let lastPageIndex = Math.ceil(count / size), renderBreakPoint = buttonSize
    let preRender = [], lastPreIndex, postRender = [];
    for (let i = 1; i <= lastPageIndex; i++) {
      lastPreIndex = i;
      if (i > renderBreakPoint) break;
      preRender.push(
        <li key={i} className={`page-item ${i === pageIndex ? "active" : ""}`}>
          <NavLink to="/" onClick={event => goToPageIndex(event, i)} className="page-link">{i}</NavLink>
        </li>
      )
    }

    if (Math.ceil(count / size) > renderBreakPoint) postRender.push(
      <>
        <li key={lastPreIndex} className="page-item">
          <NavLink to="/" onClick={event => goToEllipses(event)} className="page-link">...</NavLink>
        </li>
        <li key={lastPageIndex} className="page-item">
          <NavLink to="/" onClick={event => goToPageIndex(event, lastPageIndex)} className="page-link">
            {lastPageIndex}
          </NavLink>
        </li>
      </>
    )

    return [...preRender, ...postRender]
  }

  return (
    <Fragment>
      <div className="pagination-body">
        <p className="pagination-info text-gray-500">Page {pageIndex} of {pageLength}</p>
        <nav className="pagination">
          <ul className="nav-pages">
            {innerRender ? createPageRenderButtons(breakPoint) : createPageRenderButtons(3)}
          </ul>
        </nav>
        <nav className="nav-action">
          <GrayButton btn={{
            text: 'Previous',
            btnClassName: `btn-sm ${canPrevious ? '' : 'disabled'}`,
            outline: true,
            image:{
              imgSrc: Previous,  
              imgAlt: "go-back",
              width:"18",
              height:"18"
            },
            clickFn: (e) => goToPageIndex(e, pageIndex - 1)
          }} />
          <GrayButton btn={{
            text: 'Next',
            btnClassName: `btn-sm ${canNext ? '' : 'disabled'}`,
            outline: true,
            image:{
              imgSrc: Next,  
              imgAlt: "go-forward",
              width:"18",
              height:"18"
            },
            clickFn: (e) => goToPageIndex(e, pageIndex + 1)
          }} />
        </nav>
      </div>
    </Fragment>
  );
};

export default Pagination;
