import { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import CloseButton from "../buttons/CloseButton";
import PrimaryButton from "../buttons/PrimaryButton";
import { InputValidator } from "../../modules/enums";
import { Loading } from "../../utils/helpers/constants";
import { getBaseUrl } from "../../utils/services/axios.service";
import BookingService from "../../utils/services/booking.service";
import { copyToClipboard } from "../../utils/helpers/logicHelper";
import { emailValidator, phoneValidator } from "../../utils/services/validator.service";

type Props = {
	business: TBusiness,
	onchange: () => void,
};

type TripInitiationPayload = {
	email: string;
  	phone: string;
  	business: string;
  	coupon?: string;
}

const TripInitiationModal = ({ onchange, business }: Props) => {
	const organisation = getBaseUrl()['organisation'];
	const [show, setShow] = useState(true);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [errorState, setErrorState] = useState({ email: false, phone: false });
	const couponConfig = business['merchant_configuration'] ? business['merchant_configuration'].find(each => each.name === "coupon") : "";
	const isInvalid = !email || emailValidator(email).validState === InputValidator.INVALID || errorState.email || errorState.phone;

	const handleClose = () => {
		setShow(false);
		onchange();
	};

	const handleFieldValidation = (data: string, type: string) => {
		if (type === 'EMAIL') {
			const validatedEmail = emailValidator(data);
			setEmail(validatedEmail.validEmail);
			const isEmailValid = validatedEmail.validState !== InputValidator.VALID;
			setErrorState({ ...errorState, email: isEmailValid });
		} else if (type === 'PHONE') {
			data = data.replace(/[^0-9/+]/gi, "");
			const validatedPhone = phoneValidator(data);
			setPhone(data);
			const isPhoneInvalid = !(validatedPhone === InputValidator.VALID || !data);
			setErrorState({ ...errorState, phone: isPhoneInvalid });
		}
	};

	const handleTripInitiation = async () => {
		setLoading(true);
		let payload: TripInitiationPayload = { email, phone, business: business.reference };
		if (couponConfig && couponConfig['configuration'] && couponConfig['configuration']['code']) payload.coupon = couponConfig['configuration']['code'];

		const { data: responseData } = await BookingService.initiateTripQuote(payload);
		if (!responseData || !responseData.status || responseData.status !== Loading.SUCCESS || !responseData.data) toast.error(responseData.message);
		else {
			const baseURL = organisation === "TRU_H0D4" ? "dodge-checkout-beta.vercel.app" : "checkout.siju.it";
			const checkoutUrl = `${baseURL}/${responseData.data.quote.reference}`;
			await copyToClipboard(checkoutUrl);
			toast.success("Checkout url has been copied to clipboard!");
			handleClose();
		}
		setLoading(false);
	}

	return (
		<Modal show={show} backdrop="static" keyboard={false} centered>
			<Modal.Body>
				<div className="row">
					<div className="col-12 mt-0 mb-3"><CloseButton clickFn={handleClose} /></div>
				</div>
				<div className="row mb-5">
					<div className="col-sm-10 offset-sm-1 text-center">
						<h4 className="text-primary">Initiate Customer Trip</h4>
						<p className="text-black-3 text-center mt-1 mb-4 mx-0 mx-sm-4">
							Initiate trip by and share the link with your unique code to customers
						</p>

						<div className="col-12 mb-3">
							<div className="form-floating">
								<input type="text" inputMode="text" id="email" placeholder="email"
									className={`form-control form-lightblue ${(errorState && errorState.email) && 'is-invalid'}`}
									onChange={event => handleFieldValidation(event.target.value, 'EMAIL')} />
								<label htmlFor="email">Email <span className="text-danger">*</span></label>
							</div>
						</div>
						<div className="col-12 mb-4">
							<div className="form-floating">
								<input type="tel" inputMode="tel" id="phone" placeholder="phone"
									className={`form-control form-lightblue ${(errorState && errorState.phone) && 'is-invalid'}`}
									onChange={event => handleFieldValidation(event.target.value, 'PHONE')} />
								<label htmlFor="email">Phone</label>
							</div>
						</div>
						<PrimaryButton 
							btn={{
								text: 'Initiate Trip',
								btnClassName: 'btn-block',
								loadState: loading,
								disabledState: isInvalid,
								clickFn: handleTripInitiation
							}}
						/>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default TripInitiationModal;
