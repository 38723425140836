import { TGenericObj } from "../../../../modules/component";
import { formatAmountToString } from "../../../../utils/helpers/logicHelper";

type TCityConfig = {
  [key: string]: {
    [key: string]: TGenericObj[]
  }[]
};

type Props = {
  item: string,
  currency: string,
  patchObject: TCityConfig,
};

const CityConfig = ({ patchObject, item, currency }: Props) => (
  <div className="row">
    {patchObject && Object.entries(patchObject).length > 0 && patchObject[item].length > 0 && patchObject[item].map((priceItem, priceIndex) =>
      <div key={priceIndex} className="col-sm-6 col-xl-4 col-xxl-3">
        <ul className="tree bg-white">
          <li className="branch p-3">
            <p className="fs-6">{Object.keys(priceItem)}</p>
            {priceItem[Object.keys(priceItem)[0]].map((nestedItem: any, nestedIndex: string | number) =>
              <ul key={nestedIndex}>
                {Object.keys(nestedItem).map((key, index) =>
                  <li key={index} className="pos-relative px-2 bg-light-subtle">
                    <span>{key}</span>
                    <div className="input-group mb-4">
                      <span className="input-group-text">{currency}</span>
                      <input type="text" placeholder={nestedIndex.toString()} readOnly={true}
                        className="form-control bg-light px-2"
                        value={formatAmountToString(nestedItem[key])} />
                    </div>
                  </li>
                )}
              </ul>
            )}
          </li>
        </ul>
      </div>
    )}
  </div>
);
export default CityConfig;