import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, HeaderGroup, Table, useReactTable } from '@tanstack/react-table';
import NotFoundSVG from "../../assets/images/svgs/empty-state.svg";
import Pagination from "./Pagination";
import EmptyTable from "./EmptyTable";

type Props<TData> = {
  columns: ColumnDef<TData, string>[],
  data: TData[],
  size: number,
  count: number,
  pageIndex: number,
  setPageIndex: (filter: number) => void
}

const PaginationTable = <TData extends object>({ columns, data, size, count, pageIndex, setPageIndex }: Props<TData>) => {

  const table: Table<TData> = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <div className="table-responsive">
        <table className="table text-nowrap">
          <thead>
            {table.getHeaderGroups().map((headerGroup: HeaderGroup<TData>) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} className={header.column.getCanSort() ? 'pointer-event' : ''}>
                    {header.isPlaceholder ? null : (
                      <span
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc' ? 'Sort ascending'
                              : header.column.getNextSortingOrder() === 'desc' ? 'Sort descending' : 'Clear sort' : ""
                        }>
                        {flexRender(header.column.columnDef.header, header.getContext())} {header.column.getCanSort()}
                        {header.column.getCanSort() ?
                          {
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? "" : null}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {(data && data.length > 0) ?
        <Pagination size={size} count={count} pageIndex={pageIndex} 
          pageSize={data.length < size ? data.length : size}
          setPageIndex={setPageIndex} /> :
        <EmptyTable imgSrc={NotFoundSVG} description="No resource available" />
      }
    </>
  )
}

export default PaginationTable