import { TripSystemStatus } from "../../modules/enums";
import { MockUser, ResponseObj } from "./config";

export const MockQuote: TTripQuote = {
	base_fare: "",
	base_platform: "",
	discount_amount: "",
	charge: "",
	checkout_url: "",
	cost_per_kilometer: "",
	currency: "",
	estimated_insurance: "",
	estimated_value: "",
	insurance_premium: "",
	payment_option: "",
	platform_commission_type: "",
	platform_commission_value: "",
	platform_fee: "",
	reference: "",
	saas_charge: "",
	saas_vat: "",
	sub_total: "",
	total_charge: "",
	total_vat: "",
	vat_type: "",
	vat_value: "",
	waybill_number: ""
}

export const MockTrip: TTrip = {
	...ResponseObj,
	user: MockUser,
	confirmation: null,
	reference: "",
	is_interoperability: false,
	driver: null,
	category_size: {
		category: "",
		id: "",
		tonnage: null
	},
	trip_type: "",
	system_trip_type: "",
	source: {
		address: "",
		latitude: 0,
		longitude: 0,
		state: "",
		city: "",
	},
	destination: {
		address: "",
		latitude: 0,
		longitude: 0,
		state: "",
		city: "",
		stops: { number: 0 },
	},
	extras: null,
	is_transit: false,
	is_completed: false,
	distance: 0,
	created_at: null,
	status: "",
	system_status: TripSystemStatus.INITIATED,
	is_insured: false,
	estimated_value: "",
	insurance_status: false,
	is_paid: false,
	quote: MockQuote,
	vehicle_payment: {
		currency: "",
		is_paid: false,
		vehicle_payment: "",
		vehicle_registration_number: null
	},
	user_journey: [
		{
			latitude: null,
			longitude: null,
			message: "",
			status: '',
			system_status: "",
			time: null
		}
	],
	business_tripset: null,
	is_ims: false,
	is_bulk_ims: false,
	ims_orderset: null,
	is_updated_analytics: false,
	is_paid_saas_commission: false,
	is_paid_organisation_commission: false,
	scheduled_date: null,
}