import { AxiosResponse } from 'axios';
import {dodgeInstance as Axios} from './axios.service';

const ProfileService = {

  getOverview(): Promise<AxiosResponse< TApiResponse<TInsight>, any>>  {
    return Axios({
      method: 'GET',
      url: `/locale-service/analytics/overview/user`,
    });
  },
  getProfile(): Promise<AxiosResponse<TApiResponse<TProfile>, any>>  {
    return Axios({
      method: 'GET',
      url: `locale-service/tenants/profile`
    });
  },
  getBusinesses(): Promise<AxiosResponse<TApiResponse<TBusiness[]>, any>>  {
    return Axios({
      method: 'GET',
      url: `entity-service/business/business-profile/`
    });
  },
  createBusinessProfile(payload: Partial<TBusiness>): Promise<AxiosResponse<TApiResponse<TBusiness>, any>>  {
    return Axios({
      method: 'POST',
      url: `entity-service/business/business-profile/`,
      data: payload,
    });
  },
  getOrganisation(filter: string): Promise<AxiosResponse<TApiResponse<TOrgainsation>, any>>  {
    return Axios({
      method: 'GET',
      url: `locale-service/tenants/organisation/${filter}`
    });
  },
  changePassword(payload: { oldPassword: string, password: string }): Promise<AxiosResponse<TApiResponse<TProfile>, any>>  {
    return Axios({
      method: 'PATCH',
      url: `locale-service/tenants/change-password`,
      data: payload
    })
  },
  updateProfile(userId: string, payload: Partial<TProfile>): Promise<AxiosResponse<TApiResponse<TProfile>, any>>  {
    return Axios({
      method: 'PATCH',
      url: `entity-service/user/user-profile/${userId}/updateUser/`,
      data: payload,
    })
  },
  changeProfilePicture(userId: string, payload: FormData): Promise<AxiosResponse<TApiResponse<TProfile>, any>>  {
    return Axios({
      method: 'POST',
      url: `entity-service/user/user-profile/${userId}/profilePicture/`,
      data: payload,
      headers: {'Content-Type': 'multipart/form-data'}
    });
  },
  updateBusinessProfile(businessId: string, payload: Partial<TBusiness>): Promise<AxiosResponse<TApiResponse<TBusiness>, any>> {
    return Axios({
      method: 'PATCH',
      url: `entity-service/business/business-profile/${businessId}/`,
      data: payload,
    })
  },
  updateNotificationMatrices(payload: any): Promise<AxiosResponse<TApiResponse<TProfile>, any>>  {
    return Axios({
      method: 'PUT',
      url: `/locale-service/messaging/user/notification-matrix`,
      data: payload
    });
  },
};

export default ProfileService;
