import { MockWalletConfig } from './wallet';
import { MockNotificationMatrix, MockUser, ResponseObj } from "./config";

// export const MockBusinessProfile: TBusiness = {
//     ...ResponseObj,
//     user: MockUser,
//     config: MockBusinessConfig,
//     reference: '',
//     is_merchant: false,
//     account_manager: MockUser,
//     merchant_configuration: [MockMerchantConfiguration]
// }

export const MockOrgainsationProfile: TOrgainsation = {
    ...ResponseObj,
    bio: '',
    favicon: '',
    logo: '',
    name: '',
    org_flow: '',
    reference: '',
    support_email: '',
    support_phone: '',
    team_size: '',
    is_active: false,
    is_verified: false,
    wallet_configs: MockWalletConfig
};

export const MockUserProfile: TProfile = {
    ...MockUser,
    ...ResponseObj,
    picture: null,
    is_business: false,
    notification_matrix: [MockNotificationMatrix]
}