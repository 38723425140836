import { Fragment, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import NotFoundSVG from "../../../assets/images/svgs/empty-state.svg";
import MoreVerticalSVG from "../../../assets/images/svgs/more-vertical.svg";
import UserWarning400SVG from "../../../assets/images/svgs/user-warning-400.svg";
import NotesWhiteOutlineSVG from "../../../assets/images/svgs/notes-outline-white.svg";
import { TStoreState } from "../../../modules/store";
import TripOverview from "../trips/atom/TripOverview";
import { MockTrip } from "../../../utils/mocks/trips";
import { TripMap } from "../../../utils/helpers/mappers";
import PageInfo from "../../../components/layouts/PageInfo";
import PageLayout from "../../../components/layouts/PageLayout";
import { useGetTripsQuery } from "../../../store/slices/api.slice";
import EmptyTable from "../../../components/dataTables/EmptyTable";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { getUserJourneyDates } from "../../../utils/helpers/logicHelper";
import LightInfoButton from "../../../components/buttons/LightInfoButton";
import TripJourneyModal from "../../../components/modals/TripJourneyModal";
import DropdownElement from "../../../components/dropdown/DropdownElement";
import ActionPromptModal from "../../../components/modals/ActionPromptModal";
import DropdownComponent from "../../../components/dropdown/DropdownComponent";
import TripInitiationModal from "../../../components/modals/TripInitiationModal";
import { ActionPromptEnum, DomType, MenuType, TripSystemStatus } from "../../../modules/enums";


const Home = () => {
  const navigate = useNavigate();
  const domType = useSelector((state: TStoreState) => state.auth.domType);
  const domObject: TBusiness = useSelector((state: TStoreState) => state.auth.domObject) as TBusiness;
  const userProfile = useSelector((state: TStoreState) => state.auth.userProfile);
  const businessQuery = domType === DomType.BUSINESS ? `&business=${domObject["reference"]}` : "";

  const [timeLine, setTimeLine] = useState({});
  const [trip, setTrip] = useState<TTrip>(MockTrip);
  const [trips, setTrips] = useState<TTrip[]>([]);
  const [trackTripRender, setTrackTripRender] = useState(false);
  const [cancelTripRender, setCancelTripRender] = useState(false);
  const [tripInitiationRender, setTripInitiationRender] = useState(false);
  const { data: _trips, isLoading, isError } = useGetTripsQuery(`?size=${5}&page=${1}${businessQuery}`);

  useMemo(() => {
    const data: TTrip[] = (isLoading || !_trips || !_trips.data) ? [] : _trips.data;
    setTrips(data);
  }, [isLoading, _trips]);

  const viewTrip = (trip: TTrip) => navigate(`/${MenuType.TRIPS}/${trip["reference"]}`);
  const changeCancelRenderStatus = () => setCancelTripRender(false);

  const handleCancelTrip = (trip: TTrip) => {
    setTrip(trip);
    setCancelTripRender(true);
  }

  const handleTrackTrip = (trip: TTrip) => {
    setTrip(trip)
    let timelines = getUserJourneyDates(trip);
    setTimeLine(timelines);
    setTrackTripRender(true);
  }

  const changeTripInitiationRenderStatus = () => setTripInitiationRender(false);

  const tripInitiationRenderModal = tripInitiationRender ?
    <TripInitiationModal onchange={changeTripInitiationRenderStatus} business={domObject} /> : null;

  const cancelTripModal = cancelTripRender ?
    <ActionPromptModal onchange={changeCancelRenderStatus} type={ActionPromptEnum.CANCEL_TRIP} props={trip?.id} /> : null;

  const tripJourneyModal = trackTripRender ?
    <TripJourneyModal onchange={() => setTrackTripRender(false)} tripTimeline={timeLine} trip={trip} /> : null;


  return (
    <PageLayout menu={MenuType.HOME} loading={isLoading}>
      <Fragment>
        {cancelTripModal}
        {tripJourneyModal}
        {tripInitiationRenderModal}
      </Fragment>
      <div className="d-flex justify-content-between flex-wrap gap-2">
        <PageInfo name="Dashboard"
          description={`Welcome, ${(userProfile && userProfile["first_name"]) ? userProfile["first_name"] : "-"}`} />

        <div className="d-flex gap-3">
          <LightInfoButton btn={{
            text: 'Complete Profile Settings',
            btnClassName: 'd-none',
            image: {
              imgSrc: UserWarning400SVG,
            },
            clickFn: () => setTripInitiationRender(true)
          }} />

          {(domObject && domObject["is_merchant"]) &&
            <PrimaryButton btn={{
              text: 'Initiate Customer Trip',
              outline: true,
              clickFn: () => setTripInitiationRender(true)
            }} />}
          <PrimaryButton btn={{
            text: 'Get Trip Quote',
            image: {
              imgSrc: NotesWhiteOutlineSVG,
            },
            clickFn: () => navigate(`/${MenuType.TRIPS}/create`)
          }} />
        </div>
      </div>

      <TripOverview domObject={domObject} />

      <div className="row mt-3">
        <div className="col-12">
          <div className="card border">
            <div className="card-body">
              <h4 className="text-primary mb-4">Recent Trips</h4>
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead>
                    <tr>
                      <th>Trip Ref</th>
                      <th>Customer</th>
                      <th>Date Created</th>
                      <th>Pickup Location</th>
                      <th>Delivery Location</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(trips && trips.length > 0) && trips.slice(0, 5).map((each, index) =>
                      <tr key={index}>
                        <td>{each.reference}</td>
                        <td>
                          {(each.user && each.user["first_name"]) ? each.user["first_name"] : "-"}
                          {" "}{(each.user && each.user["last_name"]) ? each.user["last_name"] : ""}
                        </td>
                        <td>
                          {(each["created_at"] && moment(each["created_at"]).isValid()) ? moment(each["created_at"]).format('ll') : "-"}
                        </td>
                        <td>
                          {(each.source && each.source.address && each.source.address.length < 25) ? each.source.address
                            : (each.source && each.source.address && each.source.address.length >= 25) ? `${each.source.address.replace(/<[^>]*>?/gm, " ").slice(0, 25)}...`
                              : '-'
                          }
                        </td>
                        <td>
                          {(each.destination && each.destination.address && each.destination.address.length < 25) ? each.destination.address
                            : (each.destination && each.destination.address && each.destination.address.length >= 25) ? `${each.destination.address.replace(/<[^>]*>?/gm, " ").slice(0, 25)}...`
                              : '-'
                          }
                        </td>
                        <td>
                          <span
                            className={each["system_status"] ? `badge badge-${TripMap[each["system_status"] as TripSystemStatus].fill}` : 'badge badge-danger'}>
                            {each["system_status"] ? TripMap[each["system_status"] as TripSystemStatus].label : "OTHER"}
                          </span>
                        </td>
                        <td>
                          <DropdownComponent imgSrc={MoreVerticalSVG} imgAlt="More icon" className="tableMoreDropdown"
                            drop="start" variant="link">
                            <DropdownElement clickFn={() => viewTrip(each)} text="More Information" />
                            {(!each['is_paid'] && !each["business_tripset"]) &&
                              <DropdownElement clickFn={() => handleCancelTrip(each)} text="Cancel trip" />}
                            <DropdownElement clickFn={() => handleTrackTrip(each)} text="Track Trip" />
                          </DropdownComponent>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {(isError || !trips || trips.length === 0) && <EmptyTable imgSrc={NotFoundSVG} description="No recent trips" />}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Home;