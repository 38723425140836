import { ActionPromptEnum, TripSystemStatus, WalletStatus } from "../../modules/enums";

export const ActionPromptMap = {
	[ActionPromptEnum.LOGOUT]: {
		id: 0,
		title: "Are you sure you want to logout ?",
		message: "We want to make sure you intend to log out of your account.",
		actionText: "Yes, Log out",
		successMessage: "You have successfully logged out of your account",
	},
	[ActionPromptEnum.CANCEL_TRIP]: {
		id: 1,
		title: "Are you sure you want to cancel this trip ?",
		message: "We want to make sure you intended to cancel this trip information",
		actionText: "Yes, Cancel",
		successMessage: "You have successfully logged out of your account",
	},
};

export const TripMap = {
	[TripSystemStatus.INITIATED]: {
		id: 1,
		label: "Trip Initiated",
		fill: "primary",
	},
	[TripSystemStatus.AWAITING_PAYMENT]: {
		id: 2,
		label: "Awaiting Payment",
		fill: "info",
	},
	[TripSystemStatus.ORDER_CONFIRMED]: {
		id: 3,
		label: "Order Confirmed",
		fill: "primary",
	},
	[TripSystemStatus.ASSIGNED_TO_DRIVER]: {
		id: 4,
		label: "Assigned to a Driver",
		fill: "info"
	},
	[TripSystemStatus.DRIVER_AWAITING_CONFIRMATION]: {
		id: 5,
		label: "Driver is awaiting Payment Confirmation",
		fill: "info",
	},
	[TripSystemStatus.DRIVER_ARRIVED]: {
		id: 6,
		label: "Driver Arrived",
		fill: "info",
	},
	[TripSystemStatus.LOADING]: {
		id: 7,
		label: "Driver Loading Deliveries",
		fill: "info",
	},
	[TripSystemStatus.TRIP_STARTED]: {
		id: 8,
		label: "Trip Started",
		fill: "info",
	},
	[TripSystemStatus.TRIP_STARTED_WITH_STOPS]: {
		id: 9,
		label: "Trip Started",
		fill: "info",
	},
	[TripSystemStatus.AWAITING_DELIVERY_CONFIRMATION]: {
		id: 10,
		label: "Awaiting Delivery Confirmation",
		fill: "info",
	},
	[TripSystemStatus.DROP_OFF]: {
		id: 11,
		label: "Drop Off Location",
		fill: "info",
	},
	[TripSystemStatus.COMPLETED_OFFLINE]: {
		id: 12,
		label: "Trip Completed offline",
		fill: "success",
	},
	[TripSystemStatus.COMPLETED]: {
		id: 13,
		label: "Trip Completed",
		fill: "success",
	},
	[TripSystemStatus.CANCELLED]: {
		id: 14,
		label: "Trip Cancelled",
		fill: "danger",
	},
	[TripSystemStatus.RECONCILED]: {
		id: 15,
		label: "Trip Reconciled",
		fill: "success",
	},
	INVALID: {
		id: 16,
		label: "Invalid Trip",
		fill: "danger",
	},
	OTHER: {
		id: 17,
		label: "Status Unknown",
		fill: "primary",
	}
};

export const WalletStatusMap = {
	[WalletStatus.PROCESSED]: {
		id: 0,
		label: "PROCESSED",
		fill: "primary",
	},
	[WalletStatus.AWAITING_VERIFICATION]: {
		id: 1,
		label: "AWAITING VERIFICATION",
		fill: "info",
	},
	[WalletStatus.COMPLETED]: {
		id: 2,
		label: "SUCCESS",
		fill: "success",
	},
	[WalletStatus.SUCCESS]: {
		id: 3,
		label: "SUCCESS",
		fill: "success",
	},
	[WalletStatus.FAILED]: {
		id: 4,
		label: "FAILED",
		fill: "danger",
	},
	[WalletStatus.REFUNDED]: {
		id: 5,
		label: "REFUNDED",
		fill: "primary",
	},
};

export const CarouselMap = [
	{
		alt: "first_slide",
		caption: "",
		text: "",
	},
	{
		alt: "second_slide",
		caption: "",
		text: "",
	},
	{
		alt: "third_slide",
		caption: "",
		text: "",
	},
];