import { ChangeEvent, FormEvent, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import UserSVG from "../../../../assets/images/svgs/user.svg";
import PageInfo from "../../../../components/layouts/PageInfo";
import { getProfile } from "../../../../store/slices/auth.slice";
import ImageSVG from "../../../../assets/images/svgs/image-add.svg";
import PasswordInput from "../../../../components/forms/PasswordInput";
import ProfileService from "../../../../utils/services/profile.service";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { phoneValidator } from "../../../../utils/services/validator.service";
import {  Loading } from "../../../../utils/helpers/constants";
import { InputValidator } from "../../../../modules/enums";

type Props = {
  userInfo: TProfile
}

const UserDetails = ({ userInfo }: Props) => {
  const dispatch = useDispatch(), hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [firstName, setFirstName] = useState(userInfo.first_name ?? "");
  const [lastName, setLastName] = useState(userInfo.last_name ?? "");
  const [email] = useState(userInfo.email ?? "");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loadingImageUpload, setLoadingImageUpload] = useState(false);
  const [loadingDetailsChange, setLoadingDetailsChange] = useState(false);
  const [loadingPasswordChange, setLoadingPasswordChange] = useState(false);
  const [phone, setPhone] = useState({ value: userInfo.phone ?? "", errorState: false });

  const passwordDisabledState = !oldPassword || !password || !confirmPassword || password !== confirmPassword;
  const detailsDisabledState = !firstName || !lastName || !phone.value || phone.errorState || phoneValidator(phone.value) === InputValidator.INVALID;


  const editProfile = async (e: FormEvent) => {
    e.preventDefault();
    setLoadingDetailsChange(true);
    let payload = { first_name: firstName, last_name: lastName, phone: phone.value };
    const { data: responseData } = await ProfileService.updateProfile(userInfo.id, payload);
    if (!responseData || !responseData.status || responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      await getProfile(dispatch);
      toast.success(responseData.message);
    }
    setLoadingDetailsChange(false);
  };

  const changePassword = async (e: FormEvent) => {
    e.preventDefault();
    
    setLoadingPasswordChange(true);
    const { data: responseData } = await ProfileService.changePassword({ oldPassword, password });
    if (!responseData || !responseData.status || responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else toast.success(responseData.message);
    setLoadingPasswordChange(false);
  };

  const uploadProfilePicture = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoadingImageUpload(true);
    const file = event.target.files?.[0];

    if (typeof file === "object") {
      if (file.size > 2 * 1024 * 1024) {
        toast.error("File size exceeds the maximum allowed size of 2MB.");
        setLoadingImageUpload(false);
        return;
      }

      const formData = new FormData();
      await formData.append("picture", file);

      const { data: responseData } = await ProfileService.changeProfilePicture(userInfo.id, formData);
      if (!responseData || !responseData.status || responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
      else {
        await getProfile(dispatch);
        toast.success(responseData.message);
      }
    }
    setLoadingImageUpload(false);
  };

  return (
    <div className="row">
      <div className="col-md-6 mb-3 h-100">
        <div className="card">
          <div className="card-body">
            <PageInfo type="SMALL" name="Profile Settings" description="These are your personal details, they are visible to the public" />
            <div className="row mt-4">
              <div className="col-12">
                <div className="d-flex justify-content-center justify-content-sm-start mb-5 gap-1 gap-lg-3">
                  <div className="profile-avatar bg-primary-lighter">
                    <img src={userInfo.picture ?? UserSVG} alt="user-icon" className="rounded-circle" />
                  </div>
                  <div className="ms-4">
                    <p className="fs-6 fw-semibold mb-2">Profile photo</p>
                    <p className="text-gray-500 mb-2">
                      This image will be displayed
                      <br /> on your profile
                    </p>
                    <small className="text-info mb-2">
                      Accepted file type .png, .jpng, .gif.{" "}
                      <b>Less than 2MB</b>
                    </small>
                    <input
                      type="file"
                      accept="image/*"
                      ref={hiddenFileInput}
                      onChange={uploadProfilePicture}
                      className="d-none"
                    />
                    <PrimaryButton  
                      btn={{
                        text: 'Update Image',
                        outline: true,
                        btnClassName: 'mt-2 btn-sm d-block',
                        loadState: loadingImageUpload,
                        clickFn: () => hiddenFileInput.current?.click(),
                        image: {
                          width: "18",
                          height: "16",
                          imgClass: "me-2",
                          imgAlt: "uploadPicture",
                          imgSrc: ImageSVG,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mb-4">
                <div className="form-floating">
                  <input
                    type="text"
                    placeholder="First Name"
                    className="form-control form-lightblue"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <label>First Name</label>
                </div>
              </div>
              <div className="col-12 mb-4">
                <div className="form-floating">
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="form-control form-lightblue"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <label>Last Name</label>
                </div>
              </div>
              <div className="col-12 mb-4">
                <div className="form-floating">
                  <input type="tel" placeholder="Phone" value={phone.value}
                    className={`form-control form-lightblue ${(phone && phone.errorState) && 'is-invalid'}`}
                    onChange={e => phoneValidator(e.target.value) === InputValidator.INVALID ? setPhone({ value: e.target.value, errorState: true }) : setPhone({ value: e.target.value, errorState: false })} />
                  <label>Phone Number</label>
                </div>
              </div>
              <div className="col-12 mb-4">
                <div className="form-floating">
                  <input
                    type="text"
                    placeholder="Email"
                    className="form-control form-lightblue"
                    value={email}
                    disabled
                  />
                  <label>Email Address</label>
                </div>
              </div>
            </div>
            <PrimaryButton 
              btn={{
                text: 'Update Change',
                btnClassName: 'mb-2 btn-block',
                disabledState: detailsDisabledState,
                loadState: loadingDetailsChange,
                clickFn: editProfile
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 h-100">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Update Password</h5>
            <p className="text-gray-500 mb-5">
              Enter your current password to make update
            </p>
            <PasswordInput name="current_password" value={oldPassword} setInput={value => setOldPassword(value)} />
            <PasswordInput name="new_password" value={password} setInput={value => setPassword(value)} />
            <PasswordInput name="confirm_new_password" value={confirmPassword} setInput={value => setConfirmPassword(value)} />

            <PrimaryButton 
              btn={{
                text: 'Update',
                btnClassName: 'mb-4 btn-block',
                disabledState: passwordDisabledState,
                loadState: loadingPasswordChange,
                clickFn: changePassword
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
