import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import TokenHelpers from "../../utils/helpers/tokenHelper"
import {getBaseUrl} from "../../utils/services/axios.service";


const CUSTOM_PLATFORM = {platform: 'CUSTOM_USER'}
const dodgeBaseQuery = fetchBaseQuery({
	baseUrl: getBaseUrl()['dodge'],
	prepareHeaders: headers => {
		const token = TokenHelpers.getToken();
		headers.set("Content-Type", 'application/json');
		headers.set("Organisation", `${getBaseUrl()['organisation']}`);
		if(token) headers.set("Authorization", `Bearer ${token}`);
		return headers
	}
})

export const dodgeQuery = createApi({
	baseQuery: dodgeBaseQuery,
	reducerPath: 'dodgeQuery',
	tagTypes: ['Bank_Accounts', 'Entries', 'Profile', 'Trips', 'Wallet'],
	endpoints: builder => ({
		loginProfile: builder.mutation({
			query: body => ({url: `locale-service/auth/login`, method: "POST", body, headers: CUSTOM_PLATFORM})
		}),
		googleProfile: builder.mutation({
			query: payload => ({
				url: `locale-service/auth/social-login`,
				method: "POST",
				body: payload,
				headers: CUSTOM_PLATFORM
			}),
		}),
		registerProfile: builder.mutation({
			query: body => ({ url: `entity-service/user/user-profile/`, method: "POST", body})
		}),
		getTrips: builder.query({
			query: filter => `booking-service/booking/booking-trip/${filter}`,
			providesTags: ['Trips']
		}),
		getTrip: builder.mutation({
			query: body => ({url: `booking-service/booking/booking-trip/search/`, method: 'POST', body}),
		}),
		getTripTypes: builder.query({
			query: () => `booking-service/booking/booking-organisation-profile/get_organisation_trip_type/`,
		}),
		getExtras: builder.query({
			query: () => `booking-service/booking/booking-organisation-profile/get_extras/`,
		}),
		createTrip: builder.mutation({
			query: body => ({url: `booking-service/booking/booking-trip/`, method: 'POST', body}),
			invalidatesTags: ['Trips']
		}),
		updateTrip: builder.mutation<TApiResponse<TTrip>, { id: string, body: Partial<TTrip> }>({
			query: ({ id, body }) => ({ url: `booking-service/booking/booking-trip/${id}/`, method: 'PATCH', body }),
			invalidatesTags: ['Trips']
		}),
		createBusinessTrip: builder.mutation({
			query: body => ({url: `entity-service/business/business-tripset/`, method: 'POST', body}),
			invalidatesTags: ['Trips']
		}),
		getPricingConfiguration: builder.query({
			query: businessRef => ({url: `entity-service/business/business-pricing-config/`, headers: {entity: businessRef}})
		}),
		getOrganisationFleet: builder.query({
			query: () => (`asset-service/fleet/fleet-organisation-category-size/`)
		}),
		getWallet: builder.query({
			query: () => `locale-service/wallets/user/wallet`,
			providesTags: ['Wallet']
		}),
		getWalletEntries: builder.query({
			query: filter => `locale-service/wallets/user/entries/${filter}`,
			providesTags: ['Entries']
		}),
		getBankAccounts: builder.query({
			query: () => `locale-service/payment/withdrawals/users/banks`,
			providesTags: ['Bank_Accounts']
		}),
		addBankAccount: builder.mutation({
			query: payload => ({url: `locale-service/payment/withdrawals/users/banks`, method: 'POST', body: payload}),
			invalidatesTags: ['Bank_Accounts']
		}),
		getNotificationMatrices: builder.query({
			query: () => `locale-service/messaging/matrices`,
		}),
	})
})

export const {
	useLoginProfileMutation,
	useGoogleProfileMutation,
	useRegisterProfileMutation,
	useGetTripsQuery,
	useGetTripMutation,
	useGetTripTypesQuery,
	useGetExtrasQuery,
	useCreateTripMutation,
	useUpdateTripMutation,
	useCreateBusinessTripMutation,
	useGetPricingConfigurationQuery,
	useGetOrganisationFleetQuery,
	useGetWalletQuery,
	useGetWalletEntriesQuery,
	useGetBankAccountsQuery,
	useAddBankAccountMutation,
	useGetNotificationMatricesQuery,
} = dodgeQuery;
