import { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";

import CloseButton from "../buttons/CloseButton";
import DangerButton from "../buttons/DangerButton";
import PrimaryButton from "../buttons/PrimaryButton";
import { Loading } from "../../utils/helpers/constants";
import { ActionPromptEnum } from "../../modules/enums";
import { ActionPromptMap } from "../../utils/helpers/mappers";
import { toTitleCase } from "../../utils/helpers/logicHelper";
import BookingService from "../../utils/services/booking.service";

type Props = {
	type: ActionPromptEnum,
	props?: string,
	onchange: () => void,
	setSuccessfulResponse?: () => void
};

const ActionPromptModal = ({ type, props, onchange, setSuccessfulResponse }: Props) => {
	const actionProps = ActionPromptMap[type];
	const [show, setShow] = useState(true);
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		setShow(false);
		onchange();
	};

	const completeAction = () => {
		toast.success(actionProps.successMessage);
		handleClose();
		if (setSuccessfulResponse) setSuccessfulResponse();
	}

	const handleChangeRequest = async (event: any) => {
		setLoading(true);
		event.preventDefault();
		let responseData;

		if (type === ActionPromptEnum.LOGOUT) {
			handleClose();
			if (setSuccessfulResponse) setSuccessfulResponse();
		} else if (type === ActionPromptEnum.CANCEL_TRIP) responseData = await BookingService.cancelTrip(props);

		if (responseData && responseData.status && responseData.status === Loading.NO_CONTENT) completeAction();
		else if (responseData && responseData.data && responseData.data.status === Loading.SUCCESS) completeAction();
		else if (responseData && responseData.data && responseData.data.status === Loading.FAILED) {
			let errorMessage = responseData.data.message ? responseData.data.message : `You cannot ${toTitleCase(type).toLowerCase()} at this time, Please try again later.`;
			toast.error(errorMessage);
		} else handleClose();

		setLoading(false);
	}


	return (
		<Modal show={show} backdrop="static" size="lg" keyboard={false} centered>
			<Modal.Body>
				<div className="row">
					<div className="col-12 mt-0"><CloseButton clickFn={handleClose} /></div>
				</div>
				<div className="row mb-4">
					<div className="col-md-8 col-sm-10 offset-md-2 offset-sm-1 text-center">
						<h2 className="text-black-4">{actionProps.title}</h2>
						<p className="text-black-3 text-center mt-2">{actionProps.message}</p>
					</div>
					<div className="col-8 offset-2 my-4">
						<div className="d-flex flex-column flex-lg-row justify-content-between column-gap-5 row-gap-3">
							<PrimaryButton btn={{
								text: "No, Go Back",
								btnClassName: 'btn-block',
								loadState: undefined,
								disabledState: undefined,
								clickFn: handleClose
							}} />
							<DangerButton btn={{
								text: actionProps.actionText,
								outline: true,
								btnClassName: 'btn-block',
								loadState: loading,
								clickFn: handleChangeRequest
							}} />
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default ActionPromptModal;