import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import { Loading } from "../../../../utils/helpers/constants";
import { DomType, MenuType } from "../../../../modules/enums";
import EditSVG from "../../../../assets/images/svgs/edit-primary.svg";
import BookingService from "../../../../utils/services/booking.service";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import AdvancePaymentModal from "../../../../components/modals/AdvancePaymentModal";
import { formatAmountToString, formatNumber, queryErrorHandler, toTitleCase } from "../../../../utils/helpers/logicHelper";

type Props = {
	domType: DomType,
	wallet: TWallet,
	organisation: TOrgainsation,
	tripConfirmationStatus: boolean,
	tripPayload: TTrip | TBusinessTrip,
	vehicle: TVehicleCategory,
	setTripConfirmation: (value: boolean) => void ,
	setUpdateState: (value: boolean) => void
};

const TripConfirmation = ({ domType, wallet, organisation, tripConfirmationStatus, tripPayload, vehicle, setTripConfirmation, setUpdateState }: Props) => {
	const navigate = useNavigate();
	const [loadingCardPayment, setLoadingCardPayment] = useState(false);
	const [loadingWalletPayment, setLoadingWalletPayment] = useState(false);
	const [advancePaymentRender, setAdvancePaymentRender] = useState(false);
	const orgWalletConfig = organisation && organisation["wallet_configs"];
	const trip: TTrip = (domType === DomType.INDIVIDUAL && tripPayload.trips && tripPayload.trips.length > 0) ? tripPayload.trips[0] : tripPayload;

	const handleGoBack = () => {
		if (domType === DomType.INDIVIDUAL) {
			setTripConfirmation(!tripConfirmationStatus);
			setUpdateState(true);
		}
		else navigate(-1);
	}

	const handleSuccessResponse = () => {
		navigate(`/${MenuType.TRIPS}/${trip.reference}`);
	}

	const handleCardPayment = async () => {
		setLoadingCardPayment(true);
		const callbackUrl = `${window.location.origin}/${MenuType.TRIPS}/${trip.reference}/`;
		let responseData;

		if (trip.quote['checkout_url']) {
			setLoadingCardPayment(false);
			return window.open(trip.quote['checkout_url'], '_self')?.focus();
		} else if (domType === DomType.BUSINESS && trip) {
			responseData = await BookingService.checkoutBusiness(trip['id'], trip['business']['reference'], callbackUrl);
		} else responseData = await BookingService.checkout(trip.id, callbackUrl);

		if (responseData && responseData.data && responseData.data.data && responseData.data.data.checkout_url && responseData.data.status === Loading.SUCCESS) window.open(responseData.data.data.checkout_url, '_self')?.focus();
		else {
			const errorMessage = queryErrorHandler('pay for this trip', responseData)
			toast.error(errorMessage);
		}
		setLoadingCardPayment(false);
	}

	const handleWalletPayment = async () => {
		let responseData;
		setLoadingWalletPayment(true);

		if (domType === DomType.BUSINESS && trip) {
			responseData = await BookingService.walletBusinessCheckout(trip['id'], trip['business']['reference']);
		} else responseData = await BookingService.walletCheckout(trip.id);

		if (!responseData || !responseData.status || responseData.data.status !== Loading.SUCCESS) toast.error(responseData.data.message);
		else {
			if (domType === DomType.BUSINESS) navigate(`/${MenuType.TRIPS}/${trip["reference"]}/`);
			else navigate(`/${MenuType.TRIPS}/${trip["reference"]}/`)
			toast.success(responseData.data.message);
		}
		setLoadingWalletPayment(false);
	}

	const openAdvancePayment = () => setAdvancePaymentRender(true);
	const changeAdvancePaymentRenderStatus = () => setAdvancePaymentRender(false);

	const advancePaymentModal = advancePaymentRender ?
		<AdvancePaymentModal onchange={changeAdvancePaymentRenderStatus} props={{ trip, wallet }}
			onSuccessResponse={handleSuccessResponse} /> : null;

	return (
		<Fragment>
			{advancePaymentModal}
			<div className="d-inline-flex gap-3 mb-5 cursor-pointer" onClick={handleGoBack}>
				<i className="zmdi zmdi-chevron-left fs-3" />
				<h4>Go back</h4>
			</div>
			<div className="card">
				<div className="card-body">
					<h3 className="text-center mb-5">Booking Confirmation</h3>
					<div className="info-group">
						<div>
							<p className="text-nowrap">Pickup Location</p>
							<p className="fw-bold text-end">
								{(trip && trip.source && trip.source.address) ?
									(trip.source.address.length > 45 ? `${trip.source.address.replace(/<[^>]*>?/gm, ' ').slice(0, 45)}...` : trip.source.address)
									: ""
								}
							</p>
						</div>
						<div>
							<p className="text-nowrap">Drop - off location</p>
							<p className="fw-bold text-end">
								{(trip && trip.destination && trip.destination.address) ?
									(trip.destination.address.length > 45 ? `${trip.destination.address.replace(/<[^>]*>?/gm, ' ').slice(0, 45)}...` : trip.destination.address)
									: ""
								}
							</p>
						</div>
						{domType === DomType.INDIVIDUAL &&
							<div>
								<p className="text-nowrap">Distance</p>
								<p className="fw-bold text-end">{formatNumber(Math.ceil(trip['distance'] as number))}km</p>
							</div>
						}
						<div>
							<p className="text-nowrap">Vehicle Category</p>
							<p className="fw-bold text-end">
								{vehicle['category']} - {vehicle['tonnage']} tonne
							</p>
						</div>
						<div className="border-bottom">
							<p className="text-nowrap">Schedule Date</p>
							<p className="fw-bold text-end">
								{(trip["scheduled_date"] && moment(trip["scheduled_date"]).isValid()) ? moment(trip["scheduled_date"]).format('Do MMMM YYYY')
									: ""
								}
							</p>
						</div>
						{domType === DomType.BUSINESS ?
							<div>
								<p className="text-nowrap">Delivery Fee</p>
								<p className="fw-bold text-end">
									{trip.quote.currency} {(tripPayload.quote && tripPayload.quote.base_fare) ? formatAmountToString(tripPayload.quote['base_fare']) : 0.00}
								</p>
							</div>
							:
							<Fragment>
								<div>
									<p className="text-nowrap">Base fare</p>
									<p className="fw-bold text-end">
										{trip.quote.currency} {formatAmountToString(trip.quote['base_fare'])}
									</p>
								</div>
								{(trip["system_trip_type"] === "FIXED" && trip.quote['cost_per_kilometer'] && formatAmountToString(trip.quote['cost_per_kilometer']) !== "0.00") &&
									<div>
										<p className="text-nowrap">Cost per km</p>
										<p className="fw-bold text-end">
											{trip.quote.currency} {formatAmountToString(trip.quote['cost_per_kilometer'])}
										</p>
									</div>
								}
								<div>
									<p className="text-nowrap">Estimated insurance</p>
									<p className="fw-bold text-end">
										{trip.quote.currency} {formatAmountToString(trip.quote['estimated_insurance'])}
									</p>
								</div>
								{(trip.extras && Object.keys(trip.extras).length > 0 && Object.keys(trip.extras).map((key, index) =>
									<Fragment key={index}>
										{key in trip.quote &&
											<div>
												<p className="text-nowrap">{toTitleCase(key)} Cost</p>
												<p className="fw-bold text-end">
													{trip.quote.currency} {formatAmountToString(trip.quote[key])}
												</p>
											</div>
										}
									</Fragment>
								))}
							</Fragment>
						}

						<div>
							<p className="text-nowrap">VAT</p>
							<p className="fw-bold text-end">
								{trip.quote.currency} {formatAmountToString(trip.quote['vat_value'])}
							</p>
						</div>
						{(tripPayload.quote && trip.quote['discount_amount']) &&
							<div className="text-primary">
								<p className="text-nowrap">
									Discount
									<span className="ms-2">
										{(tripPayload && tripPayload.coupon && typeof tripPayload.coupon === 'object') ? tripPayload?.coupon?.discount
											: tripPayload.quote["discount_amount"] ?
												Math.ceil((Number(tripPayload.quote["discount_amount"]) * 100) / Number(tripPayload.quote["total_charge"])) : 0}
												%
									</span>
								</p>
								<p className="fw-bold text-end">
									{trip.quote.currency} {formatAmountToString(tripPayload.quote['discount_amount'])}
								</p>
							</div>
						}
						<div className="border-top border-bottom my-3">
							<p className="text-nowrap">Total Charge</p>
							<p className="fw-bold text-end">
								{trip.quote.currency} {(tripPayload.quote && tripPayload.quote['charge']) ? formatAmountToString(tripPayload.quote['charge']) : 0.00}
							</p>
						</div>
					</div>
					{domType === DomType.INDIVIDUAL &&
						<div className="d-flex justify-content-end x mb-4">
							<h4 className="text-primary cursor-pointer" onClick={handleGoBack}>
								<img src={EditSVG} alt="Edit" className="me-2" />Edit
							</h4>
						</div>
					}
					<div className="d-flex justify-content-center flex-column flex-md-row gap-3">
						{(domType === DomType.BUSINESS && trip.quote && Object.entries(trip.quote).length > 0) && (wallet && wallet.extras && wallet.extras["advanced_payment_status"]) &&
							(orgWalletConfig && orgWalletConfig["advance_payment_status"]) &&
							<PrimaryButton btn={{
								text: 'Part Payment',
								outline: true,
								btnClassName: 'd-block',
								clickFn: openAdvancePayment
							}} />
						}
						<PrimaryButton btn={{
							text: 'Pay with Card',
							loadState: loadingCardPayment,
							btnClassName: 'd-block',
							clickFn: handleCardPayment
						}}/>
						<PrimaryButton btn={{
							text: 'Pay with Wallet',
							outline: true,
							loadState: loadingWalletPayment,
							btnClassName: 'd-block',
							clickFn: handleWalletPayment
						}}/>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default TripConfirmation;