import { AxiosResponse } from 'axios';
import { dodgeInstance as Axios } from './axios.service';

const BookingService = {
	autoCompleteMapsBySearch(key: string) {
		return Axios({
			method: 'GET',
			url: `booking-service/maps/autocomplete-search-by-text/?search_text=${key}`
		});
	},
	placesApiMap(key: string) {
		return Axios({
			method: 'GET',
			url: `booking-service/maps/search-place-by-id/?place_id=${key}`
		});
	},
	initiateTripQuote(payload: TTripInitiate): Promise<AxiosResponse<TApiResponse<TTrip>, any>> {
		return Axios({
			method: 'POST',
			url: `booking-service/booking/booking-trip/check_quote/`,
			data: payload,
		});
	},
	getCustomerVehicleCategories(tripType: string): Promise<AxiosResponse<TApiResponse<TVehicleCategory[]>, any>> {
		return Axios({
			method: 'GET',
			url: `booking-service/booking/booking-organisation-profile/get_booking_category_sizes/?trip_type=${tripType}`
		});
	},
	getBusinessVehicleCategories(id: string, tripType: string): Promise<AxiosResponse<TApiResponse<TVehicleCategory[]>, any>> {
		return Axios({
			method: 'GET',
			url: `entity-service/business/business-profile/${id}/get_business_category_sizes/?trip_type=${tripType}`
		});
	},
	advancePaymentCheckout(id: string): Promise<AxiosResponse<TApiResponse<{ checkout_url: string }>, any>> {
		return Axios({
			method: 'GET',
			url: `booking-service/booking/booking-trip/${id}/advancedcheckout`
		});
	},
	advancePaymentBusinessCheckout(id: string, businessRef: string): Promise<AxiosResponse<TApiResponse<{ checkout_url: string }>, any>> {
		return Axios({
			method: 'GET',
			url: `entity-service/business/business-tripset/${id}/advancedcheckout/`,
			headers: { entity: businessRef }
		});
	},
	walletCheckout(id: string): Promise<AxiosResponse<TApiResponse<{ checkout_url: string }>, any>> {
		return Axios({
			method: 'GET',
			url: `booking-service/booking/booking-trip/${id}/walletcheckout/`
		});
	},
	walletBusinessCheckout(id: string, businessRef: string): Promise<AxiosResponse<TApiResponse<{ checkout_url: string }>, any>> {
		return Axios({
			method: 'GET',
			url: `entity-service/business/business-tripset/${id}/walletcheckout/`,
			headers: { entity: businessRef }
		});
	},
	checkout(id: string, callbackUrl: string): Promise<AxiosResponse<TApiResponse<{ checkout_url: string }>, any>> {
		return Axios({
			method: 'GET',
			url: `booking-service/booking/booking-trip/${id}/checkout/?callback=${callbackUrl}`
		});
	},
	checkoutBusiness(id: string, businessRef: string, callbackUrl: string): Promise<AxiosResponse<TApiResponse<{ checkout_url: string }>, any>> {
		return Axios({
			method: 'GET',
			url: `entity-service/business/business-tripset/${id}/checkout/?callback=${callbackUrl}`,
			headers: { entity: businessRef }
		});
	},
	cancelTrip(id: string | undefined): Promise<AxiosResponse> {
		return Axios({
			method: 'GET',
			url: `booking-service/booking/booking-trip/${id}/cancel/`,
		});
	},
};

export default BookingService;