export enum AccountType {
	CUSTOMER = "CUSTOMER",
	MERCHANT = "MERCHANT",
}
export enum ActionPromptEnum {
	LOGOUT = "LOGOUT",
	CANCEL_TRIP = "CANCEL_TRIP",
};

export enum DomType {
	INDIVIDUAL = "INDIVIDUAL",
	BUSINESS = "BUSINESS",
};

export enum InputEnum {
	BUTTON = "button",
	CHECKBOX = "checkbox",
	COLOR = "color",
	DATE = "date",
	DATETIME = "datetime-local",
	DECIMAL = "decimal",
	NONE = "none",
	NUMBER = "number",
	EMAIL = "email",
	FILE = "file",
	HIDDEN = "hidden",
	IMAGE = "image",
	MONTH = "month",
	NUMERIC = "numeric",
	PASSWORD = "password",
	RADIO = "radio",
	RANGE = "range",
	RESET = "reset",
	SEARCH = "search",
	SUBMIT = "submit",
	TEL = "tel",
	TEXT = "text",
	TIME = "time",
	URL = "url",
	WEEK = "week"
}

export enum InputValidator {
	INVALID = "INVALID",
	VALID = "VALID"
};

export enum MenuType {
	AUTH = "auth",
	HOME = "home",
	TRIPS = "trips",
	WALLET = "wallet",
	HELP_CENTER = "help-center",
	REFER_EARN = "refer-and-earn",
	SETTINGS = "settings-and-profile",
	PRICING_CONFIGURATION = "pricing-and-discounts",
};

export enum SettingsNavOptions {
	USER_DETAILS = "USER_DETAILS",
	MERCHANT_PROFILE = "MERCHANT_PROFILE",
	NOTIFICATION_PREFERENCE = "NOTIFICATION_PREFERENCE",
};

export enum SubMenuType {
	QUOTE_LEADS = "quote-leads",
	LOGIN = "login",
	ONBOARD = "onboard-profile",
	RESET_PASSWORD = "reset-password",
	FORGOT_PASSWROD = "forgot-password",
};

export enum TripSystemStatus {
	LOADING = "LOADING",
	DROP_OFF = "DROP-OFF",
	INITIATED = "INITIATED",
	COMPLETED = "COMPLETED",
	CANCELLED = "CANCELLED",
	RECONCILED = "RECONCILED",
	TRIP_STARTED = "TRIP-STARTED",
	DRIVER_ARRIVED = "DRIVER-ARRIVED",
	ORDER_CONFIRMED = "ORDER-CONFIRMED",
	AWAITING_PAYMENT = "AWAITING-PAYMENT",
	COMPLETED_OFFLINE = "COMPLETED-OFFLINE",
	ASSIGNED_TO_DRIVER = "ASSIGNED-TO-DRIVER",
	TRIP_STARTED_WITH_STOPS = "TRIP-STARTED-WITH-STOPS",
	DRIVER_AWAITING_CONFIRMATION = "DRIVER-AWAITING-CONFIRMATION",
	AWAITING_DELIVERY_CONFIRMATION = "AWAITING-DELIVERY-CONFIRMATION",
}

export enum TripTypeOptions {
	FIXED = 'FIXED',
	VARIABLE = 'VARIABLE',
	NEGOTIABLE = 'NEGOTIABLE',
	DISTRIBUTION_PER_CITY = 'DISTRIBUTION-PER-CITY',
	DISTRIBUTION_PER_STOP = 'DISTRIBUTION-PER-STOP',
};

export enum WalletStatus {
	FAILED = "FAILED",
	SUCCESS = "SUCCESS",
	REFUNDED = "REFUNDED",
	COMPLETED = "COMPLETED",
	PROCESSED = "PROCESSED",
	AWAITING_VERIFICATION = "AWAITING_VERIFICATION",
}