import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";

import UserDetails from "./atom/UserDetails";
import BusinessProfile from "./atom/BusinessProfile";
import PageInfo from "../../../components/layouts/PageInfo";
import PageLayout from "../../../components/layouts/PageLayout";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { useGetNotificationMatricesQuery } from "../../../store/slices/api.slice";
import { MenuType, SettingsNavOptions, SubMenuType } from "../../../modules/enums";
import NotificationPreference from "./atom/NotificationPreference";
import { TStoreState } from "../../../modules/store";

const Settings = () => {
	const navigate = useNavigate();
	const [key, setKey] = useState(SettingsNavOptions.USER_DETAILS);
	const business = useSelector((state: TStoreState) => state.auth.domObject);
	const userProfile = useSelector((state: TStoreState) => state.auth.userProfile);
	const { data: matrices, isLoading } = useGetNotificationMatricesQuery("");


	return (
		<PageLayout menu={MenuType.SETTINGS} loading={isLoading}>
			<div className="d-flex justify-content-between">
				<PageInfo name="Settings" description="Control your profile setup and integrations" />
				<PrimaryButton 
					btn={{
						text: 'Onboard Business',
						clickFn: () => navigate(`/${MenuType.AUTH}/${SubMenuType.ONBOARD}/`, { state: "CREATE_PROFILE" })
					  }}
				/>
			</div>
			<div className="my-4">
				<Nav justify={false} variant="underline" role="tab-content" className="py-0 d-flex flex-sm-row flex-column"
					defaultActiveKey={key}>
					{((userProfile && Object.entries(userProfile).length > 0)) &&
						<Nav.Item onClick={() => setKey(SettingsNavOptions.USER_DETAILS)}>
							<button className={`nav-link ${key === SettingsNavOptions.USER_DETAILS && "active"}`}>
								User Details
							</button>
						</Nav.Item>}
					{(business && Object.entries(business).length > 0) &&
						<Nav.Item onClick={() => setKey(SettingsNavOptions.MERCHANT_PROFILE)}>
							<button className={`nav-link ${key === SettingsNavOptions.MERCHANT_PROFILE && "active"}`}>
								Business Profile
							</button>
						</Nav.Item>
					}
					{(matrices && matrices.data && userProfile && Object.entries(userProfile).length > 0) &&
						<Nav.Item onClick={() => setKey(SettingsNavOptions.NOTIFICATION_PREFERENCE)}>
							<button className={`nav-link ${key === SettingsNavOptions.NOTIFICATION_PREFERENCE && "active"}`}>
								Notification Preference
							</button>
						</Nav.Item>
					}
				</Nav>
				<div className="tab-content">
					{(userProfile && Object.entries(userProfile).length > 0) &&
						<div className={`tab-pane fade ${key === SettingsNavOptions.USER_DETAILS && "show active"}`}>
							<UserDetails userInfo={userProfile} />
						</div>}
					{(business && Object.entries(business).length > 0) &&
						<div className={`tab-pane fade ${key === SettingsNavOptions.MERCHANT_PROFILE && "show active"}`}>
							<BusinessProfile businessInfo={business as TBusiness} />
						</div>
					}
					{(matrices && matrices.data && userProfile && Object.entries(userProfile).length > 0) &&
						<div className={`tab-pane fade ${key === SettingsNavOptions.NOTIFICATION_PREFERENCE && "show active"}`}>
							<NotificationPreference matrices={matrices.data} userMatrices={userProfile['notification_matrix']}/>
						</div>
					}
				</div>
			</div>
		</PageLayout>
	)
}

export default Settings;