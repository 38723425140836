import { useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Loading } from "../../../../utils/helpers/constants";
import PageInfo from "../../../../components/layouts/PageInfo";
import { getProfile } from "../../../../store/slices/auth.slice";
import ProfileService from "../../../../utils/services/profile.service";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";


type Props = {
	matrices: TUserNotification[],
  userMatrices: TUserNotification[]
};



const NotificationPreference = ({ matrices, userMatrices }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [detectMatrixChange, setDetectMatrixChange] = useState(false);
  const [userNotificationMatrices, setUserNotificationMatrices] = useState(userMatrices);


  const updateNotificationMatrix = (value:boolean, index:number, key:string, item:TUserNotification ) => {
    setDetectMatrixChange(true);
    const newArray = [...userNotificationMatrices], newObj = { ...item };
    newObj[key] = value;
    newArray[index] = newObj;
    setUserNotificationMatrices(newArray);
  }

  const saveNotificationMatrices = async () => {
    setLoading(true);
    const { data: responseData } = await ProfileService.updateNotificationMatrices([...userNotificationMatrices]);
    if (!responseData || !responseData.status || responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      toast.success(responseData.message);
      setDetectMatrixChange(false);
      await getProfile(dispatch);
    }
    setLoading(false);
  }

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="card">
          <div className="card-body">
            <PageInfo type="SMALL" name="Notification Configuration" description="Configure from the list of notification types and triggers for a better experience." />

            <div className="row mx-1 mt-5 mb-3">
              <div className="col-6">
                <h6>Type</h6>
              </div>
              <div className="col-6">
                <div className="row">
                  <h6 className="col-4">InApp</h6>
                  <h6 className="col-4">SMS</h6>
                  <h6 className="col-4">Email</h6>
                </div>
              </div>
            </div>
            {userNotificationMatrices.map((item, index) => {
              let element = matrices.find((data) => (item.matrix === data.matrix) && data.active === 'user');

              if (element) {
                return (
                  <div key={index} className="row border-top border-gray-100 pt-3 pb-2 mx-2">
                    <div className="col-6">
                      <p className="fw-bold">{element['description']}</p>
                    </div>
                    <div className="col-6">
                      <div className="row form-check">
                        <label className="col-4">
                          <input type="checkbox" className="form-check-input ms-0" name="push" checked={Boolean(item['push'])}
                            onChange={event => updateNotificationMatrix(event.target.checked, index, 'push', item)} />
                        </label>
                        <label className="col-4">
                          <input type="checkbox" className="form-check-input ms-0" name="sms" checked={Boolean(item['sms'])}
                            onChange={event => updateNotificationMatrix(event.target.checked, index, 'sms', item)} />
                        </label>
                        <label className="col-4">
                          <input type="checkbox" className="form-check-input ms-0" name="email" checked={Boolean(item['email'])}
                            onChange={event => updateNotificationMatrix(event.target.checked, index, 'email', item)} />
                        </label>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
            <div className="d-flex justify-content-center justify-content-sm-end mt-5">
              <PrimaryButton
                  btn={{
                    text: 'Update Preference',
                    btnClassName: 'px-5',
                    disabledState: !detectMatrixChange,
                    loadState: loading,
                    clickFn: saveNotificationMatrices
                  }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationPreference;