import { MouseEvent } from "react";
import GrayButton from "./GrayButton";
import CancelSolidSVG from "../../assets/images/svgs/cancel-circle-gray.svg";

const CloseButton = ({ clickFn }: { clickFn: (event: MouseEvent<HTMLButtonElement, MouseEvent>) => void }) => (
	<GrayButton
		btn={{
			text: 'Close',
			flat: true,
			image: {
				imgAlt: 'close',
				imgSrc: CancelSolidSVG,
				width: "18",
				height: "18",
			},
			btnClassName: 'btn-sm my-2 fs-6',
			clickFn: clickFn
		}} />
);
export default CloseButton;