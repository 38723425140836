import Cookies from "js-cookie";
import EncryptionHelper from "./encryptionHelper";

const CookieHelper = {
  get: (key: string) => {
    const dataEncrypted: string = Cookies.get(key) ?? '';
    return dataEncrypted !== 'null' ? EncryptionHelper.decrypt(dataEncrypted) : '';
  },

  set: <T>(key: string, data: T, ex: number) => {
    const dataEncrypted = EncryptionHelper.encrypt(data) as unknown as string;
    Cookies.set(key, dataEncrypted, {expires: ex});
  },

  remove: (key: string) => {
    Cookies.remove(key);
  }

}

export default CookieHelper;