import { ColumnDef, ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import { formatAmountToString, toTitleCase } from "../../../utils/helpers/logicHelper";
import { WalletStatusMap } from "../../../utils/helpers/mappers";
import { WalletStatus } from "../../../modules/enums";
import moment from "moment";

const column: ColumnHelper<TWallet> = createColumnHelper<TWallet>()

export const WalletColumns: ColumnDef<TWallet, any>[] = [
	column.accessor('invoice_reference', {
		header: () => <span>Reference</span>,
		cell: info => <span className="text-truncate">{info.getValue() ? info.getValue() : '-'}</span>,
		footer: info => info.column.id,
	}),
	column.accessor('amount', {
		header: () => <span>Amount</span>,
		cell: info => <span className="text-truncate">
			{info.row.original.currency ? info.row.original.currency : ""}{" "}{info.getValue() ? formatAmountToString(info.getValue()) : '-'}
			</span>,
		footer: info => info.column.id,
	}),
	column.accessor('available_balance', {
		header: () => <span>Balance</span>,
		cell: info => <span className="text-truncate">
			{info.row.original.currency ? info.row.original.currency : ""}{" "}{info.getValue() ? toTitleCase(info.getValue()) : '-'}
			</span>,
		footer: info => info.column.id,
	}),
	column.accessor('type', {
		header: () => <span>Type</span>,
		cell: info => <span className="text-truncate">{info.getValue() ? info.getValue() : '-'}</span>,
		footer: info => info.column.id,
	}),
	column.accessor('description', {
		header: () => <span>Description</span>,
		cell: info => <span className="text-truncate">{info.getValue() ? info.getValue() : '-'}</span>,
		footer: info => info.column.id,
	}),
	column.accessor('status', {
		header: () => <span>Status</span>,
		cell: info => <span className={`pointer badge badge-${info.getValue() ? WalletStatusMap[info.getValue().toUpperCase() as WalletStatus].fill : "badge-primary"}`}>
			{info.getValue() ? WalletStatusMap[info.getValue().toUpperCase() as WalletStatus].label : "OTHER"}
			</span>,
		footer: info => info.column.id,
	}),
	column.accessor('created_at', {
		header: () => <span>Date</span>,
		cell: info => <span className="text-truncate">{(info.getValue() && moment(info.getValue()).isValid()) ? moment(info.getValue()).format("lll") : '-'}</span>,
		footer: info => info.column.id,
	}),
];