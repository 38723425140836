import { createSlice } from '@reduxjs/toolkit'
import { TConfigState } from '../../modules/store';
import { MockInsight } from '../../utils/mocks/config';

const initialState = {
	appLoader: false,
	sideMenuMode: false,
	insight: MockInsight
}

const configSlice = createSlice({
	name: 'config',
	initialState,
	reducers: {
		setAppLoader: (state: TConfigState, { payload: mode }: { payload: boolean }) => {
			state.appLoader = mode
		},
		toggleSideMenuMode: (state: TConfigState, { payload: mode }: { payload: boolean }) => {
			state.sideMenuMode = mode
		},
		setInsight: (state: TConfigState, { payload: overview }) => {
			state.insight = overview;
		}
	}
})


export const { setAppLoader, toggleSideMenuMode, setInsight } = configSlice.actions;

export default configSlice.reducer;
