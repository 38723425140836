type Props = {
  icon: string,
  title: string,
  value: string | number,
}

const InsightCard = ({icon, title, value}: Props) => (
  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-4">
    <div className="card p-1 border">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div className="border border-primary-light border-radius-5 p-1">
            <img src={icon} alt="ICON" width={24} height={24}/>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-5">
            <p className="text-gray-500 mb-2">{title}</p>
            <h4>{value}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InsightCard;
