import { useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";

import { Loading } from "../../utils/helpers/constants";
import Google from "../../assets/images/pngs/google.png";
import TokenHelper from "../../utils/helpers/tokenHelper";
import GrayButton from "../../components/buttons/GrayButton";
import AuthLayout from "../../components/layouts/AuthLayout";
import { onAuthentication } from "../../store/slices/auth.slice";
import PasswordInput from "../../components/forms/PasswordInput";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { queryErrorHandler } from "../../utils/helpers/logicHelper";
import { emailValidator } from "../../utils/services/validator.service";
import { InputValidator, SubMenuType, MenuType } from "../../modules/enums";
import { useGoogleProfileMutation, useLoginProfileMutation, } from "../../store/slices/api.slice";

const Login = () => {
  const dispatch = useDispatch(), isLoggedIn = TokenHelper.authenticationValid();
  const [password, setPassword] = useState<string | undefined>("");
  const [email, setEmail] = useState({ value: "", errorState: false });
  const [loginProfile, { isLoading: loginLoading }] = useLoginProfileMutation();
  const [googleProfile, { isLoading: socialAuthLoading }] =
    useGoogleProfileMutation();
  const isInvalid = !email.value || !password || email.errorState || emailValidator(email.value).validState === InputValidator.INVALID;

  useGoogleOneTapLogin({
    onSuccess: (response) =>
      authenticateGoogleAuth({
        token: response.credential,
        platform: "GOOGLE_TAP",
      }).catch((err) => console.trace(err)),
    onError: () =>
      toast.error(`Error, cannot validate user via google authentication`),
    cancel_on_tap_outside: false,
  });

  const googleCallbackResponse = useGoogleLogin({
    onSuccess: (response) =>
      authenticateGoogleAuth({
        token: response["access_token"],
        platform: "GOOGLE",
      }),
    onError: () =>
      toast.error("Error, cannot validate user via google authentication"),
  });

  const errorHandler = (response: TQueryResponse) => {
    const errorMessage = queryErrorHandler("register", response);
    toast.error(errorMessage);

  };

  const authenticateUser = async () => {
    let results = await loginProfile({ email: email.value, password });
    if (results.data && results.data.status === Loading.SUCCESS)
      onAuthentication(dispatch, results.data.data.access);
    else errorHandler(results);
  };

  const authenticateGoogleAuth = async (payload: { token?: string, platform: string }) => {
    let results = await googleProfile(payload);
    if (results.data && results.data.status === Loading.SUCCESS)
      onAuthentication(dispatch, results.data.data.access);
    else errorHandler(results);
  };

  if (isLoggedIn) return <Navigate to={{ pathname: "/" }} />;

  return (
    <AuthLayout type={SubMenuType.LOGIN}>
      <h3 className="text-lg-start text-center">Sign in</h3>
      <p className="text-office-brown-700 mb-4 text-lg-start text-center">
        Use your email to sign in or provide your details
      </p>
      <GrayButton
        btn={{
          text: 'Continue with Google',
          outline: true,
          image: {
            width: "15",
            height: "15",
            imgAlt: 'google-auth',
            imgClass: 'mb-1 me-2',
            imgSrc: Google,
          },
          loadState: socialAuthLoading,
          btnClassName: 'btn-block',
          clickFn: () => googleCallbackResponse()
        }} />

      <div className="custom-line-through">
        <small>OR</small>
      </div>

      <div className="form-floating mb-4">
        <input type="text" value={email.value} placeholder="email address"
          className={`form-control form-lightblue ${email && email.errorState && "is-invalid"}`}
          onChange={(e) => emailValidator(e.target.value).validState === InputValidator.INVALID ? setEmail({ value: e.target.value.toLowerCase(), errorState: true, }) : setEmail({ value: e.target.value.toLowerCase(), errorState: false, })} />
        <label>Email Address</label>
      </div>
      <PasswordInput name="password" value={password} isValidatorRequired={false} setInput={(value) => setPassword(value)} />
      <NavLink to={`/${MenuType.AUTH}/${SubMenuType.FORGOT_PASSWROD}`} className="float-end mb-4 fw-500 text-decoration-none">
        Forgot Password
      </NavLink>

      <PrimaryButton
        btn={{
          text: 'Sign in',
          loadState: loginLoading,
          disabledState: isInvalid,
          btnClassName: 'mb-4 btn-block',
          clickFn: authenticateUser
        }} />

      <div className="d-flex justify-content-center">
        <div className="mt-4 p-3 bg-primary-light">
          <p className="text-office-brown-800 text-center">
            Don’t have an account?
            <NavLink to={`/${MenuType.AUTH}/${SubMenuType.ONBOARD}`} className="text-primary-emphasis ms-2 fw-bold">
              Sign up
            </NavLink>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;