import axios, { AxiosHeaders, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import TokenHelper from "../helpers/tokenHelper";
import CookieHelper from "../helpers/cookieHelper";
import { AppInfo, AppKeys } from "../helpers/constants";
import { MenuType, SubMenuType } from '../../modules/enums';

export const LOCALHOST = "localhost";
// export const LOCALHOST = "192.168.100.3";
export const BETA_BASEURL = "app-beta.mytruq.com";
export const PROD_BASEURL = "app.mytruq.com";

export const getBaseUrl = (): TBaseUrl => {
  let organisation = AppInfo.ORGANISATION, dodge = AppInfo.DODGE_BASE_URL, backoffice = AppInfo.BACKOFFICE_BASE_URL;
  if (window.location.hostname === LOCALHOST || window.location.hostname === BETA_BASEURL) {
    dodge = AppInfo.DODGE_STAGING_BASE_URL
    backoffice = AppInfo.BACKOFFICE_STAGING_BASE_URL
    organisation = AppInfo.STAGING_ORGANISATION
  } else if (window.location.hostname === PROD_BASEURL) {
    dodge = AppInfo.DODGE_BASE_URL
    backoffice = AppInfo.BACKOFFICE_BASE_URL
    organisation = AppInfo.ORGANISATION
  }
  return { dodge, backoffice, organisation }
};


const headers = { "Content-Type": "application/json", organisation: `${getBaseUrl()['organisation']}` }
const dodgeInstance: AxiosInstance = axios.create({ baseURL: getBaseUrl()['dodge'], headers })

dodgeInstance.interceptors.request.use((config: InternalAxiosRequestConfig<AxiosHeaders>) => {
  let token = TokenHelper.getToken(), headers = { Authorization: `Bearer ${token}`, ...config.headers }
  if (TokenHelper.authenticationValid()) config.headers = headers as AxiosHeaders['headers'];
  else config.headers = { ...config.headers } as AxiosHeaders['headers'];
  return config;
}, error => Promise.reject(error));

dodgeInstance.interceptors.response.use((response: AxiosResponse<TApiResponse<any>>) => response, error => {
  let token = TokenHelper.getToken();
  if (error.response.status === 401 && token) {
    CookieHelper.remove(AppKeys.TOKEN);
    window.location.href = `/${MenuType.AUTH}/${SubMenuType.LOGIN}`;
  } else return error.response;
});

export { dodgeInstance };
