import { ChangeEvent, Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import moment from "moment/moment";

import { TStoreState } from "../../../modules/store";
import TripConfirmation from "./atom/TripConfirmation";
import { DomType, MenuType } from "../../../modules/enums";
import { Loading } from "../../../utils/helpers/constants";
import PageInfo from "../../../components/layouts/PageInfo";
import PageLayout from "../../../components/layouts/PageLayout";
import BookingService from "../../../utils/services/booking.service";
import MapsSelectHook from "../../../components/hooks/MapsSelectHook";
import DatePickerHook from "../../../components/hooks/DatePickerHook";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { validateIfFloat, validateIfInteger } from "../../../utils/services/validator.service";
import {
	formatAmountToNumber, formatTripTypeByCategory, generateString, queryErrorHandler, toTitleCase
} from "../../../utils/helpers/logicHelper";
import {
	useCreateBusinessTripMutation, useCreateTripMutation, useGetExtrasQuery, useGetTripTypesQuery, useGetWalletQuery, useUpdateTripMutation
} from "../../../store/slices/api.slice";

const BookTrip = () => {
	const navigate = useNavigate();
	const domType = useSelector((state: TStoreState) => state.auth.domType);
	const business: TBusiness = useSelector((state: TStoreState) => state.auth.domObject) as TBusiness;
	const organisation = useSelector((state: TStoreState) => state.auth.organisation);
	const [createTrip] = useCreateTripMutation();
	const [updateTrip] = useUpdateTripMutation();
	const [createBusinessTrip] = useCreateBusinessTripMutation();
	const { data: wallet, isLoading: isLoadingWallet } = useGetWalletQuery("");
	const { data: extraServices, isLoading: loadingExtras } = useGetExtrasQuery("");
	const { data: initialTripTypes, isLoading: loadingTripTypes } = useGetTripTypesQuery("");

	const [date, setDate] = useState<Date | null>(null);
	const [source, setSource] = useState<TTripLocation>();
	const [estimatedValue, setEstimatedValue] = useState("");
	const [extras, setExtra] = useState<TExtra[]>([]);
	const [tripTypes, setTripTypes] = useState<TTripType[]>([]);
	const [tripExtras, setTripExtras] = useState<TExtra>({});
	const [tripType, setTripType] = useState<TTripType | null>(null);
	const [destination, setDestination] = useState<TTripLocation>();
	const [categories, setCategories] = useState<TVehicleCategory[]>([]);
	const [category, setCategory] = useState<TVehicleCategory | string | undefined>();
	const [confirmationData, setConfirmationData] = useState<TTrip | TBusinessTrip | null>(null);
	const [confirmedVehicleData, setConfirmedVehicleData] = useState<TVehicleCategory | null>(null);
	const [loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [updateBookingState, setUpdateBookingState] = useState(false);
	const [tripConfirmationRender, setTripConfirmationRender] = useState(false);
	const couponConfig = (business && business['merchant_configuration']) ? business['merchant_configuration'].find(each => each.name === "coupon") : "";
	const loadState = isLoading || loadingExtras || loadingTripTypes || isLoadingWallet;
	const isInvalid = !source || Object.entries(source).length < 0 || !destination
		|| Object.entries(destination).length < 0 || !category || !date;


	useEffect(() => {
		if (business && domType === DomType.BUSINESS) getVehicleCategories().catch(() => setLoading(false));
	}, [business, domType]);

	useEffect(() => {
		let customerTripType = (initialTripTypes && initialTripTypes.data) ? formatTripTypeByCategory(initialTripTypes.data, DomType.INDIVIDUAL) : [];
		setTripTypes(customerTripType);
	}, [initialTripTypes]);

	useMemo(() => {
		const data = (loadingExtras || !extraServices || !extraServices.data) ? [] : extraServices.data;
		setExtra(data);
	}, [loadingExtras, extraServices])

	const goBack = () => navigate(-1);

	const selectCategory = (event: ChangeEvent<HTMLSelectElement>) => setCategory(event.target.value);

	const updateExtras = (value: string, key: string) => setTripExtras(prev => ({ ...prev, [key]: value ? parseInt(value) : 0 }));

	const selectIndividualtripType = async (event: ChangeEvent<HTMLSelectElement>) => {
		setCategories([]);
		setCategory("");
		setTripType("");
		const { data: response } = await BookingService.getCustomerVehicleCategories(event.target.value);
		if (response && response.data && response.status === Loading.SUCCESS) {
			setCategories(response.data);
		} else setCategories([]);
		setTripType(event.target.value);
	}


	const getVehicleCategories = async () => {
		setIsLoading(true);
		let configObj = business["config"] as TCostConfig;

		const { data: response } = await BookingService.getBusinessVehicleCategories(business["id"], configObj.config);
		if (response && response.data && response.status === Loading.SUCCESS) {
			setCategories(response.data);
		} else setCategories([]);
		setIsLoading(false);
	}

	const updateLocation = (placeData: TTripLocation, type: "SOURCE" | "DESTINATION") => {
		let placeObj = {
			city: placeData.city,
			state: placeData.state,
			address: placeData.address,
			latitude: placeData['latitude'],
			longitude: placeData['longitude']
		};
		if (type === "SOURCE") setSource(prevState => ({ ...prevState, ...placeObj }))
		if (type === "DESTINATION") setDestination(prevState => ({ ...prevState, ...placeObj }))
	}

	const handleTripBooking = async () => {
		setLoading(true);
		let vehicleInfo = categories.find(data => data.id === category);
		let responseData, trips: Partial<TTrip> | Partial<TTrip>[] = {
			source,
			category_size: category as string,
			destination: { ...destination, stops: { stopovers: [], number: 0 } } as TTripLocation,
			scheduled_date: moment(date).format()
		};
		if (couponConfig && couponConfig['configuration'] && couponConfig['configuration']['code']) trips.coupon = couponConfig['configuration']['code']

		if (business && domType === DomType.BUSINESS) {
			let config = business.config as string;
			trips = [{ ...trips, trip_type: config, waybill_number: generateString(8) }];
			let payload = { business: business.id, trips };
			responseData = await createBusinessTrip(payload);

		} else {
			trips = {
				...trips, extras: tripExtras,
				estimated_value: formatAmountToNumber(estimatedValue),
				trip_type: tripType
			};
			if (updateBookingState) responseData = await updateTrip({ id: confirmationData?.id as string, body: trips })
			else responseData = await createTrip(trips);
		}
		if (responseData && responseData.data && responseData.data.status === Loading.SUCCESS) {
			setTripConfirmationRender(true);
			setConfirmationData(responseData.data.data);
			setConfirmedVehicleData(vehicleInfo as TVehicleCategory);
		} else {
			const errorMessage = queryErrorHandler('book trip', responseData)
			toast.error(errorMessage);
		}
		setLoading(false);
	}

	return (
		<PageLayout menu={MenuType.TRIPS} loading={loadState}>
			{(tripConfirmationRender && confirmationData) && confirmedVehicleData ?
				<TripConfirmation domType={domType} wallet={wallet.data} organisation={organisation}
					tripConfirmationStatus={tripConfirmationRender} tripPayload={confirmationData} vehicle={confirmedVehicleData}
					setUpdateState={value => setUpdateBookingState(value)}
					setTripConfirmation={value => setTripConfirmationRender(value)} />
				:
				<Fragment>
					<div className="d-inline-flex gap-3 cursor-pointer" onClick={goBack}>
						<i className="zmdi zmdi-chevron-left fs-3" />
						<h4>Go back</h4>
					</div>
					<div className="row mt-4">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<PageInfo name="Get A Trip Quote" description="Get a quote for your trip" />

									{domType === DomType.INDIVIDUAL &&
										<div className="row mt-4">
											<div className="col-sm-12 col-md-6">
												<div className="form-floating">
													<select className="form-select form-lightblue" onChange={selectIndividualtripType}>
														<option disabled={true} defaultValue="">Select your trip type</option>
														{tripTypes && tripTypes.map(each => (
															<option key={each["customized_name"]} value={each["customized_name"]} defaultValue={tripType}>
																{each["customized_name"]}
															</option>
														))}
													</select>
													<label>Trip type<span className="text-danger ms-1">*</span></label>
												</div>
											</div>
										</div>
									}
									<div className="row mt-4">
										<div className="col-sm-12 col-md-6 mb-4">
											<MapsSelectHook label="Pick Up location" isLabelRequired={true} placeholder="Source"
												setInitialValue={source?.address}
												setLocation={value => updateLocation(value, "SOURCE")} />

										</div>
										<div className="col-sm-12 col-md-6 mb-4">
											<MapsSelectHook label="Drop Off location" isLabelRequired={true} placeholder="Destination"
												setInitialValue={destination?.address}
												setLocation={value => updateLocation(value, "DESTINATION")} />
										</div>
										{(domType === DomType.BUSINESS || (domType === DomType.INDIVIDUAL && tripType)) &&
											< div className="col-sm-12 col-md-6 mb-4">
												<div className="form-floating">
													<select className="form-select form-lightblue" onChange={selectCategory}>
														<option disabled={true} defaultValue="">Select category size</option>
														{(categories && categories.length > 0) && categories.map(each => (
															<option key={each["id"]} value={each["id"]} defaultValue={category as string}>
																{each["category"]} {each["tonnage"]}
															</option>
														))}
													</select>
													<label>Vehicle category<span className="text-danger ms-1">*</span></label>
												</div>
											</div>
										}

										<div className="col-sm-12 col-md-6 mb-4">
											<DatePickerHook placeholder="Select scheduled date" dateFormat="MMMM d, yyyy h:mm aa"
												selected={date} getDate={setDate} timeIntervals={30} filterTime={true}
												showYearDropdown withPortal showTimeSelect isLabelRequired
												setMinDate={new Date()} label="Scheduled Date"
												className="form-control form-lightblue" />
										</div>
										{domType === DomType.INDIVIDUAL &&
											<Fragment>
												<div className="col-sm-12 col-md-6 mb-4">
													<div className="form-floating">
														<input type="text" placeholder="Estimated Worth of Item"
															className="form-control form-lightblue"
															value={estimatedValue}
															onKeyPress={validateIfFloat}
															onChange={(e) => setEstimatedValue(e.target.value)}
														/>
														<label>Estimated value of Item<span className="text-danger ms-1">*</span></label>
													</div>
												</div>
												{(extras && extras.length > 0) && extras.map(item =>
													<div key={item['key']} className="col-sm-12 col-md-6 mb-4">
														{!item['key'].toString().toLowerCase().includes('delivery') &&
															<div className="form-floating">
																<input type="text" placeholder="Estimated Worth of Item"
																	className="form-control form-lightblue"
																	value={(tripExtras && tripExtras[item['key']]) ? tripExtras[item['key']] : ""}
																	onKeyPress={validateIfInteger}
																	onChange={event => updateExtras(event.target.value, item['key'] as string)}
																/>
																<label>Number of {item.name ? toTitleCase(item.name as string) : toTitleCase(item['key'] as string)}</label>
															</div>
														}
													</div>
												)}
											</Fragment>
										}
									</div>
									<div className="d-flex justify-content-end">
										<PrimaryButton btn={{
											text: updateBookingState ? "Update Quote" : "Get a Quote",
											btnClassName: 'px-5',
											loadState: loading,
											disabledState: isInvalid,
											clickFn: handleTripBooking
										}} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			}
		</PageLayout>
	)
}

export default BookTrip;