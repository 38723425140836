import { formatAmountToString, formatNumber } from "../../../../utils/helpers/logicHelper";


type Props = {
  element: TStopConfig,
  currency: string,
};

const StopConfig = ({ element, currency }: Props) => (
  <>
    <div className="row">
      <div className="col-sm-6 col-lg-6 col-xl-3">
        <div className="form-floating mb-4">
          <input type="text" placeholder="Near/Far Determinant (kms)"
            className="form-control bg-light" readOnly={true}
            value={(element && element["distance"]) ? formatNumber(element["distance"]) : 0} />
          <label>Near/Far Determinant (kms)</label>
        </div>
      </div>
      <div className="col-sm-6 col-lg-6 col-xl-3">
        <div className="form-floating mb-4">
          <input type="text" placeholder="Stop Count"
            className="form-control bg-light" readOnly={true}
            value={(element && element["stops"]) ? formatNumber(element["stops"]) : 0}

          />
          <label>Stop Count</label>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-6 col-lg-6 col-xl-3">
        <div className="input-group mb-4">
          <span className="input-group-text">{currency}</span>
          <div className="form-floating">
            <input type="text" placeholder="Flat Rate (Near Trips)"
              className="form-control bg-light" readOnly={true}
              value={(element && element["low_flat_rate"]) ? formatAmountToString(element["low_flat_rate"]) : 0} />
            <label>Flat Rate (Near Trips)</label>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-lg-6 col-xl-3">
        <div className="input-group mb-4">
          <span className="input-group-text">{currency}</span>
          <div className="form-floating">
            <input
              type="text" placeholder="Flat Rate (Far Trips)"
              className="form-control bg-light" readOnly={true}
              value={(element && element["high_flat_rate"]) ? formatAmountToString(element["high_flat_rate"]) : 0} />
            <label>Flat Rate (Far Trips)</label>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-lg-6 col-xl-3">
        <div className="input-group mb-4">
          <span className="input-group-text">{currency}</span>
          <div className="form-floating">
            <input type="text" placeholder="Stop Rate (Near Trips)"
              className="form-control bg-light" readOnly={true}
              value={(element && element["low_stops_rate"]) ? formatAmountToString(element["low_stops_rate"]) : 0} />
            <label>Stop Rate (Near Trips)</label>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-lg-6 col-xl-3">
        <div className="input-group mb-4">
          <span className="input-group-text position-relative">{currency}</span>
          <div className="form-floating">
            <input type="text" placeholder="Stop Rate (Far Trips)"
              className="form-control bg-light" readOnly={true}
              value={(element && element["high_stops_rate"]) ? formatAmountToString(element["high_stops_rate"]) : 0} />
            <label>Stop Rate (Far Trips)</label>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default StopConfig;