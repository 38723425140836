import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import moment from "moment";

import { TStoreState } from "../../../modules/store";
import { MockTrip } from "../../../utils/mocks/trips";
import { TripMap } from "../../../utils/helpers/mappers";
import { Loading } from "../../../utils/helpers/constants";
import PageInfo from "../../../components/layouts/PageInfo";
import PageLayout from "../../../components/layouts/PageLayout";
import BookingService from "../../../utils/services/booking.service";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import TripJourneyModal from "../../../components/modals/TripJourneyModal";
import { DomType, MenuType, TripSystemStatus } from "../../../modules/enums";
import MapOutlineSVG from "../../../assets/images/svgs/map-outline-white.svg";
import AdvancePaymentModal from "../../../components/modals/AdvancePaymentModal";
import { useGetTripMutation, useGetWalletQuery } from "../../../store/slices/api.slice";
import {
  formatAmountToString, formatNumber, getUserJourneyDates, queryErrorHandler, toTitleCase
} from "../../../utils/helpers/logicHelper";


const TripInformation = () => {
  const navigate = useNavigate(), { reference } = useParams();
  const domType = useSelector((state: TStoreState) => state.auth.domType);
  const organisation = useSelector((state: TStoreState) => state.auth.organisation);

  const { data: wallet, isLoading: isLoadingWallet } = useGetWalletQuery("");
  const [getTrip, { isLoading }] = useGetTripMutation();
  const [trip, setTrip] = useState<TTrip>(MockTrip);
  const [extras, setExtras] = useState({});
  const [timeLine, setTimeLine] = useState<{ [x: string]: string; }>();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [trackTripRender, setTrackTripRender] = useState(false);
  const [advancePaymentRender, setAdvancePaymentRender] = useState(false);
  const loadState = isLoadingWallet || isLoading;

  useEffect(() => {
    getTripInfo().catch(error => console.log(error));
  }, []);

  const goBack = () => navigate(-1);

  const handleSuccessResponse = () => {
    toast.success('Successfully paid for trip. Assigning to driver...');
    navigate(`/${MenuType.TRIPS}/${trip.reference}`);
  }

  const paymentErrorHandler = (responseData: { data?: any; error?: any; }) => {
    const errorMessage = queryErrorHandler('pay for this trip', responseData);
    toast.error(errorMessage);
  }

  const getTripInfo = async () => {
    let response = await getTrip({ reference })

    if (response && response.data && response.data.data && response.data.data[0]) {
      let timelines = getUserJourneyDates(response.data.data[0]);
      setTimeLine(timelines);
      setTrip(response.data.data[0]);
      setExtras(response.data.data[0].extras);
    } else {
      const errMessage = queryErrorHandler('get trip information ', response)
      toast.error(errMessage);
      navigate(`/${MenuType.TRIPS}`);
    }
  }

  const getCheckoutUrl = async () => {
    setLoadingPayment(true);
    let responseData;
    const callbackUrl = `${window.location.origin}/trips/${trip.reference}/`;

    if (trip && trip.quote && trip.quote['checkout_url']) {
      setLoadingPayment(false);
      return window.open(trip.quote['checkout_url'], '_self')?.focus();
    } else if (trip['business_tripset'] && Object.entries(trip['business_tripset']).length > 0) {
      responseData = await BookingService.checkoutBusiness(trip['business_tripset']['tripset_id'], trip['business_tripset']['business'], callbackUrl);
    } else responseData = await BookingService.checkout(trip.id, callbackUrl);

    if (responseData && responseData.data && responseData.data.data && responseData.data.data.checkout_url && responseData.data.status === Loading.SUCCESS) window.open(responseData.data.data.checkout_url, '_self')?.focus();
    else paymentErrorHandler(responseData);
    setLoadingPayment(false);
  }

  const payWithWallet = async () => {
    let responseData;
    setLoadingPayment(true);

    if (trip['business_tripset'] && Object.entries(trip['business_tripset']).length > 0) {
      responseData = await BookingService.walletBusinessCheckout(trip['business_tripset']['tripset_id'], trip['business_tripset']['business']);
    } else responseData = await BookingService.walletCheckout(trip.id);
    if (responseData && responseData.data && responseData.data.status === Loading.SUCCESS) {
      toast.error(responseData.data.message);
      toast.success(responseData.data.message);
      await getTrip("");
    } else paymentErrorHandler(responseData);
    setLoadingPayment(false);
  }

  const changeAdvancePaymentRenderStatus = () => setAdvancePaymentRender(false);

  const advancePaymentModal = advancePaymentRender ?
    <AdvancePaymentModal onchange={changeAdvancePaymentRenderStatus} props={{ trip, wallet: wallet.data }}
      onSuccessResponse={handleSuccessResponse} /> : null;

  const tripJourneyModal = trackTripRender ?
    <TripJourneyModal onchange={() => setTrackTripRender(false)} tripTimeline={timeLine} trip={trip} /> : null;


  return (
    <PageLayout menu={MenuType.TRIPS} loading={loadState}>
      <Fragment>
        {tripJourneyModal}
        {advancePaymentModal}
      </Fragment>
      <div className="d-flex justify-content-between flex-wrap">
        <div className="d-inline-flex gap-3 cursor-pointer" onClick={goBack}>
          <i className="zmdi zmdi-chevron-left fs-3" />
          <PageInfo name="Trip Information" description={`Order ID: ${trip && trip.reference}`} />
        </div>
        <div className="d-flex gap-3">
          {(trip && !trip["is_paid"]) &&
            <Dropdown className="d-inline" drop="down-centered">
              <Dropdown.Toggle variant="outline-primary">
                Make Trip Payment {loadingPayment && <i className="zmdi zmdi-spinner zmdi-hc-spin text-primary ms-2" />}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="fs-6" onClick={getCheckoutUrl}>
                  Pay with Card
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="fs-6" onClick={payWithWallet}>
                  Pay with Wallet
                </Dropdown.Item>
                {((trip['business_tripset'] && Object.entries(trip['business_tripset']).length > 0 && Object.entries(trip['quote']).length > 0)
                  && (wallet && wallet.data && wallet.data.extras && wallet.data.extras["advanced_payment_status"])
                  && (organisation && organisation["wallet_configs"] && organisation["wallet_configs"]["advance_payment_status"])) &&
                  <Fragment>
                    <Dropdown.Divider />
                    <Dropdown.Item className="fs-6" onClick={() => setAdvancePaymentRender(true)}>
                      Part Payment
                    </Dropdown.Item>
                  </Fragment>
                }
              </Dropdown.Menu>
            </Dropdown>
          }

          <PrimaryButton btn={{
            text: 'Track Trip',
            image: {
              imgSrc: MapOutlineSVG,
              width: "24",
              height: "24",
              imgClass: "me-2",
            },
            clickFn: () => setTrackTripRender(true)
          }} />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-12 col-xl-6 mb-4">
          <div className="card">
            <div className="card-header bg-white border-bottom-0">
              <h5>Order ID: {trip && trip.reference}</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <p className="text-black-2 fw-500">Scheduled Delivery Date:</p>
                  <p className="text-primary fw-500">
                    {(trip && trip.scheduled_date && moment(trip.scheduled_date).isValid())
                      ? moment(trip.scheduled_date).format("LLL") : "-"
                    }
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 mt-3">
                  <p className="text-black-2 fw-500 text-end">Driver Assigned:</p>
                  <p className="text-primary fw-500 text-end">
                    {(trip && trip["driver"] && trip["driver"].user)
                      ? `${trip["driver"].user.first_name} ${trip["driver"].user.last_name}` : "No Driver Assigned"
                    }
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 mt-3">
                  <p className="text-black-2 fw-500">Trip Status:</p>
                  <p
                    className={`text-primary fw-500 ${trip["system_status"] ? `text-${TripMap[trip["system_status"] as TripSystemStatus].fill}` : 'text-primary'}`}>
                    {(trip && trip["status"]) ? trip["status"] : "-"}
                  </p>
                </div>
                <div className="col-sm-12 col-md-6 mt-3">
                  <p className="text-black-2 fw-500 text-end">Stops:</p>
                  <p className="text-primary fw-500 text-end">
                    {(trip && trip.destination && trip.destination.stops && trip.destination.stops.number)
                      ? trip.destination.stops.number : "0"
                    }
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 my-3">
                  <ul className="task-list">
                    <li>
                      <i className="task-icon zmdi zmdi-check active" />
                      <h6>Trip Initiated
                        <small className="float-end text-muted fs-11">
                          {(timeLine && timeLine["INITIATED"] && moment(timeLine["INITIATED"]).isValid()) && moment(timeLine["INITIATED"]).format("ll")}
                        </small>
                      </h6>
                    </li>
                    <li>
                      <i className={`task-icon ${trip["is_paid"] ? "zmdi zmdi-check active" : ""}`} />
                      <h6>Payment Confirmed
                        <small className="float-end text-muted fs-11">
                          {(timeLine && timeLine["ORDER-CONFIRMED"] && moment(timeLine["ORDER-CONFIRMED"]).isValid()) && moment(timeLine["ORDER-CONFIRMED"]).format("ll")}
                        </small>
                      </h6>
                    </li>
                    <li>
                      <i
                        className={`task-icon ${(trip["is_transit"] || trip["is_completed"]) ? "zmdi zmdi-check active" : ""}`} />
                      <h6>On the way
                        <small className="float-end text-muted fs-11">
                          {(timeLine && timeLine["TRIP-STARTED"] && moment(timeLine["TRIP-STARTED"]).isValid()) && moment(timeLine["TRIP-STARTED"]).format("ll")}
                        </small>
                      </h6>
                    </li>
                    <li>
                      <i className={`task-icon ${(trip['is_completed']) ? "zmdi zmdi-check active" : ""}`} />
                      <h6>Delivered
                        <small className="float-end text-muted fs-11">
                          {(timeLine && timeLine["COMPLETED"] && moment(timeLine["COMPLETED"]).isValid()) && moment(timeLine["COMPLETED"]).format("ll")}
                        </small>
                      </h6>
                    </li>
                    {(trip && trip['business_tripset']) &&
                      <li>
                        <i
                          className={`task-icon ${(trip['system_status'] === "RECONCILED") ? "zmdi zmdi-check active" : ""}`} />
                        <h6>Reconciled
                          <small className="float-end text-muted fs-11">
                            {(timeLine && timeLine["RECONCILED"] && moment(timeLine["RECONCILED"]).isValid()) && moment(timeLine["RECONCILED"]).format("ll")}
                          </small>
                        </h6>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-xl-6">
          <div className="row">
            <div className="col-12 mb-4 order-2 order-xl-0">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <h6 className="mb-3">Customer Information</h6>
                      <p className="text-black-2 fw-500 mb-3">
                        Full name:
                        <span className="text-primary ms-2">
                          {trip.user && Object.entries(trip.user).length > 0 ? `${toTitleCase(trip.user["first_name"])} ${toTitleCase(trip.user["last_name"])}` : ''}
                        </span>
                      </p>
                      <p className="text-black-2 fw-500 mb-3">
                        Email:
                        <span className="text-primary ms-2">
                          {(trip && trip.user && trip.user.email) ?
                            <a href={`mailto:${trip.user["email"]}`}>{trip.user.email}</a> : "-"
                          }
                        </span>
                      </p>
                      <p className="text-black-2 fw-500 mb-3">
                        Phone Number:
                        <span className="text-primary ms-2">
                          {(trip && trip.user && trip.user["phone"]) ?
                            <a href={`tel:${trip.user["phone"]}`}>{trip.user["phone"]}</a> : "-"
                          }
                        </span>
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <h6 className="mb-3">Quote Details</h6>
                      <p className="text-black-2 fw-500 mb-3">
                        Payment Status: {(trip && trip["is_paid"])
                          ? <span className="text-success ms-2">PAID</span>
                          : <span className="text-info ms-2">NOT PAID</span>
                        }
                      </p>
                      {(trip && trip.quote && trip.quote['discount_amount']) &&
                        <p className="text-black-2 fw-500 mb-3">
                          Discount:
                          <span className="text-primary ms-2">
                            {trip.quote.currency}{" "}{trip.quote['discount_amount']}
                          </span>
                        </p>
                      }
                      <p className="text-black-2 fw-500 mb-3">
                        VAT:
                        <span className="text-primary ms-2">
                          {(trip && trip.quote && trip.quote.currency)}{" "}
                          {(trip && trip.quote && trip.quote['total_vat']) ? formatAmountToString(trip.quote['total_vat']) : "-"}
                        </span>
                      </p>
                      <p className="text-black-2 fw-500 mb-3">
                        Charge:
                        <span className="text-primary ms-2">
                          {(trip && trip.quote && trip.quote.currency)}{" "}
                          {(trip && trip.quote && trip.quote['charge']) ? formatAmountToString(trip.quote['charge']) : "-"}
                        </span>
                      </p>
                      <p className="text-black-2 fw-500 mb-3">
                        Estimated Value of Items:
                        <span className="text-primary ms-2">
                          {(trip && trip.quote && trip.quote.currency)}{" "}
                          {trip.estimated_value ? formatAmountToString(trip["estimated_value"]) :
                            (trip && trip.quote && trip['estimated_value']) ? formatAmountToString(trip['estimated_value']) : '-'}
                        </span>
                      </p>
                      <p className="text-black-2 fw-500 mb-3">
                        Estimated Insurance:
                        <span className="text-primary ms-2">
                          {trip && trip.quote && trip.quote.currency}{" "}
                          {(trip && trip.quote && trip.quote['estimated_insurance']) ? formatAmountToString(trip.quote['estimated_insurance']) : "-"}
                        </span>
                      </p>

                      {Object.keys(extras).length > 0 && Object.keys(extras).map((key, index) => (
                        <div key={index}>
                          {(Object.entries(trip.quote).length > 0 && key in trip.quote) &&
                            <p className="text-black-2 fw-500 mb-3">{toTitleCase(key)}:
                              <span className="text-primary ms-2">
                                {(trip && trip.quote && trip.quote.currency)}{" "}
                                {(trip.quote && trip.quote[key]) ? formatAmountToString(trip.quote[key]) : "0"}
                              </span>
                            </p>
                          }
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <h6 className="mt-5 mb-3">Pick Up Address</h6>
                      <p className="text-primary fw-500">
                        {(trip && trip.source && trip.source.address) ? trip.source.address : "-"}
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <h6 className="mt-5 mb-3">Drop Off Location</h6>
                      <p className="text-primary fw-500">
                        {(trip && trip.destination && trip.destination.address) ? trip.destination.address : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 mb-4 order-0 order-xl-1">
              <div className="card">
                <div className="card-body">
                  <h6 className="mb-3">Driver Details</h6>
                  <p className="text-black-2 fw-500 mb-3">
                    Full name:
                    <span className="text-primary ms-2">
                      {(trip && trip["driver"] && trip["driver"].user)
                        ? `${trip["driver"].user.first_name} ${trip["driver"].user.last_name}` : "-"
                      }
                    </span>
                  </p>
                  <p className="text-black-2 fw-500 mb-3">
                    Email:
                    <span className="text-primary ms-2">
                      {(trip && trip["driver"] && trip["driver"].user && trip["driver"].user.email) ?
                        <a href={`mailto:${trip["driver"].user.email}`}>{trip["driver"].user.email}</a> : "-"
                      }
                    </span>
                  </p>
                  <p className="text-black-2 fw-500 mb-3">
                    Phone Number:
                    <span className="text-primary ms-2">
                      {(trip && trip["driver"] && trip["driver"].user && trip["driver"].user["phone"]) ?
                        <a href={`mailto:${trip["driver"].user["phone"]}`}>{trip["driver"].user["phone"]}</a> : "-"}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 mb-4 order-1 order-xl-2">
              <div className="card">
                <div className="card-body">
                  <h6 className="mb-3">More Details</h6>

                  <p className="text-black-2 fw-500 mb-3">
                    Trip Type:
                    <span className="text-primary ms-2">
                      {(trip && trip["trip_type"]) ? trip["trip_type"] : "-"}
                    </span>
                  </p>
                  <p className="text-black-2 fw-500 mb-3">
                    Vehicle Category:
                    <span className="text-primary ms-2">
                      {(trip && trip["category_size"] && typeof trip["category_size"] === 'object' && Object.entries(trip["category_size"]).length > 0)
                        ? `${trip["category_size"]["category"]} - ${trip["category_size"]["tonnage"]} tonne` : "-"
                      }
                    </span>
                  </p>
                  <p className="text-black-2 fw-500 mb-3">
                    Trip Distance:
                    <span className="text-primary ms-2">
                      {(trip && trip["distance"]) ? formatNumber(trip["distance"]) : "0"} km
                    </span>
                  </p>
                  {(domType === DomType.BUSINESS || domType === DomType.INDIVIDUAL) &&
                    <Fragment>
                      {(trip["confirmation"] && (trip["confirmation"]['arrival'] || trip["confirmation"]['delivery'])) &&
                        <p className="text-black-2 fw-500 mb-3">Confirmation Code:
                          <span className="text-primary fs-6 fw-bold ms-2">
                            {trip["confirmation"]['delivery'] ? trip["confirmation"]['delivery']
                              : trip["confirmation"]['arrival'] ? trip["confirmation"]['arrival'] : '*****'}
                          </span>
                        </p>
                      }
                    </Fragment>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default TripInformation;