import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import toast from "react-hot-toast";

import CityConfig from "./atom/CityConfig";
import StopConfig from "./atom/StopConfig";
import FixedConfig from "./atom/FixedConfig";
import VariableConfig from "./atom/VariableConfig";
import { TStoreState } from "../../../modules/store";
import PageInfo from "../../../components/layouts/PageInfo";
import CopySVG from "../../../assets/images/svgs/copy.svg";
import PageLayout from "../../../components/layouts/PageLayout";
import EmptyTable from "../../../components/dataTables/EmptyTable";
import { MenuType, TripTypeOptions } from "../../../modules/enums";
import NotFoundSVG from "../../../assets/images/svgs/empty-state.svg";
import { copyToClipboard, formatAmountToString, toTitleCase } from "../../../utils/helpers/logicHelper";
import { useGetOrganisationFleetQuery, useGetTripTypesQuery, useGetPricingConfigurationQuery }
  from "../../../store/slices/api.slice";


const PriceConfigurations = () => {
  const business: TBusiness = useSelector((state: TStoreState) => state.auth.domObject) as TBusiness;
  const [categorySizes, setCategorySizes] = useState<TVehicleCategory[]>([]);
  const { data: tripTypes, isLoading: loadingTripTypes, isError: errorTripType } = useGetTripTypesQuery('');
  const { data: _categorySizes, isLoading: loadingFleet, isError: errorFleet } = useGetOrganisationFleetQuery('');
  const {data: config, isLoading: loadingPriceConfig, isFetching, isError: errorPriceConfig} = useGetPricingConfigurationQuery((business && business["reference"]));

  const couponConfig = (business && business['merchant_configuration']) ? business['merchant_configuration'].find(each => each.name === "coupon") : "";
  const currency = (business && business.config && typeof business.config === 'object' && business.config.currency) ? business.config.currency : "";
  const businessTripType = (tripTypes && tripTypes.data && tripTypes.data.length > 0 && business && business.config && typeof business.config === 'object') && tripTypes.data.find((each: TTripType) => (typeof business.config === 'object' && each["customized_name"] === business.config.config));

  const errorState = errorPriceConfig || errorFleet || errorTripType;
  const loadState = loadingPriceConfig || isFetching || loadingFleet || loadingTripTypes;  

  useMemo(() => {
    const data = (loadingFleet || !_categorySizes || !_categorySizes.data) ? [] : _categorySizes.data;
    setCategorySizes(data);
  }, [_categorySizes, loadingFleet]);


  const renderPricingHeader = (item: TVehicleCategory, element: TPricingConfig) => {
    const config = business.config as TCostConfig
    return(
    <Accordion.Header>
      {item["category"] ? toTitleCase(item["category"]) : ""} ({item["tonnage"]} tonne) - Commission:{" "}
        {config['commission_type'].toUpperCase() !== 'PERCENTAGE' && config.currency}{" "}
        {formatAmountToString(element["commission"])}{config['commission_type'].toUpperCase() === 'PERCENTAGE' && '%'}
    </Accordion.Header>
  )
}

  const handleCopyDiscountCode = async (code: string) => {
    await copyToClipboard(code);
    toast.success("Discount code has been copied successfully!");
  }

  return (
    <PageLayout menu={MenuType.PRICING_CONFIGURATION} loading={loadState}>
      <div className="row">
        <div className="col-12 mb-3">
          <PageInfo name="Pricing & Discount" description="Business Trip Pricing Configuration & Discount Code" />
        </div>

        {(business && business["is_merchant"]) &&
          <div className="col-sm-8 col-lg-6 col-xl-5 col-xxl-4 my-4">
            <div className="card">
              <div className="card-body">
                <p className="text-black-2 fs-6">Discount Code</p>
                <div className="d-flex justify-content-between mt-3">
                  <h4 className="text-primary">
                    {(couponConfig && Object.entries(couponConfig['configuration']).length !== 0 && couponConfig['configuration']['code'])
                      ? couponConfig['configuration']['code'].toUpperCase() : "-"
                    }
                  </h4>
                  {(couponConfig && Object.entries(couponConfig['configuration']).length !== 0 && couponConfig['configuration']['code'])
                    && <img src={CopySVG} alt="Copy" width={24} height={24}
                      onClick={() => handleCopyDiscountCode(couponConfig['configuration']['code'].toUpperCase())} />
                  }
                </div>
              </div>
            </div>
          </div>
        }

        <div className="col-12">
          <div className="card">
            <div className="card-header bg-white border-0">
              <h5>
                {(businessTripType && (businessTripType["system_name"] === TripTypeOptions.FIXED || businessTripType["system_name"] === TripTypeOptions.VARIABLE))
                  ? toTitleCase(businessTripType["system_name"]) : (businessTripType && businessTripType["customized_name"]) ? toTitleCase(businessTripType["customized_name"]) : ""
                }{" "}Pricing Model
              </h5>
            </div>
            <div className="card-body">
              {(business && Object.entries(business).length > 0) &&
                <Accordion defaultActiveKey="0">
                  {(categorySizes && Array.isArray(categorySizes)) && categorySizes.map((item, index) => {
                    let element = config && config.data && config.data.find((data: TPricingConfig) => item.id === data["category_size"]);

                    if ((businessTripType && businessTripType["system_name"] === TripTypeOptions.DISTRIBUTION_PER_CITY) && (element && element["config_type"] === TripTypeOptions.DISTRIBUTION_PER_CITY)) {
                      let patchObject = { [item.id]: element["price_list"] ? [...element["price_list"]] : [{ DEFAULT: [{ DEFAULT: 0 }] }] }
                      return (
                        <Accordion.Item key={index} eventKey={index.toString()}>
                          {renderPricingHeader(item, element)}
                          <Accordion.Body>
                            <CityConfig patchObject={patchObject} item={item.id} currency={currency} />
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                    if ((businessTripType && businessTripType["system_name"] === TripTypeOptions.DISTRIBUTION_PER_STOP) && (element && element["config_type"] === TripTypeOptions.DISTRIBUTION_PER_STOP)) {
                      return (
                        <Accordion.Item key={index} eventKey={index.toString()}>
                          {renderPricingHeader(item, element)}
                          <Accordion.Body>
                            <StopConfig element={element["stop_config"] ?? {}} currency={currency} />
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                    if ((businessTripType && businessTripType["system_name"] === TripTypeOptions.FIXED) && (element && element["config_type"] === businessTripType["customized_name"])) {
                      return (
                        <Accordion.Item key={index} eventKey={index.toString()}>
                          {renderPricingHeader(item, element)}
                          <Accordion.Body>
                            <FixedConfig element={element["fixed_pricing_config"] ?? []} currency={currency} />
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                    if ((businessTripType && businessTripType["system_name"] === TripTypeOptions.VARIABLE) && (element && element["config_type"] === businessTripType["customized_name"])) {
                      return (
                        <Accordion.Item key={index} eventKey={index.toString()}>
                          {renderPricingHeader(item, element)}
                          <Accordion.Body>
                            <VariableConfig element={element["variable_pricing_config"] ?? []} currency={currency} />
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    }
                  }
                  )}
                </Accordion>
              }
              {(!business || errorState || Object.entries(business).length === 0) &&
                <EmptyTable imgSrc={NotFoundSVG} text="No Pricing Configuration"
                  description="You do not have an active merchant account to see pricing configuration" />
              }
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default PriceConfigurations;