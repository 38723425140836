import { KeyboardEvent } from "react";
import { InputValidator } from "../../modules/enums";

export const NUMBER_REGEX = new RegExp(/.*\d/);
export const LENGTH_REGEX = new RegExp(/.{8,}$/);
export const LOWERCASE_REGEX = new RegExp(/.*[a-z]/);
export const UPPERCASE_REGEX = new RegExp(/.*[A-Z]/);
export const SPECIAL_CHARS_REGEX = new RegExp(/.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/);

export const emailValidator = (email: string): { validState: InputValidator, validEmail: string } => {
	const pattern = /\S+@\S+\.\S+/, validEmail = email.toLowerCase().replace(/ /g, "");
	const validState = pattern.test(validEmail) ? InputValidator.VALID : InputValidator.INVALID;
	return { validState, validEmail }
};

export const phoneValidator = (phone: string): InputValidator => {
	const pattern = /^[+]?[(]?[0-9]{3}[)]?[-\]?[0-9]{3}[-\s]?[0-9]{4,7}$/;
	return pattern.test(phone) ? InputValidator.VALID : InputValidator.INVALID
}

export const validateIfFloat = (event: KeyboardEvent<HTMLInputElement>) => {
	if(event.charCode === 101 || event.charCode === 45) event.preventDefault();
}

export const validateIfInteger = (event: KeyboardEvent<HTMLInputElement>) => {
	if(event.charCode === 101 || (event.charCode === 46 || event.charCode === 45)) event.preventDefault();
}