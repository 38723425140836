import {useState} from "react";

import EyeSVG from "../../assets/images/svgs/eye.svg";
import EyeHideSVG from "../../assets/images/svgs/eye-slash.svg";
import InfoSVG from "../../assets/images/svgs/warning-circle-solid-primary.svg";
import {formatAmountToString} from "../../utils/helpers/logicHelper";

type Props = {
  wallet: TWallet;
}

const WalletCard = ({wallet}: Props) => {

  const [isWalletBalanceMasked, setIsWalletBalanceMasked] = useState(true);
  const totalBalance = (wallet && wallet["ledger_balance"])
    ? formatAmountToString(wallet["ledger_balance"]).split(".") : ['0', '00']


  return (
    <div className="card bg-dark p-2">
      <div className="card-body">
        <p className="fw-bold text-gray-400">Total Balance</p>
        <div className="my-2">
          <div className="d-flex justify-content-between">
            <span className="d-inline-flex">
              <h1 className="text-white">
                {(wallet && wallet["currency"])}{" "}
                {(totalBalance && totalBalance[0] && !isWalletBalanceMasked) ? totalBalance[0] : "********"}
              </h1>
              <h3 className="text-gray-400 mt-auto mb-1">
                .{(totalBalance && totalBalance[1] && !isWalletBalanceMasked) ? totalBalance[1] : '**'}
              </h3>
            </span>
            <div className="my-auto cursor-pointer">
              {isWalletBalanceMasked
                ? <img src={EyeSVG} alt="SHOW_WALLET" width={30} height={30}
                       onClick={() => setIsWalletBalanceMasked(prevState => !prevState)}/>
                : <img src={EyeHideSVG} alt="HIDE_WALLET" width={30} height={30}
                       onClick={() => setIsWalletBalanceMasked(prevState => !prevState)}/>
              }
            </div>
          </div>

          <span className="d-flex mt-2">
            <p className="text-gray-500">
              Available Balance:
              <span className="text-white">
                {" "}{(wallet && wallet["currency"])}{" "}
                {(wallet && wallet["available_balance"] && !isWalletBalanceMasked)
                  ? formatAmountToString(wallet["available_balance"]) : "***********"
                }
              </span>
            </p>
            <img src={InfoSVG} alt="ICON" width={20} height={20}/>
          </span>
        </div>
      </div>
    </div>
  )
}

export default WalletCard;
