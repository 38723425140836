import { useState } from "react";
import { Navigate, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import { Loading } from "../../utils/helpers/constants";
import TokenHelper from "../../utils/helpers/tokenHelper";
import AuthService from "../../utils/services/auth.service";
import { MenuType, SubMenuType } from "../../modules/enums";
import AuthLayout from "../../components/layouts/AuthLayout";
import PasswordInput from "../../components/forms/PasswordInput";
import PrimaryButton from "../../components/buttons/PrimaryButton";

const ResetPassword = () => {
  const navigate = useNavigate(),
    isLoggedIn = TokenHelper.authenticationValid();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string | undefined>("");
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>("");
  const isInvalid = !password || !confirmPassword || password !== confirmPassword;

  const resetPassword = async () => {
    setLoading(true);
    const payload = {
      password: password as string,
      confirm_password: confirmPassword as string,
      token: searchParams.get("token"),
    };

    const { data: responseData } = await AuthService.resetPassword(payload);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      navigate(`/${MenuType.AUTH}/${SubMenuType.LOGIN}`);
      toast.success(responseData.message);
    }
    setLoading(false);
  };

  if (isLoggedIn) return <Navigate to={{ pathname: "/" }} />;

  return (
    <AuthLayout>
      <h3 className="text-lg-start text-center">Reset Password</h3>
      <p className="text-office-brown-700 mb-4 text-lg-start text-center">
        Provide new password to reset your password
      </p>
      <PasswordInput name="enter_new_password" value={password} setInput={(value) => setPassword(value)} isValidatorRequired={false} />
      <PasswordInput name="enter_confirm_new_password" value={confirmPassword} setInput={(value) => setConfirmPassword(value)} isValidatorRequired={false} />
      <PrimaryButton btn={{
        text: 'Set New Password',
        btnClassName: 'mb-3 btn-block',
        loadState: loading,
        disabledState: isInvalid,
        clickFn: resetPassword
      }} />

      <div className="my-4 p-2">
        <p className="text-office-brown-800">
          Request new reset{" "}
          <NavLink to={`/${MenuType.AUTH}/${SubMenuType.FORGOT_PASSWROD}`} className="text-primary-emphasis text-decoration-none fw-bold">
            link
          </NavLink>
        </p>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
