import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";

import { DebounceHook } from "./DebounceHook";
import { Cities } from "../../utils/helpers/cities";
import { Loading } from "../../utils/helpers/constants";
import BookingService from "../../utils/services/booking.service";
import DropdownSVG from "../../assets/images/svgs/arrow-down.svg";

type TMapOptions = {
	name: string,
	place_id: string,
};

type Props = {
	label: string,
	isLabelRequired: boolean,
	placeholder: string,
	setInitialValue: string | undefined,
	setLocation: (value: TLocation) => void
};

const MapsSelectHook = ({ label, isLabelRequired, placeholder, setInitialValue, setLocation }: Props) => {
	const [debounceField, setDebounceField] = useState("");
	const [debounceState, setDebounceState] = useState(false);
	const [locationObject, setLocationObject] = useState<TMapOptions | null>(null);
	const [mapOptions, setMapOptions] = useState<TMapOptions[]>([]);
	const [mapOptionsState, setMapOptionsState] = useState(false);


	const debounceText = DebounceHook(debounceField, 350);

	useEffect(() => {
		if (debounceText && debounceState) callAutoCompleteAPI(debounceText).catch(err => console.trace(err));
	}, [debounceText]);

	useEffect(() => {
		if (locationObject && Object.entries(locationObject).length > 0) callPlacesAPI(locationObject['place_id']).catch(err => console.trace(err));
	}, [locationObject]);

	const updateDebounceField = (value: string) => {
		setDebounceField(value);
		setDebounceState(true);
	}

	const selectMapOption = (item: TMapOptions) => {
		setMapOptionsState(false);
		setDebounceField(item.name);
		setLocationObject(item);
	}

	const getDebounceField = () => {
		if (debounceField) return debounceField;
		else if (setInitialValue) return setInitialValue;
		else return debounceText;
	}

	const callAutoCompleteAPI = async (data: string) => {
		const { data: responseData } = await BookingService.autoCompleteMapsBySearch(data);
		if (responseData.status !== Loading.SUCCESS) console.log(responseData.status);
		else {
			setDebounceState(false);
			setMapOptionsState(true);
			setMapOptions(responseData.data);
		}
	}

	const callPlacesAPI = async (data: string) => {
		if (data) {
			const { data: responseData } = await BookingService.placesApiMap(data);
			if (responseData.status !== Loading.SUCCESS) console.log(responseData.status);
			else {
				let ifExists: any[] = [];
				for (let forEach of responseData.data['address_components']) {
					let found = Cities.filter(eachCity => forEach['long_name'].toLowerCase() === eachCity.city.toLowerCase());
					ifExists = ifExists.concat(found);
				}
				let city = ifExists.length > 0 ? ifExists[0]['city'].toUpperCase() : 'DEFAULT';
				let state = ifExists.length > 0 ? ifExists[0]['state'] : 'DEFAULT';
				let placeData: TLocation = {
					city, state, address: responseData.data.address,
					latitude: responseData.data['geometry']['latitude'],
					longitude: responseData.data['geometry']['longitude']
				};
				setLocation(placeData)
			}
		} else return toast.error("We are unable to resove this location, Please select another location that is approximately close to your choice of location");
	}


	return (
		<div className="position-relative">
			<div className="form-floating mb-1">
				<input type="text" className="form-control form-lightblue pe-2rem"
					value={getDebounceField()}
					placeholder={placeholder ? placeholder : ""}
					onChange={e => updateDebounceField(e.target.value)} />
				<img src={DropdownSVG} alt="ARROW_ICON" className="form-icon bg-gray-500" width={24} height={24} />
				{label &&
					<label>{label}{isLabelRequired && <span className="text-danger ms-1">*</span>}</label>
				}
			</div>
			{mapOptionsState &&

				<Dropdown.Menu show className="custom-dropdown">
					{mapOptions.slice(0, 7).map((item, index) =>
						<Dropdown.Item key={index} className="custom-dropdown-item" onClick={() => selectMapOption(item)}>
							{item.name}
						</Dropdown.Item>
					)}
				</Dropdown.Menu>
			}
		</div>
	);
};

export default MapsSelectHook;
