import { DomType } from "../../modules/enums";

export const copyToClipboard = async (text: string) => {
	return await navigator.clipboard.writeText(text);
};

export const formatAmountToNumber = (data: string) => {
	const newData = data.replace(/[^0-9]/g, "");
	return Number(newData);
};

export const formatAmountToString = (data: string | number | bigint) => {
	const format = !data ? "0.00" : data;
	if (format.toString() === "0.00") return format.toLocaleString();
	else if (format.toString().includes(",")) {
		const newFormat = format.toString().replace(/,/g, "");
		const formattedNumber = parseFloat(newFormat);
		return formattedNumber.toLocaleString();
	} else if (format.toString().includes(".")) {
		const formatValue = truncateDecimalValue(format as string);
		return formatValue.toLocaleString();
	} else return `${format.toLocaleString()}`;
};

export const formatNumber = (value: string | number | bigint) => {
	const numFormat = Intl.NumberFormat('en-US');
	numFormat.format(value as number);
	return numFormat.format(value as number);
};

export const formatTripTypeByCategory = (tripTypes: TTripType[], type: DomType): TTripType[] => {
	let businessCategory: TTripType[] = [], customerCategory: TTripType[] = [];
	for (let tripType of tripTypes) {
		if (tripType.system_name.startsWith('DISTRIBUTION') && type === DomType.BUSINESS) {
			const formatData = {
				...tripType,
				label: tripType.customized_name ? tripType.customized_name : tripType.system_name,
				value: tripType.customized_name
			}
			businessCategory.push(formatData);
		}
		if (!tripType.system_name.startsWith('DISTRIBUTION') && type === DomType.INDIVIDUAL) {
			const formatData = {
				...tripType,
				label: tripType.customized_name ? `${tripType.customized_name} (${tripType.system_name} MODEL)` : tripType.system_name,
				value: tripType.customized_name
			}
			customerCategory.push(formatData)
		}
	}
	if (type === DomType.BUSINESS) return businessCategory;
	else return customerCategory;
};

export const formatWebsite = (website: string) => {
	if (/(http(s?)):\/\//i.test(website)) return website;
	else return `https://${website}`;
}

export const generateString = (length: number) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}

export const getUserJourneyDates = (payload: TTrip) => {
	let userJourney = {};
	payload["user_journey"].map(item => (userJourney = { ...userJourney, [item["system_status"]]: item["time"] }));
	return userJourney
};

export const queryErrorHandler = (type: string, response?: { data?: any; error?: any; }) => {
	if (!response) return `You cannot ${type.toLowerCase()} at this time, Please try again later.`;
	let errMessage = "";
	if (response && response.error && response.error.data && response.error.data.message) errMessage = response.error.data.message;
	else if (response && response.data && response.data.message) errMessage = response.data.message;
	else errMessage = `You cannot ${type.toLowerCase()} at this time, Please try again later.`;
	return errMessage;
};

export const toTitleCase = (str: string | undefined) => {
	if (!str || str.length === 0) return "";
	str = (/[_&\\/#, +()$~%.'":*?<>{}-]/g).test(str) ? str.replaceAll(/[_&\\/#, +()$~%.'":*?<>{}-]/g, ' ') : str;
	return str.trim().split(' ').map(word => (
		word[0] ? word[0].toUpperCase() + word.substring(1).toLowerCase() : word + word.substring(1).toLowerCase()
	)).join(' ')
};

export const truncateDecimalValue = (value: string) => {
	value = value.toString();
	const numberValue = value.split(".")[0];
	const suffixedNumber = value.split(".")[1].substring(0, 2);
	const decimalCount = value.split(".")[1].length;
	const prefixedNumber = Number(numberValue).toLocaleString();
	if (decimalCount > 2) value = `${prefixedNumber}.${suffixedNumber}`;
	else if (decimalCount < 2) value = `${prefixedNumber}.${suffixedNumber}0`;
	else value = `${prefixedNumber}.${suffixedNumber}`;
	return value;
};