import { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import CloseButton from "../buttons/CloseButton";
import PrimaryButton from "../buttons/PrimaryButton";
import { Loading } from "../../utils/helpers/constants";
import WalletService from "../../utils/services/wallet.service";
import { validateIfFloat } from "../../utils/services/validator.service";

type Props = {
	wallet: TWallet,
	onchange: () => void,
};

const WalletTopUpModal = ({ onchange, wallet }: Props) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("")

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const handleWalletTopUp = async () => {
    setLoading(true);
    let callbackUrl = `${window.location.origin}/wallet`;
    let payload = { amount: amount.split(",").join(""), user_id: wallet.id, callbackUrl };
    let { data: responseData } = await WalletService.topUpWallet(payload);
    if (!responseData || !responseData.status || responseData.status !== Loading.SUCCESS || !responseData.data) toast.error(responseData.message)
    else window.open(responseData.data['checkout_url'], '_self')?.focus();
    setLoading(false);
  };

  return (
    <Modal show={show} backdrop="static" keyboard={false} centered>
      <Modal.Body>
        <div className="row">
          <div className="col-12 mt-0 mb-3"><CloseButton clickFn={handleClose} /></div>
        </div>
        <div className="row mb-5">
          <div className="col-sm-10 offset-sm-1 text-center">
            <h4 className="text-primary">Top up your Wallet</h4>
            <p className="text-black-3 text-center mt-1">Enter amount you want top up into your wallet</p>
            <div className="input-group my-3">
              <span className="input-group-text bg-primary-lighter">{(wallet && wallet["currency"])}</span>
              <div className="form-floating">
                <input type="number" inputMode="numeric" className="form-control form-lightblue" id="amount"
                  placeholder="amount" onKeyPress={validateIfFloat}
                  onChange={event => setAmount(event.target.value)} />
                <label htmlFor="amount">Enter Amount you want to Top up <span className="text-danger">*</span></label>
              </div>
            </div>
            <PrimaryButton 
              btn={{
                text: 'Proceed',
                btnClassName: 'btn-block',
                loadState: loading,
                disabledState: !amount,
                clickFn: handleWalletTopUp
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default WalletTopUpModal;
