import { useNavigate } from 'react-router-dom';
import ErrorSVG from "../../../assets/images/svgs/Error.svg";
import PrimaryButton from '../../../components/buttons/PrimaryButton';

const Error403 = () => {
	const navigate = useNavigate();

	const handleClick = () => navigate(-1)

	return (
		<div className='error-body min-vh-100'>
			<img src={ErrorSVG} alt='FORBIDDEN' width={540} height={400} />
			<p className='error-text mt-3'>Access Denied! Sorry, but you do not have permission to access this page, Please
				contact the website administrator for assistance or return to the homepage</p>
			<PrimaryButton btn={{
				text: "Go Back",
				btnClassName: 'px-4 mt-3',
				clickFn: handleClick
			}} />
		</div>
	)
}

export default Error403