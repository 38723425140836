import CryptoJS from 'crypto-js';
import {AppKeys} from "./constants";

const EncryptionHelper = {
  encrypt: <T>(data: T): CryptoJS.lib.CipherParams | undefined => {
    if (!data) return;
    else return CryptoJS.AES.encrypt(JSON.stringify(data), AppKeys.TOKEN);
  },

  decrypt: (data: string | null) => {
    if (!data) return null;
    else {
      const bytes = CryptoJS.AES.decrypt(data.toString(), AppKeys.TOKEN);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  },

  jwtDecode: (data: string) => {
    if (!data) return null;
    else if (typeof data === 'object') return data;
    else {
      const base64Url = data.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    }
  }
};

export default EncryptionHelper;