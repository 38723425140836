import { useState } from "react";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import CloseButton from "../buttons/CloseButton";
import PrimaryButton from "../buttons/PrimaryButton";
import { Loading } from "../../utils/helpers/constants";
import BookingService from "../../utils/services/booking.service";
import { formatAmountToString } from "../../utils/helpers/logicHelper";

type Props = {
	props: { trip: TTrip, wallet: TWallet },
	onchange: () => void,
	onSuccessResponse: (arg: boolean) => void
};

const AdvancePaymentModal = ({ onchange, props, onSuccessResponse }: Props) => {
	const [show, setShow] = useState(true);
	const [loading, setLoading] = useState(false);
	const walletConfig = (props && props.wallet && props.wallet.extras);
	const percentagePayable = Number(100 - Number(walletConfig["threshold_rate"]));
	const totalCharge = Number(((1 + (walletConfig["organisation_rate"] / 100)) * Number(props.trip.quote["charge"])).toFixed(2));
	const balanceAmount = (percentagePayable === 0) ? totalCharge : Number(((percentagePayable / 100) * Number(props.trip.quote["charge"])).toFixed(2));
	const InitialDeposit = Number(totalCharge - balanceAmount);
	const interest = Number(totalCharge - Number(props.trip.quote["charge"]));

	const handleClose = () => {
		setShow(false);
		onchange();
	};

	const completeAction = (responseMessage: string) => {
		toast.success(responseMessage);
		handleClose()
		onSuccessResponse(true);
	}

	const handleTripInstallmentPayment = async () => {
		setLoading(true);
		let responseData;

		if(props && (props.trip['business_tripset'] || props.trip['business'])){
			if (props && props.trip['business_tripset'] && Object.entries(props.trip['business_tripset']).length > 0) {
			responseData = await BookingService.advancePaymentBusinessCheckout(props.trip['business_tripset']['tripset_id'], props.trip['business_tripset']['business']);
		} else responseData = await BookingService.advancePaymentBusinessCheckout(props.trip['id'],props.trip['business']['reference'])
	}
	else responseData = await BookingService.advancePaymentCheckout(props.trip.id);

		if (!responseData || !responseData.data || !responseData.data.status || responseData.data.status !== Loading.SUCCESS) toast.error(responseData.data.message);
		else completeAction(responseData.data.message);

		setLoading(false);
	};


	return (
		<Modal show={show} backdrop="static" size="lg" keyboard={false} scrollable centered>
			<Modal.Body>
				<div className="row">
					<div className="col-12 mt-0"><CloseButton clickFn={handleClose} /></div>
				</div>
				<div className="row mb-5">
					<div className="col-sm-10 col-md-8 offset-sm-1 offset-md-2 text-center">
						<h4 className="text-primary">Part Payment</h4>
						<p className="text-black-3 text-center mt-1">Trip advance payment breakdown</p>
					</div>

					<div className="col-sm-10 offset-sm-1">
						<div className="info-group border-bottom">
							<div>
								<p>Percentage Off</p>
								<p className="fw-bold text-end">{walletConfig && walletConfig["threshold_rate"]}%</p>
							</div>
							<div className="pb-2">
								<p>Max Amount Payable</p>
								<p className="fw-bold text-end">{props?.wallet?.currency} {formatAmountToString(Number(walletConfig.outstanding_cap))} </p>
							</div>
						</div>
						<div className="info-group border-bottom">
							<div>
								<p>Trip Charge</p>
								<p className="fw-bold text-end">{props?.wallet?.currency} {formatAmountToString(props.trip.quote.charge)}</p>
							</div>
							<div className="pb-2">
								<p>Interest</p>
								<p className="fw-bold text-end">{props?.wallet?.currency} {formatAmountToString(interest)}</p>
							</div>
						</div>
						<div className="info-group border-bottom">
							<div>
								<p>Initial Deposit</p>
								<p className="fw-bold text-end">{props?.wallet?.currency} {formatAmountToString(InitialDeposit)}</p>
							</div>
							<div className="pb-2">
								<p>Balance Charge</p>
								<p className="fw-bold text-end">{props?.wallet?.currency} {formatAmountToString(Number(balanceAmount))}</p>
							</div>
						</div>
					</div>

					<div className="col-sm-10 col-md-8 offset-sm-1 offset-md-2 mt-4">
						<PrimaryButton
							btn={{
								text: 'Make Payment',
								btnClassName: 'btn-block',
								loadState: loading,
								clickFn: handleTripInstallmentPayment
							}}
						/>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default AdvancePaymentModal;
