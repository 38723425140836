import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../../store/slices/auth.slice";
import PageInfo from "../../../../components/layouts/PageInfo";
import CookieHelper from "../../../../utils/helpers/cookieHelper";
import { toTitleCase } from "../../../../utils/helpers/logicHelper";
import ProfileService from "../../../../utils/services/profile.service";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { AppKeys, Loading } from "../../../../utils/helpers/constants";
import { DomType } from "../../../../modules/enums";
import { TStoreState } from "../../../../modules/store";

type Props = {
	businessInfo: TBusiness
}
const BusinessProfile = ({businessInfo}: Props) => {
	const dispatch = useDispatch();
	const domType = useSelector((state: TStoreState) => state.auth.domType);
	const businessRef = (businessInfo && businessInfo.reference) ? businessInfo.reference : "";
	const businessPhone = (businessInfo && businessInfo.user && businessInfo.user.phone) ? businessInfo.user.phone : "";
	const businessEmail = (businessInfo && businessInfo.user && businessInfo.user.email) ? businessInfo.user.email : "";
	const businessType = domType === DomType.BUSINESS ? (businessInfo && businessInfo["is_merchant"] ? 'Merchant Profile' : 'Buisness Profile') : 'Customer Profile';
	const couponIndex = (businessInfo && businessInfo['merchant_configuration']) ? businessInfo['merchant_configuration'].findIndex(each => each.name === "coupon") : -1;
	const couponConfig = (businessInfo && businessInfo['merchant_configuration']) ? businessInfo['merchant_configuration'].find(each => each.name === "coupon") : "";
	const pricingModel = (businessInfo && businessInfo.config && typeof businessInfo.config === "object" && "config" in businessInfo.config && businessInfo.config.config) ? toTitleCase(businessInfo.config.config) : "";
	const communityCoupon = (couponConfig && couponConfig['configuration'] && couponConfig['configuration']['code']) ? couponConfig['configuration']['code'] : "";
	const commissionType = (businessInfo && businessInfo.config && typeof businessInfo.config === "object" && "commission_type" in businessInfo.config && businessInfo.config["commission_type"]) ? toTitleCase(businessInfo.config["commission_type"]) : "";
	const businessManagerLastName = (businessInfo && businessInfo["account_manager"] && businessInfo["account_manager"]["last_name"]) ? toTitleCase(businessInfo["account_manager"]["last_name"]) : "";
	const businessManagerFirstName = (businessInfo && businessInfo["account_manager"] && businessInfo["account_manager"]["first_name"]) ? toTitleCase(businessInfo["account_manager"]["first_name"]) : "";

	const [loading, setLoading] = useState(false);
	const [coupon, setCoupon] = useState("");
	const [businessWebsite, setBusinessWebsite] = useState("");
	const [businessName, setBusinessName] = useState("");
	const disabledState = !businessName || !businessWebsite || (businessInfo && businessInfo["is_merchant"] && !coupon);

	useMemo(() => {
		setCoupon(communityCoupon);
		setBusinessWebsite(businessInfo ? businessInfo.url : "");
		setBusinessName(businessInfo ? toTitleCase(businessInfo.name) : "")
	}, [communityCoupon, businessInfo])

	const editBusinessProfile = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		let payload = { name: businessName, url: businessWebsite, }  as { 
			name: string; 
			url: string; 
			merchant_configuration?: TMerchantConfiguration[] 
		};
		
		if (businessInfo && businessInfo["is_merchant"]) {
			let config = [...businessInfo['merchant_configuration']];
			if (couponIndex !== -1) {
				let couponData = { name: config[couponIndex]["name"], configuration: { key: config[couponIndex]["configuration"]["key"], code: coupon } }
				config[couponIndex] = couponData;
			}
			payload = { ...payload, merchant_configuration: config };
		}

		const { data: responseData } = await ProfileService.updateBusinessProfile(businessInfo.id, payload);
		if (!responseData || !responseData.status || responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
		else {
			CookieHelper.remove(AppKeys.USER_DOM);
			await getProfile(dispatch);
			toast.success(responseData.message);
		}
		setLoading(false);
	};

	return (
		<div className="row">
			<div className="col-12 mb-3">
				<div className="card">
					<div className="card-body">
						<PageInfo type="SMALL" name="Business Information" description="These are your business details and configuration." />
						<div className="row mt-4">
							<div className="col-sm-12 col-md-6 mb-4">
								<div className="form-floating">
									<input type="text" className="form-control text-primary form-lightblue"
										value={businessName}
										onChange={(e) => setBusinessName(e.target.value)} />
									<label>Business Name</label>
								</div>
							</div>
							<div className="col-sm-12 col-md-6 mb-4">
								<div className="form-floating">
									<input type="text" className="form-control text-primary form-lightblue" disabled
										value={businessRef} />
									<label>Business Reference</label>
								</div>
							</div>
							<div className="col-sm-12 col-md-6 mb-4">
								<div className="form-floating">
									<input type="text" className="form-control text-primary form-lightblue"
										value={businessWebsite}
										onChange={(e) => setBusinessWebsite(e.target.value)} />
									<label>Business Website</label>
								</div>
							</div>
							<div className="col-sm-12 col-md-6 mb-4">
								<div className="form-floating">
									<input type="text" className="form-control text-primary form-lightblue"
										value={coupon}
										disabled={domType !== DomType.BUSINESS || !communityCoupon || !businessInfo || (businessInfo && !businessInfo["is_merchant"])}
										onChange={event => setCoupon(event.target.value.toUpperCase())} />
									<label>Community Coupon</label>
								</div>
							</div>
							<div className="col-sm-12 col-md-6 mb-4">
								<div className="form-floating">
									<input
										type="text"
										className="form-control text-primary form-lightblue"
										value={businessType}
										disabled />
									<label>Business Type</label>
								</div>
							</div>
							<div className="col-sm-12 col-md-6 mb-4">
								<div className="form-floating">
									<input type="text" className="form-control text-primary form-lightblue" disabled
										value={commissionType} />
									<label>Payment Commission Type</label>
								</div>
							</div>
							<div className="col-sm-12 col-md-6 mb-4">
								<div className="form-floating">
									<input
										type="text"
										className="form-control text-primary form-lightblue" disabled
										value={pricingModel}
									/>
									<label>Pricing Model</label>
								</div>
							</div>
							<div className="col-sm-12 col-md-6 mb-4">
								<div className="form-floating">
									<input
										type="text"
										className="form-control text-primary form-lightblue" disabled
										value={`${businessManagerFirstName} ${businessManagerLastName}`}
									/>
									<label>Business Manager</label>
								</div>
							</div>
							<div className="col-sm-12 col-md-6 mb-4">
								<div className="form-floating">
									<input
										type="text"
										className="form-control text-primary form-lightblue" disabled
										value={businessEmail}
									/>
									<label>Business Email Address</label>
								</div>
							</div>
							<div className="col-sm-12 col-md-6 mb-4">
								<div className="form-floating">
									<input
										type="text"
										className="form-control text-primary form-lightblue" disabled
										value={businessPhone}
									/>
									<label>Business Phone Support</label>
								</div>
							</div>
						</div>

						<div className="d-flex justify-content-center justify-content-sm-end">
							<PrimaryButton
								btn={{
									text: 'Update Profile',
									btnClassName: 'px-5',
									disabledState: disabledState,
									loadState: loading,
									clickFn: editBusinessProfile
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BusinessProfile;
