import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import TokenHelper from "./utils/helpers/tokenHelper";
import { MenuType, SubMenuType } from "./modules/enums";

import Login from "./pages/authentication/Login";
import Register from "./pages/authentication/Register";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import ResetPassword from "./pages/authentication/ResetPassword";

import Home from "./pages/dashboard/home/Home";
import Trips from "./pages/dashboard/trips/Trips";
import BookTrip from "./pages/dashboard/trips/BookTrip";
import TripInformation from "./pages/dashboard/trips/TripInformation";
import PriceConfigurations from "./pages/dashboard/trips/PriceConfigurations";
import Wallet from "./pages/dashboard/wallet/Wallet";
import Settings from "./pages/dashboard/settings/Settings";

import Error403 from "./pages/misc/error/Error403";
import Error404 from "./pages/misc/error/Error404";



const App = () => {

	const AuthenticatedRoutes = () => {
		if (TokenHelper.authenticationValid()) return <Outlet />
		else return <Navigate to={`/${MenuType.AUTH}/${SubMenuType.LOGIN}`} replace />;
	}

	return (
		<Router>
			<Toaster containerStyle={{ zIndex: '99999999' }} />
			<Routes>
				<Route path={MenuType.AUTH}>
					<Route path={SubMenuType.LOGIN} element={<Login />} />
					<Route path={SubMenuType.ONBOARD} element={<Register />} />
					<Route path={SubMenuType.FORGOT_PASSWROD} element={<ForgotPassword />} />
					<Route path={SubMenuType.RESET_PASSWORD} element={<ResetPassword />} />
				</Route>
				<Route element={<AuthenticatedRoutes />}>
					{['/', MenuType.HOME, 'dashboard'].map(path => <Route path={path} key={path} element={<Home />} />)}
					<Route path={MenuType.TRIPS} element={<Outlet />}>
						<Route index element={<Trips />} />
						<Route path="create" element={<BookTrip />} />
						<Route path=":reference" element={<TripInformation />} />
					</Route>
					<Route path={MenuType.WALLET} element={<Wallet />} />
					<Route path={MenuType.SETTINGS} element={<Settings />} />
					<Route path={MenuType.PRICING_CONFIGURATION} element={<PriceConfigurations />} /> 
				</Route>
				<Route path="/403" element={<Error403 />} />
				<Route path="*" element={<Error404 />} />
			</Routes>
		</Router>
	);
}

export default App;