import { Fragment, ReactElement } from "react";
import { Dropdown } from "react-bootstrap";

type DropdownElementProps = {
  text: string | ReactElement,
  divider?: boolean,
  disabled?: boolean,
  clickFn: () => void, 
}

const DropdownElement = ({ text, divider, disabled, clickFn }: DropdownElementProps) => (
  <Fragment>
    <Dropdown.Item onClick={clickFn} disabled={disabled}>{text}</Dropdown.Item>
    {divider && <Dropdown.Divider/>}
  </Fragment>
)

export default DropdownElement;
