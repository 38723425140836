// export const MockBusinessConfig: TBusinessConfig = {
// 	currency: "NGN"
// };

export const MockCustomerInsight: TCustomerInsight = {
	is_transit: "0.00",
	completed_trips: "0.00",
	awaiting_driver: "0.00",
};

export const MockBusinessInsight: TBusinessInsight = {
	reference: "",
	completed_trips: "0.00",
	total_revenue: "0.00",
};

export const MockInsight: TInsight = {
	customer: MockCustomerInsight,
	business: [MockBusinessInsight]
};

export const MockMerchantConfiguration: TMerchantConfiguration = {
	name: "coupon",
	configuration: {
		code: "coupon",
		key: ""
	}
};

export const MockNotificationMatrix: TUserNotification = {
	key: false,
}

export const MockUser: TUser = {
	id: "",
	first_name: '',
	last_name: '',
	email: '',
	phone: '',
};

export const ResponseObj: TResponseObj = {
	id: '',
	created_at: null,
	updated_at: null,
};