import {AppKeys} from './constants';
import CookieHelper from "./cookieHelper";

const TokenHelper = {

  authenticationValid() {
    const TOKEN = CookieHelper.get(AppKeys.TOKEN);
    return !!TOKEN;
  },

  saveToken(token: string) {
    CookieHelper.set(AppKeys.TOKEN, token, AppKeys.COOKIE_EXPIRY_PERIOD);
    localStorage.setItem(AppKeys.TOKEN, token);
  },

  getToken() {
    return CookieHelper.get(AppKeys.TOKEN);
  },

}

export default TokenHelper;
