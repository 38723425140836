import GrayButton from "../buttons/GrayButton";
import SearchSVG from "../../assets/images/svgs/search.svg";
import filterSVG from "../../assets/images/svgs/filter.svg";

const TableFilter = ({ searchFn }: { searchFn: (value: string) => void }) => (
  <div className="row mt-2 mb-5">
    <div className="col-12">
      <div className="d-flex justify-content-between flex-wrap">
        <div className="d-flex flex-wrap gap-3">
          <div className="position-relative">
            <input type="text" className="form-control form-control-sm filter-control"
              placeholder="Search here..." onChange={event => searchFn(event.target.value.toUpperCase())} />
            <img src={SearchSVG} className="search-icon" alt="SEARCH_ICON" />
          </div>
          <GrayButton btn={{
            text: 'Filter',
            btnClassName: 'btn-sm d-none',
            image: {
              imgSrc: filterSVG,
              height: '18',
              width: '18',
            },
            clickFn: () => console.log("")
          }} />
        </div>
        <GrayButton btn={{
          text: 'Select dates',
          btnClassName: 'btn-sm d-none',
          clickFn: () => console.log("")
        }} />
      </div>
    </div>
  </div>
)

export default TableFilter
