import { AxiosResponse } from 'axios';
import {dodgeInstance as Axios} from './axios.service';

const AuthService = {
  forgotPassword(payload: { email: string, callbackUrl: string }): Promise<AxiosResponse<TApiResponse<TProfile>, any>>  {
    return Axios({
      method: 'POST',
      url: `locale-service/auth/forgot-password`,
      data: payload,
      headers: {platform: 'CUSTOM_USER'}
    });
  },

  resetPassword(payload: { password: string, confirm_password: string, token: string | null }): Promise<AxiosResponse<TApiResponse<TProfile>, any>>  {
    return Axios({
      method: 'POST',
      url: `locale-service/auth/reset-password`,
      data: payload,
      headers: {platform: 'CUSTOM_USER'}
    });
  },

  logout(): Promise<AxiosResponse<TApiResponse<TProfile>, any>> {
    return Axios({
      method: 'GET',
      url: `locale-service/auth/logout`,
    });
  },

};

export default AuthService;
