import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Line } from "rc-progress";
import toast from "react-hot-toast";

import UserSVG from "../../assets/images/svgs/user.svg";
import MerchantSVG from "../../assets/images/svgs/shop.svg";
import CookieHelper from "../../utils/helpers/cookieHelper";

import AuthLayout from "../../components/layouts/AuthLayout";
import BackSVG from "../../assets/images/svgs/arrow-left.svg";
import { AppKeys, Loading } from "../../utils/helpers/constants";
import PasswordInput from "../../components/forms/PasswordInput";
import ProfileService from "../../utils/services/profile.service";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { emailValidator } from "../../utils/services/validator.service";
import { useRegisterProfileMutation } from "../../store/slices/api.slice";
import { getProfile, onAuthentication } from "../../store/slices/auth.slice";
import { formatWebsite, queryErrorHandler } from "../../utils/helpers/logicHelper";
import { InputValidator, SubMenuType, MenuType, AccountType } from "../../modules/enums";

const Register = () => {
  const dispatch = useDispatch(), navigate = useNavigate(), location = useLocation();
  const [step, setStep] = useState((location.state && location.state === "CREATE_PROFILE") ? 3 : 1);
  const [terms, setTerms] = useState(false);
  const [website, setWebsite] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [loadState, setLoadState] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [accountType, setAccountType] = useState<string | null>(null);
  const [email, setEmail] = useState({ value: "", errorState: false });
  const [password, setPassword] = useState<{ errorState: boolean, value: string | undefined }>({ value: undefined, errorState: false });
  const [registerProfile, { isLoading: loading }] = useRegisterProfileMutation();

  const isInvalid = !firstName || !lastName || !terms || !email.value || email.errorState
    || emailValidator(email.value).validState === InputValidator.INVALID || !password.value || password.errorState;

  const isBusinessInvalid = !businessName || !website;

  const goBack = () => {
    if (location.state && location.state === "CREATE_PROFILE") navigate(-1);
    else setStep(step - 1);
  }

  const lineProgressStatus = () => {
    if (step === 3) return 100;
    else if (step === 2) return 60;
    else return 30;
  };

  const handleFieldValidation = (data: string, type: string) => {
    if (type === "EMAIL") {
      const validatedEmail = emailValidator(data);
      setEmail(prev => ({ ...prev, value: validatedEmail.validEmail }));
      const isEmailValid = validatedEmail.validState !== InputValidator.VALID;
      setEmail(prev => ({ ...prev, errorState: isEmailValid }));
    }
  };

  const authenticateUser = async () => {
    const payload = {
      first_name: firstName, last_name: lastName,
      email: email.value, password: password.value
    };

    let result = await registerProfile(payload);
    if (result.data && result.data.status === Loading.SUCCESS) {
      onAuthentication(dispatch, result.data.data.access);
      toast.success("Your profile has been created successfully");
      setStep(step + 1);
    } else {
      const errorMessage = queryErrorHandler("register", result);
      toast.error(errorMessage);
    }
  };

  const handleOnboarding = async () => {
    setLoadState(true);
    if (step === 2) {
      if (accountType === "MERCHANT") setStep(step + 1);
      else if (accountType === "CUSTOMER") navigate("/");
    } else {
      let payload = {
        name: businessName, url: formatWebsite(website), is_merchant: true
      };
      const { data: responseData } = await ProfileService.createBusinessProfile(payload);
      if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
      else {
        toast.success(responseData.message);
        await getProfile(dispatch);
        CookieHelper.set(AppKeys.USER_DOM, responseData.data, AppKeys.COOKIE_EXPIRY_PERIOD);
        if (location.state && location.state === "CREATE_PROFILE") navigate(-1);
        else navigate('/');
      }
    }
    setLoadState(false);
  }

  return (
    <AuthLayout step={step} type={SubMenuType.ONBOARD}>
      <div className="justify-start items-start">
        {step === 3 && (
          <div className="d-flex flex-row mb-4 cursor-pointer" onClick={goBack}>
            <img src={BackSVG} alt="GO_BACK" />
            <p>Back</p>
          </div>
        )}

        <h4 className="text-lg-start text-center">
          {step === 1 ? "Create Account" : step === 2 ? "Select type of Account" : "Enter Business Name"}
        </h4>
        <p className="text-office-brown-700 mb-3 text-lg-start text-center">
          {step === 3 ? "Provide your Business name and website URL" : "Follow the step to complete your sign up process"}
        </p>
        {(!location.state) &&
          <Fragment>
            <p className="text-primary">{step}<span className="fw-bold">/3</span></p>
            <Line percent={lineProgressStatus()} strokeColor="#2C3891" trailColor="#E5E5E5" className="line w-100 pe-5" />
          </Fragment>
        }
      </div>

      <Fragment>
        {step === 1 && (
          <Fragment>
            <div className="form-floating my-4">
              <input
                type="text"
                className="form-control form-lightblue"
                value={firstName}
                placeholder="first_name"
                onChange={(e) => setFirstName(e.target.value)}
              />
              <label>First Name</label>
            </div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control form-lightblue"
                value={lastName}
                placeholder="last_name"
                onChange={(e) => setLastName(e.target.value)}
              />
              <label>Last Name</label>
            </div>
            <div className="form-floating mb-4">
              <input
                type="text" placeholder="email address" value={email.value}
                className={`form-control form-lightblue ${email.errorState && "is-invalid"}`}
                onChange={(e) => handleFieldValidation(e.target.value, "EMAIL")} />
              <label>Email Address</label>
            </div>
            <PasswordInput name="password" value={password.value} isValidatorRequired
              setErrorState={value => setPassword(prev => ({ ...prev, errorState: value }))}
              setInput={value => setPassword({ errorState: password.errorState, value })} />
            <div className="ms-1 d-inline-flex column-gap-3">
              <input className="form-check-input" type="checkbox" id="terms" defaultChecked={terms} onChange={(e) => setTerms(e.target.checked)}
              />
              <small>
                By activating your account, you agree to our{" "}
                <a className="text-primary" href="https://www.truq.it/legal/terms-conditions" target="_blank" rel="noreferrer">
                  Terms and Conditions.
                </a>
              </small>
            </div>
            <PrimaryButton 
              btn={{
                text: 'Save & Continue',
                loadState: loading,
                disabledState: isInvalid,
                btnClassName: 'btn-block my-4 fw-bold',
                clickFn: authenticateUser
              }}/>

            <div className="d-flex justify-content-center">
              <div className="mt-2 p-3 bg-primary-light">
                <p className="text-office-brown-800 text-center">
                  Already have an account?
                  <NavLink to={`/${MenuType.AUTH}/${SubMenuType.LOGIN}`} className="text-primary-emphasis ms-2 fw-bold">
                    Sign in
                  </NavLink>
                </p>
              </div>
            </div>
          </Fragment>
        )}

        {step === 2 && (
          <Fragment>
            <div className="row mt-5">
              <div className="col-6 mb-2">
                <label className="cursor-pointer card border-primary h-100" htmlFor="customer">
                  <div className="card-body">
                    <div className="row justify-content-end">
                      <div className="col-auto">
                        <input type="radio" id="customer" name="account_type" value={AccountType.CUSTOMER} className="custom-radio"
                          checked={accountType === AccountType.CUSTOMER}
                          onChange={event => setAccountType(event.target.value)} />
                      </div>
                    </div>
                    <img src={UserSVG} alt="USER_ICON" className="mb-2" width={18} height={18} />
                    <h6 className="mb-2">Customer</h6>
                    <small className="text-office-brown-700">
                      Sign up as a customer to book your move hassle-free.
                    </small>
                  </div>
                </label>
              </div>
              <div className="col-6 mb-2">
                <label className="cursor-pointer card border-primary cursor-pointer h-100" htmlFor="merchant">
                  <div className="card-body">
                    <div className="row justify-content-end">
                      <div className="col-auto">
                        <input type="radio" id="merchant" name="account_type" value={AccountType.MERCHANT} className="custom-radio"
                          checked={accountType === AccountType.MERCHANT}
                          onChange={event => setAccountType(event.target.value)} />
                      </div>
                    </div>
                    <img src={MerchantSVG} alt="USER_ICON" className="mb-2" width={18} height={18} />
                    <h6 className="mb-2">Merchant</h6>
                    <small className="text-office-brown-700">
                      Sign up as a merchant to offer top-notch logistics services to your customers.
                    </small>
                  </div>
                </label>
              </div>
            </div>
            <PrimaryButton btn={{
              text: 'Save & Continue',
              loadState: loading,
              disabledState: !accountType,
              btnClassName: 'my-5 btn-block fw-bold',
              clickFn: handleOnboarding
            }} />
          </Fragment>
        )}

        {step === 3 && (
          <Fragment>
            <div className="form-floating mt-5 mb-4">
              <input type="text" className="form-control form-lightblue" value={businessName} placeholder="business_name"
                onChange={(e) => setBusinessName(e.target.value)} />
              <label>Enter Business Name</label>
            </div>
            <div className="form-floating mb-4">
              <input type="url" className="form-control form-lightblue" value={website} placeholder="website"
                onChange={(e) => setWebsite(e.target.value)} />
              <label>Enter Website URL</label>
            </div>
            <PrimaryButton 
              btn={{
                text: 'Create Account',
                loadState: loadState,
                disabledState: isBusinessInvalid,
                btnClassName: 'btn-block my-5',
                clickFn: handleOnboarding
              }}/>
          </Fragment>
        )}
      </Fragment>
    </AuthLayout>
  );
};

export default Register;