import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Line } from "rc-progress";
import moment from "moment";
import MapHook from "../hooks/MapHook";
import CloseButton from "../buttons/CloseButton";

type Props = {
	onchange: () => void,
	trip: TTrip,
	tripTimeline?: { [x: string]: string; }
};

const TripJourneyModal = ({ onchange, trip, tripTimeline }: Props) => {
	const [show, setShow] = useState(true);

	const handleClose = () => {
		setShow(false);
		onchange();
	};

	const lineProgressStatus = () => {
		if (trip && trip["is_completed"]) return 100;
		else if (trip && trip["is_transit"] && !trip["is_completed"]) return 65;
		else if (trip && trip["is_paid"] && !trip["is_transit"] && !trip["is_completed"])
			return 35;
		else return 20;
	};

	return (
		<Modal show={show} backdrop="static" size="lg" keyboard={false} scrollable centered>
			<Modal.Body>
				<div className="row">
					<div className="col-12"><CloseButton clickFn={handleClose} /></div>
				</div>
				<div className="row d-flex row-gap-5">
					<div className="col-sm-12 col-md-12 col-lg-6 mb-3">
						<div className="map">
							<MapHook source={trip?.source} destination={trip?.destination} />
						</div>
					</div>
					<div className="col-sm-12 col-md-12 col-lg-6 mb-3">
						<div className="d-flex gap-lg-2 column-gap-3 justify-content-between">
							<div>
								<p className="text-13px text-black-2">Delivery Start Date</p>
								<p className="text-13px fw-bold">
									{tripTimeline && tripTimeline["INITIATED"] ? moment(tripTimeline["INITIATED"]).format("llll") : "-"}
								</p>
							</div>
							<Line
								percent={lineProgressStatus()}
								strokeWidth={4}
								trailWidth={4}
								strokeColor="#FFAB00"
								trailColor="#D3D3D3"
								className="line mt-2 me-2"
							/>
							<div>
								<p className="text-13px text-black-2">
									{tripTimeline && tripTimeline["COMPLETED"] ? "Delivery End Date" : "Expected Delivery Date"}
								</p>
								<p className="text-13px fw-bold">
									{tripTimeline ? tripTimeline["COMPLETED"] ? moment(tripTimeline["COMPLETED"]).format("llll")
										: moment(trip?.scheduled_date).format("llll") : "--"}
								</p>
							</div>
						</div>
						<div className="border-bottom my-4" />
						<h6>Latest Updates</h6>

						<div className="row mt-4">
							{(trip && trip.user_journey) && trip.user_journey.map((item, index) => (
								<div key={index} className="col-6 mb-30px">
									<p className="text-13px text-black-2">{moment(item.time).format("llll")}</p>
									<p className="text-13px fw-bold">{item.message}</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default TripJourneyModal;