import { Fragment, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";

import ArrowInSVG from "../../../assets/images/svgs/arrow-in-white.svg";
// import ArrowOutSVG from "../../../assets/images/svgs/arrow-out-primary.svg";
import { MenuType } from "../../../modules/enums";
import PaginationTable from "../../../components/dataTables/PaginationTable";
import PageInfo from "../../../components/layouts/PageInfo";
import WalletCard from "../../../components/cards/WalletCard";
import PageLayout from "../../../components/layouts/PageLayout";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import WalletTopUpModal from "../../../components/modals/WalletTopUpModal";
import { useGetWalletEntriesQuery, useGetWalletQuery } from "../../../store/slices/api.slice";
import { WalletColumns } from "../../../components/dataTables/atom/WalletColumns";


const Wallet = () => {
  const [size] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [topUpRender, setTopUpRender] = useState(false);

  const { data: wallet, isLoading: isLoadingWallet } = useGetWalletQuery("");
  const { data: entries, isLoading: isLoadingEntries } = useGetWalletEntriesQuery(`?size=${size}&page=${pageIndex}`);
  

  const columns: ColumnDef<TWallet, string>[] = useMemo(() => [...WalletColumns], []);
  const loadState = isLoadingWallet || isLoadingEntries;
  const changeTopUpRenderStatus = () => setTopUpRender(false);

  const walletTopUpModal = topUpRender ? <WalletTopUpModal onchange={changeTopUpRenderStatus} wallet={wallet.data}/> : null;

  return (
    <PageLayout menu={MenuType.WALLET} loading={loadState}>
      <Fragment>{walletTopUpModal}</Fragment>
  
      <div className="row">
        <div className="col-12 mb-4">
          <div className="d-flex justify-content-between flex-wrap">
            <PageInfo name="Wallet" description="View your wallet balance and your transactions summary" />
            <div className="d-flex gap-3">
              {/*<PrimaryButton  
                btn={{
                  text: 'Withdraw Money',
                  btnClassName: 'd-none',
                  outline: true,
                  image: {
                    imgSrc: ArrowOutSVG,
                    imgAlt: "WITHDRAW"
                  }
                }}
              />*/}
              <PrimaryButton
                btn={{
                  text: 'Top up Wallet',
                  image: {
                    width: "24",
                    height: "24",
                    imgSrc: ArrowInSVG,
                    imgAlt: "TOP_UP",
                    imgClass: "me-1"
                  },
                  clickFn: () => setTopUpRender(true)
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-sm-8 col-md-7 col-lg-8 col-xl-6 col-xxl-5 mb-4">
          <WalletCard wallet={wallet?.data} />
        </div>
      </div>

      <div className="row mt-4">
        <div className="card">
          <div className="card-header border-bottom-0 bg-white">
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="d-flex flex-wrap gap-3">
                    <h5>Wallet Transactions</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <PaginationTable data={entries ? entries.data : []} size={size} columns={columns}
              count={entries ? entries.count : 0} pageIndex={pageIndex}
              setPageIndex={index => setPageIndex(index)} />
          </div>
        </div>
      </div>

    </PageLayout>
  )
}

export default Wallet;
