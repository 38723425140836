import { ReactElement } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import InnerLoader from "../loaders/InnerLoader";
import { MenuType, SubMenuType } from "../../modules/enums";
import { useSelector } from "react-redux";
import { TStoreState } from "../../modules/store";

type Props = {
	menu: MenuType,
	subMenu?: SubMenuType,
	loading: boolean,
	children: ReactElement | ReactElement[]
}
const PageLayout = ({ menu, subMenu, loading, children }: Props) => {
	const apploader = useSelector((state: TStoreState) => state.config.appLoader);

	return (
		<main>
			<SideMenu menu={menu} subMenu={subMenu} />
			<Header />
			<section className="main-content side-content">
				<div className="container-fluid">
					<div className="inner-body">
						{(apploader || loading) ? <InnerLoader /> : children}
					</div>
				</div>
			</section>
		</main>
	)
}

export default PageLayout;
