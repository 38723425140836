type Props = {
  element: TFixedConfig[],
  currency: string,
}; 

const FixedConfig = ({ element, currency }: Props) => (
  <div className="row">
    {element.map((data, dataIndex) =>
      <div key={dataIndex} className="col-sm-6 col-lg-6 col-xl-3 mb-4">
        <div className="form-floating">
          <input type="text" placeholder="Distance" readOnly={true}
            className="form-control bg-light" value={(data && data["distance"]) ? data["distance"] : 0} />
          <label>Distance</label>
        </div>
        <div className="input-group">
          <span className="input-group-text">{currency}</span>
          <div className="form-floating">
            <input type="text" placeholder="Price" readOnly={true}
              className="form-control bg-light" value={(data && data["price"]) ? data["distance"] : 0} />
            <label>Cost</label>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default FixedConfig;