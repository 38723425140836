import { Dispatch, Store, createSlice } from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';
import { NavigateFunction } from 'react-router-dom';


import { setAppLoader, setInsight } from "./config.slice";
import { TDomState } from '../../modules/component';
import { AppKeys } from "../../utils/helpers/constants";
import TokenHelper from "../../utils/helpers/tokenHelper";
import AuthService from "../../utils/services/auth.service";
import CookieHelper from "../../utils/helpers/cookieHelper";
import { getBaseUrl } from '../../utils/services/axios.service';
import ProfileService from "../../utils/services/profile.service";
import { DomType, MenuType, SubMenuType } from '../../modules/enums';
import { MockOrgainsationProfile, MockUserProfile } from '../../utils/mocks/profile';
import { TAuthState } from '../../modules/store';

const initialState = {
  token: null,
  domType: DomType.INDIVIDUAL,
  domObject: '',
  businesses: [],
  userProfile: MockUserProfile,
  organisation: MockOrgainsationProfile
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state: TAuthState, { payload: access }: { payload: string }): void => {
      state.token = access
    },
    setProfile: (state: TAuthState, { payload: profile }: { payload: TProfile }) => {
      state.userProfile = profile
    },
    setOrganisation: (state: TAuthState, { payload: data }: { payload: TOrgainsation }) => {
      state.organisation = data
    },
    setBusinesses: (state: TAuthState, { payload: data }) => {
      state.businesses = data
    },
    setDomState: (state: TAuthState, { payload: data }: { payload: TDomState }) => {
      state.domType = data.type
      state.domObject = data.type === DomType.INDIVIDUAL ? data.id : data.business
    },
  }
})

export const onAuthentication = (dispatch: Dispatch, access: string) => {
  TokenHelper.saveToken(access);
  const userAuthenticated = TokenHelper.authenticationValid();
  if (userAuthenticated) getProfile(dispatch).catch(err => console.log(err));
}

export const onRTKPersist = async (store: Store) => {
  const userAuthenticated = TokenHelper.authenticationValid();
  if (userAuthenticated) await getProfile(store.dispatch).catch(err => console.log(err));
}

export const getProfile = async (dispatch: Dispatch) => {
  try {
    setAppLoader(true);
    const getResources = [
      ProfileService.getProfile(),
      ProfileService.getOrganisation(getBaseUrl()['organisation']),
      ProfileService.getBusinesses(),
      ProfileService.getOverview()
    ];

    await Promise.all(getResources).then(async response => {
      let token: string = TokenHelper.getToken();
      let profile: TProfile = response[0]['data']['data'] as unknown as TProfile,
        businesses: TBusiness[] = response[2]['data']['data'] as unknown as TBusiness[],
        organisation: TOrgainsation = response[1]['data']['data'] as unknown as TOrgainsation,
        overview = response[3]['data']['data'],
        domState = setDomSwitcher(profile, businesses);

      dispatch(setAuth(token));
      dispatch(setProfile(profile as TProfile));
      dispatch(setInsight(overview));
      dispatch(setDomState(domState));
      dispatch(setOrganisation(organisation));
      if (businesses && businesses.length > 0) dispatch(setBusinesses(businesses));
      setAppLoader(false);
    });
  } catch (error: any) {
    setAppLoader(false);
    if (error && error.status === 401 as HttpStatusCode) {
      //toDo -- on removeAuth, {{params: navigate}}
      CookieHelper.remove(AppKeys.TOKEN);
      window.location.href = `/${MenuType.AUTH}/${SubMenuType.LOGIN}`;
    } else console.error(error)
  }
}

export const setDomSwitcher = (profile: TProfile, businesses: TBusiness[]) => {
  let domState, cookieState = CookieHelper.get(AppKeys.USER_DOM);

  if (cookieState) return cookieState;
  else {
    if (profile['is_business'] && businesses.length > 0) {
      domState = { type: DomType.BUSINESS, id: businesses[0].id, business: businesses[0] }
    } else domState = { type: DomType.INDIVIDUAL, id: profile.id }
    CookieHelper.set(AppKeys.USER_DOM, domState, AppKeys.COOKIE_EXPIRY_PERIOD);
    return domState;
  }
}

export const switchDomState = (dispatch: Dispatch, domState: TDomState) => {
  CookieHelper.set(AppKeys.USER_DOM, domState, AppKeys.COOKIE_EXPIRY_PERIOD);
  dispatch(setDomState(domState));
}

export const removeAuthentication = async (navigate: NavigateFunction) => {
  await AuthService.logout();
  CookieHelper.remove(AppKeys.USER_DOM);
  CookieHelper.remove(AppKeys.TOKEN);
  localStorage.clear();
  navigate(`/${MenuType.AUTH}/${SubMenuType.LOGIN}`);
}

export const { setAuth, setProfile, setOrganisation, setBusinesses, setDomState } = authSlice.actions

export default authSlice.reducer;