import { Fragment, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ColumnHelper, createColumnHelper, ColumnDef, CellContext } from "@tanstack/react-table";

import TripOverview from "./atom/TripOverview";
import { TStoreState } from "../../../modules/store";
import { MockTrip } from "../../../utils/mocks/trips";
import PaginationTable from "../../../components/dataTables/PaginationTable";
import PageInfo from "../../../components/layouts/PageInfo";
import PageLayout from "../../../components/layouts/PageLayout";
import TableFilter from "../../../components/dataTables/TableFilter";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { getUserJourneyDates } from "../../../utils/helpers/logicHelper";
import DropdownElement from "../../../components/dropdown/DropdownElement";
import TripJourneyModal from "../../../components/modals/TripJourneyModal";
import MoreVerticalSVG from "../../../assets/images/svgs/more-vertical.svg";
import ActionPromptModal from "../../../components/modals/ActionPromptModal";
import { ActionPromptEnum, DomType, MenuType } from "../../../modules/enums";
import DropdownComponent from "../../../components/dropdown/DropdownComponent";
import { TripsColumns } from "../../../components/dataTables/atom/TripsColumns";
import TripInitiationModal from "../../../components/modals/TripInitiationModal";
import NotesWhiteOutlineSVG from "../../../assets/images/svgs/notes-outline-white.svg";
import { useGetTripMutation, useGetTripsQuery } from "../../../store/slices/api.slice";


const Trips = () => {
  const navigate = useNavigate();
  const domType = useSelector((state: TStoreState) => state.auth.domType);
  const domObject: TBusiness = useSelector((state: TStoreState) => state.auth.domObject) as TBusiness;
  const businessQuery = domType === DomType.BUSINESS ? `&business=${domObject["reference"]}` : ""

  const [size] = useState(10);
  const [getTrip] = useGetTripMutation();
  const [trip, setTrip] = useState<TTrip>(MockTrip);
  const [pageIndex, setPageIndex] = useState(1);
  const [timeLine, setTimeLine] = useState({});
  const [filteredTrips, setFilteredTrips] = useState<TTrip[] | null>(null);
  const [filteredTripsCount, setFilteredTripsCount] = useState<number | null>(null);
  const [trackTripRender, setTrackTripRender] = useState(false);
  const [cancelTripRender, setCancelTripRender] = useState(false);
  const [tripInitiationRender, setTripInitiationRender] = useState(false);
  const { data: trips, isLoading } = useGetTripsQuery(`?size=${size}&page=${pageIndex}${businessQuery}`);

  const column: ColumnHelper<TTrip> = createColumnHelper<TTrip>();

  const actionColumn: ColumnDef<TTrip, string> = column.accessor('id', {
    header: () => <span>Actions</span>,
    enableSorting: false,
    cell: (info: CellContext<TTrip, string>) => {
      let data: TTrip = info.row.original
      return (
        <DropdownComponent imgSrc={MoreVerticalSVG} variant="link" imgAlt="More" className="tableMoreDropdown"
          drop="start">
          <DropdownElement clickFn={() => navigate(`/${MenuType.TRIPS}/${data.reference}`)}
            text="View Trip Information" />
          {(!data['is_paid'] && !data["business_tripset"]) &&
            <DropdownElement clickFn={() => handleCancelTrip(data)} text="Cancel trip" />
          }
          <DropdownElement clickFn={() => handleTrackTrip(data)} text="Track Trip" />
        </DropdownComponent>
      )
    }
  })
  const columns: ColumnDef<TTrip, string>[] = useMemo(() => [...TripsColumns, actionColumn], []);

  const handleCancelTrip = (trip: TTrip) => {
    setTrip(trip);
    setCancelTripRender(true);
  }

  const handleTrackTrip = (trip: TTrip) => {
    setTrip(trip)
    let timelines = getUserJourneyDates(trip);
    setTimeLine(timelines);
    setTrackTripRender(true);
  }

  const handleTripSearch = async (inputValue: string) => {
    if (inputValue.length < 1) {
      setFilteredTrips(trips.data);
      setFilteredTripsCount(trips.count);
    } else {
      const filterSearchObject = trips.data.filter((data: TTrip) => JSON.stringify(data).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
      if (inputValue.length > 14) await searchTrip(inputValue);
      else {
        setFilteredTrips(filterSearchObject);
        setFilteredTripsCount(filterSearchObject.length);
      }
    }
  }

  const searchTrip = async (reference: string) => {
    let response = await getTrip({ reference })
    if (response && response.data && response.data.data) {
      setFilteredTrips(response.data.data);
      setFilteredTripsCount(response.data.data.length);
    } else {
      setFilteredTrips([]);
      setFilteredTripsCount(0);
    }
  }

  const changeCancelRenderStatus = () => setCancelTripRender(false);
  const changeTripInitiationRenderStatus = () => setTripInitiationRender(false);

  const tripInitiationRenderModal = tripInitiationRender ?
    <TripInitiationModal onchange={changeTripInitiationRenderStatus} business={domObject} /> : null;

  const cancelTripModal = cancelTripRender ?
    <ActionPromptModal onchange={changeCancelRenderStatus} type={ActionPromptEnum.CANCEL_TRIP}
      props={trip?.id} /> : null;

  const tripJourneyModal = trackTripRender ?
    <TripJourneyModal onchange={() => setTrackTripRender(false)} tripTimeline={timeLine} trip={trip} /> : null;


  return (
    <PageLayout menu={MenuType.TRIPS} loading={isLoading}>
      <Fragment>
        {cancelTripModal}
        {tripJourneyModal}
        {tripInitiationRenderModal}
      </Fragment>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="d-flex justify-content-between flex-wrap gap-2">
            <PageInfo name="Trips" description="List of all trips" />
            <div className="d-flex gap-3">
              {(domObject && domObject["is_merchant"]) &&
                <PrimaryButton btn={{
                  text: 'Initiate Customer Trip',
                  outline: true,
                  clickFn: () => setTripInitiationRender(true)
                }} />
              }
              <PrimaryButton btn={{
                text: 'Get Trip Quote',
                image: {
                  imgSrc: NotesWhiteOutlineSVG,
                },
                clickFn: () => navigate(`/${MenuType.TRIPS}/create`)
              }} />
            </div>
          </div>
        </div>
      </div>

      <TripOverview domObject={domObject} />

      <div className="card">
        <div className="card-body">
          <TableFilter searchFn={handleTripSearch} />
          <PaginationTable data={filteredTrips ? filteredTrips : (trips && trips.data) ? trips.data : []} size={size}
            count={filteredTripsCount ? filteredTripsCount : (trips && trips.count) ? trips.count : 0}
            columns={columns} pageIndex={pageIndex} setPageIndex={(index: number) => setPageIndex(index)} />
        </div>
      </div>
    </PageLayout>
  )
}

export default Trips;