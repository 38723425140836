import { formatAmountToString } from "../../../../utils/helpers/logicHelper";


type Props = {
  element: TVariableConfig[],
  currency: string,
};

const VariableConfig = ({ element, currency }: Props) => (
  <div className="row">
    <div className="col-sm-6 col-lg-6 col-xl-3">
      <div className="input-group">
        <span className="input-group-text">{currency}</span>
        <div className="form-floating">
          <input type="text" placeholder="base_fare"
            className="form-control bg-light" readOnly={true}
            value={(element && element[0] && element[0]["base_fare"]) ? formatAmountToString(element[0]["base_fare"]) : 0} />
          <label>Base Fare</label>
        </div>
      </div>
    </div>
    <div className="col-sm-6 col-lg-6 col-xl-3">
      <div className="input-group">
        <span className="input-group-text">{currency}</span>
        <div className="form-floating">
          <input type="text" placeholder="cost_per_kilometer"
            className="form-control bg-light" readOnly={true}
            value={(element && element[0] && element[0]["cost_per_kilometer"]) ? formatAmountToString(element[0]["cost_per_kilometer"]) : 0} />
          <label>Cost Per Kilometer</label>
        </div>
      </div>
    </div>
  </div>
);

export default VariableConfig;