import { TButtons } from "../../modules/component";

const DangerButton = ({ btn }: { btn: TButtons }) => (
  <button onClick={btn.clickFn} disabled={btn.disabledState}
    className={`btn ${btn.outline ? "btn-outline-danger" : btn.flat ? "btn-danger-gray" : "btn-danger"} ${btn.btnClassName}`}>
    {btn.image && <img src={btn.image.imgSrc} alt={btn.image.imgAlt ?? 'ICON'}
      className={`${btn.image.imgClass ? btn.image.imgClass : 'me-1'}`} width={btn.image.width ?? 24} height={btn.image.height ?? 24} />
    }
    {btn.text}
    {btn.loadState && <i className={`zmdi zmdi-spinner zmdi-hc-spin ms-2 ${(btn.outline || btn.flat) ? "text-danger-900" : "text-white"}`} />}
  </button>
);

export default DangerButton;