import {configureStore} from "@reduxjs/toolkit"
import {setupListeners} from "@reduxjs/toolkit/query"
import {createLogger} from 'redux-logger'

import {dodgeQuery} from "./slices/api.slice";
import authReducer from './slices/auth.slice';
import configReducer from './slices/config.slice';


const loggerMiddleware = () => {
  if (process.env.NODE_ENV === 'development') return [createLogger({collapsed: true})];
  return [];
};

// ToDo -- Check out Redux Saga, and comparison on RTK
export const store = configureStore({
  reducer: {
    [dodgeQuery.reducerPath]: dodgeQuery.reducer,
    auth: authReducer,
    config: configReducer,
  },
  middleware: initMiddleware => initMiddleware().concat(dodgeQuery.middleware).concat(loggerMiddleware()),
  devTools: false
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch);
