import GoogleMapReact from "google-map-react";
import MapSvg from "../../assets/images/svgs/map.svg";

type Props = {
  source: TTripLocation,
  destination: TTripLocation
};

const MapHook = ({ source, destination }: Props) => {

  const defaultProps = { center: { lat: 0, lng: 0 }, zoom: 12 };
  const createMapOptions = () => {
    return {
      panControl: false,
      streetViewControl: true
    }
  }

  const handleApiLoaded = (map: google.maps.Map, maps: typeof google.maps,) => {
    let start = { lat: source.latitude, lng: source.longitude };
    let end = { lat: destination.latitude, lng: destination.longitude };
    let waypoints = [];
    if (destination && destination.stops && destination.stops.stopovers) {

      for (let stopover of destination.stops.stopovers) {
        let location = new maps.LatLng(stopover.address.latitude, stopover.address.longitude);
        let point = { location, stopover: true };
        waypoints.push(point);
      }
    }

    let routeMode: google.maps.DirectionsRequest = { travelMode: google.maps.TravelMode.DRIVING, origin: start, destination: end, waypoints };
    let directionsService = new maps.DirectionsService();
    let directionsRenderer = new maps.DirectionsRenderer({ map: map, suppressMarkers: true });
    directionsRenderer.setMap(map);
    directionsService.route(routeMode, (DirectionsResult, DirectionsStatus) => {

      if (DirectionsStatus === maps.DirectionsStatus.OK && DirectionsResult) {
        let route = DirectionsResult.routes[0].legs[0];
        createMarker(maps, map, route.start_location, 'Pick Up');
        createMarker(maps, map, route.end_location, 'Drop off');
        /*if (destination && destination.stops && destination.stops.stopovers){
          for (let [index, stopover] of destination.stops.stopovers.entries()) {
            let text = `Drop off ${index + 1}`;
            createMarker(maps, map, {lat: stopover.address.latitude, lng: stopover.address.longitude}, text);
          }
        }*/
        directionsRenderer.setDirections(DirectionsResult);
      }
    });
  }

  const createMarker = (maps: typeof google.maps, map: google.maps.Map, position: google.maps.LatLng | google.maps.LatLngLiteral, text: string) => {
    let label = { text, position: 'absolute', top: '30px', textAlign: 'center', fontSize: "16px", fontWeight: "600" };
    return new maps.Marker({ position: position, map: map, icon: MapSvg, label });
  }

  return (
    <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyDasD4rndKZy6fYD3SEQ2AIgAjyHxk-g6A" }}
      yesIWantToUseGoogleMapApiInternals
      options={createMapOptions}
      defaultCenter={defaultProps.center}
      layerTypes={['TrafficLayer', 'TransitLayer']}
      defaultZoom={defaultProps.zoom}
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
    </GoogleMapReact>
  );
}

export default MapHook;