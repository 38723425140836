import {useSelector} from "react-redux";

import BusSVG from "../../../../assets/images/svgs/bus-primary.svg";
import UserCircleSVG from "../../../../assets/images/svgs/user-circle.svg";
import MapSolidSVG from "../../../../assets/images/svgs/map-solid-primary.svg";
import WalletSolidSVG from "../../../../assets/images/svgs/wallet-solid-primary.svg";
import { TStoreState } from "../../../../modules/store";
import InsightCard from "../../../../components/cards/InsightCard";
import {formatAmountToString, formatNumber} from "../../../../utils/helpers/logicHelper";

const TripOverview = ({ domObject }: { domObject : TBusiness}) => {
  const insight = useSelector((state: TStoreState) => (state.config.insight));
  const businessInsight = (domObject && domObject["reference"] && insight && insight["business"]) ? insight.business.find(item => item["reference"] === domObject["reference"]) : "";

  return (
    <div className="row mt-4">
      {(domObject && Object.entries(domObject).length > 0 && typeof domObject["config"] === 'object') ?
        <>
          <InsightCard icon={WalletSolidSVG} title="Total Revenue"
                       value={`${(domObject["config"] && domObject["config"]["currency"]) ? domObject["config"]["currency"] : ""}
                         ${(businessInsight && businessInsight["total_revenue"]) ? formatAmountToString(businessInsight["total_revenue"]) : 0}`}/>
          <InsightCard icon={MapSolidSVG} title="Completed Trips"
                       value={(businessInsight && businessInsight["completed_trips"]) ? formatNumber(businessInsight["completed_trips"]) : 0}/>
        </>
        : <>
          <InsightCard icon={MapSolidSVG} title="Completed Trips"
                       value={(insight && insight["customer"] && insight["customer"]["completed_trips"]) ? formatNumber(insight["customer"]["completed_trips"]) : 0}/>

        </>
      }

      <InsightCard icon={BusSVG} title="Trips in Transit"
                   value={(insight && insight["customer"] && insight["customer"]["is_transit"]) ? formatNumber(insight["customer"]["is_transit"]) : 0}/>
      <InsightCard icon={UserCircleSVG} title="Awaiting Drivers"
                   value={(insight && insight["customer"] && insight["customer"]["awaiting_driver"]) ? formatNumber(insight["customer"]["awaiting_driver"]) : 0}/>
    </div>
  )
}

export default TripOverview;
