type Props = {
  type?: string,
  name: string,
  description: string
}

const PageInfo = ({ type, name, description }: Props) => (
  <div>
    {(type && type === "SMALL") ? <h5>{name}</h5> : <h3>{name}</h3>}
    <p className="text-gray-500">{description ? description : ""}</p>
  </div>
)

export default PageInfo;
