import { useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import toast from "react-hot-toast";

import {Loading} from "../../utils/helpers/constants";
import TokenHelper from "../../utils/helpers/tokenHelper";
import AuthService from "../../utils/services/auth.service";
import AuthLayout from "../../components/layouts/AuthLayout";
import BackSVG from "../../assets/images/svgs/arrow-left.svg";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { emailValidator } from "../../utils/services/validator.service";
import { InputValidator, MenuType, SubMenuType } from "../../modules/enums";

const ForgotPassword = () => {
  const isLoggedIn = TokenHelper.authenticationValid();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState({ value: "", errorState: false });
  const isInvalid =
    !email.value ||
    email.errorState ||
    emailValidator(email.value).validState === InputValidator.INVALID;

  const authenticateUser = async () => {
    setLoading(true);
    const payload = {
      email: email.value,
      callbackUrl: `${window.location.origin}/${MenuType.AUTH}/${SubMenuType.RESET_PASSWORD}`,
    };
    const { data: responseData } = await AuthService.forgotPassword(payload);
    if (responseData.status !== Loading.SUCCESS)
      toast.error(responseData.message);
    else toast.success(responseData.message);
    setLoading(false);
  };

  if (isLoggedIn) return <Navigate to={{ pathname: "/" }} />;

  return (
    <AuthLayout>
      <div>
        <NavLink to={`/${MenuType.AUTH}/${SubMenuType.LOGIN}`}>
          <p className="text-office-brown-800 mb-5">
            <img src={BackSVG} alt="GO_BACK" /> Back
          </p>
        </NavLink>
      </div>

      <h3 className="my-1 text-lg-start text-center">Forgot Password</h3>
      <p className="text-office-brown-700 mb-4 text-lg-start text-center">
        Enter your email address to recover your password
      </p>

      <div className="form-floating mb-5">
        <input
          type="text"
          placeholder="email address"
          value={email.value}
          className={`form-control form-lightblue ${email && email.errorState && "is-invalid"
            }`}
          onChange={(e) =>
            emailValidator(e.target.value).validState ===
              InputValidator.INVALID
              ? setEmail({
                value: e.target.value.toLowerCase(),
                errorState: true,
              })
              : setEmail({
                value: e.target.value.toLowerCase(),
                errorState: false,
              })
          }
        />
        <label>Email Address</label>
      </div>
      <PrimaryButton
        btn={{
          text: 'Send Recovery Link',
          loadState: loading,
          disabledState: isInvalid,
          btnClassName: 'mb-3 btn-block',
          clickFn: authenticateUser
        }}/>
    </AuthLayout>
  );
};

export default ForgotPassword;
