import { AxiosResponse } from 'axios';
import {dodgeInstance as Axios} from './axios.service';

const WalletService = {

  topUpWallet(payload: { amount: string, user_id: string, callbackUrl: string }): Promise<AxiosResponse<TApiResponse<{checkout_url: string}>, any>>  {
    return Axios({
      method: 'POST',
      url: `locale-service/wallets/user/top-up`,
      data: payload
    })
  },

};

export default WalletService;
