
import { useState, ReactElement, useMemo } from "react";
import { SubMenuType } from "../../modules/enums";
import Logo from "../../assets/images/pngs/truq-logo-white.png";

type Props = {
  step?: number,
  type?: SubMenuType,
  children: ReactElement | ReactElement[],
};

const AuthLayout = ({ children, type, step }: Props) => {
  const [bgImage, setBgImage] = useState('');


  useMemo(() => {
    if (type === SubMenuType.LOGIN) setBgImage('login-background')
    else if (type === SubMenuType.ONBOARD && step !== 1) setBgImage('user-background')
    else setBgImage('register-background');
  }, [step, type]);


  return (
    <div className="page">
      <div className="auth-wrapper">
        <div className="row">
          <div className={`d-none d-lg-block col-md-0 col-lg-6 ${bgImage}`}>
            <img src={Logo} alt="LOGO" className="auth-logo" />
          </div>
          <div className="col-md-12 col-lg-6 auth-form">
            <div className="w-450">
              <img src={Logo} alt="LOGO" width={60} height={25} className="ms-1 my-4 d-block d-lg-none" />
              <div className="auth-form-wrapper">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;