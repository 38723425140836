import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Logo from "../../assets/images/pngs/logo.png";
import UserSVG from "../../assets/images/svgs/user.svg"
import SearchSVG from "../../assets/images/svgs/search.svg";
import SideMenuSVG from "../../assets/images/svgs/sidemenu.svg";
import DropdownSVG from "../../assets/images/svgs/dropdown.svg";
// import MessageSVG from "../../assets/images/svgs/message-primary.svg";
// import NotificationSVG from "../../assets/images/svgs/notification-primary.svg";
import { TStoreState } from "../../modules/store";
import { DomType, MenuType } from "../../modules/enums";
import DropdownElement from "../dropdown/DropdownElement";
import DropdownComponent from "../dropdown/DropdownComponent";
import { toTitleCase } from "../../utils/helpers/logicHelper";
import { switchDomState } from "../../store/slices/auth.slice";
import { toggleSideMenuMode } from "../../store/slices/config.slice";


const Header = () => {
  const dispatch = useDispatch();
  const domType = useSelector((state: TStoreState) => state.auth.domType);
  const domObject = useSelector((state: TStoreState) => state.auth.domObject);
  const userProfile = useSelector((state: TStoreState) => state.auth.userProfile);
  const sideMenuMode = useSelector((state: TStoreState) => state.config.sideMenuMode);
  const businesses: TBusiness[] = useSelector((state: TStoreState) => state.auth.businesses);

  const activeDomSpan = <span className="fs-12px badge badge-success">Active</span>
  const setCustomerProfile = () => switchDomState(dispatch, {
    type: DomType.INDIVIDUAL, id: userProfile.id,
  })
  const setBusinessProfile = (business: TBusiness) => switchDomState(dispatch, { type: DomType.BUSINESS, id: business.id, business })
  const renderCustomerProfile = () => <>Customer Profile{domType === DomType.INDIVIDUAL && activeDomSpan}</>

  const renderBusinesses = (item: TBusiness) => (
    <>
      {item['is_merchant'] ? 'Merchant' : 'Business'} Profile: {toTitleCase(item.name)}
      {typeof domObject === 'object' && domObject.id === item.id && activeDomSpan}
    </>
  )

  return (
    <header className="main-header sticky sticky-pin">
      <div className="container-fluid">
        <div className="main-header-left">
          <div className="d-block d-lg-none">
            <img src={SideMenuSVG} alt="LOGO" className="me-3"
              width={20} height={20}
              onClick={() => dispatch(toggleSideMenuMode(!sideMenuMode))} />
            <NavLink to="/">
              <img src={Logo} alt="logo" width={50} height={20} />
            </NavLink>
          </div>
        </div>
        <div className="main-header-right">
          <div className="position-relative me-3 d-none">
            <input
              type="text" className="form-control  d-none d-lg-block" placeholder="Search here..." />
            <img src={SearchSVG} className="search-icon" alt="SEARCH_ICON" />
          </div>
          <div className="d-flex">
            {/*<img src={NotificationSVG} alt="NOTIFICATION_ICON"  width={28} height={28}/>*/}
            {/*<img src={MessageSVG} alt="MESSAG_ICON"  className="mx-2" width={30} height={30}/>*/}
            <NavLink to={`/${MenuType.SETTINGS}`}>
              {(userProfile && userProfile["picture"])
                ? <img src={userProfile["picture"]} width={37} height={37} alt="PROFILE" className="rounded-circle" />
                : <div className="main-profile-menu bg-light-orange">
                  <img src={UserSVG} alt="user-icon" width={20} height={20} />
                </div>
              }
            </NavLink>
            <DropdownComponent drop="down" className="btn-link header-dropdown" variant="link" imgSrc={DropdownSVG}
              imgAlt="dropdown">
              <p className="fw-bold border-bottom border-gray-100 mb-1 ps-1 py-3">Switch Accounts</p>
              <DropdownElement text={renderCustomerProfile()} divider={true} clickFn={() => setCustomerProfile()} />
              {businesses && businesses.map((item, index) =>
                <DropdownElement key={index} divider={true} clickFn={() => setBusinessProfile(item)}
                  text={renderBusinesses(item)}
                />
              )}
            </DropdownComponent>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
