import { ColumnDef, ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { TripMap } from "../../../utils/helpers/mappers";
import { TripSystemStatus } from "../../../modules/enums";
import { toTitleCase } from "../../../utils/helpers/logicHelper";

const column: ColumnHelper<TTrip> = createColumnHelper<TTrip>()

export const TripsColumns: ColumnDef<TTrip, any>[] = [
	column.accessor('reference', {
		header: () => <span>Reference</span>,
		cell: info => <span className="text-truncate">{info.getValue() ? info.getValue() : '-'}</span>,
		footer: info => info.column.id,
	}),
	column.accessor('user.first_name', {
		header: () => <span>Customer</span>,
		cell: info => <span className="text-truncate">
			{info.getValue() ? `${info.getValue() ? toTitleCase(info.getValue()) : "-"}
        ${(info.row.original.user && info.row.original.user.last_name) ? ` ${toTitleCase(info.row.original.user.last_name)}` : ""}` : '-'
			}
		</span>,
		footer: info => info.column.id,
	}),
	column.accessor('created_at', {
		header: () => <span>Date Created</span>,
		cell: info => <span className="text-truncate">{(info.getValue() && moment(info.getValue()).isValid()) ? moment(info.getValue()).format("ll") : '-'}</span>,
		footer: info => info.column.id,
	}),
	column.accessor('source', {
		header: () => <span>Pick Up Location</span>,
		cell: info => <span className="text-truncate">
			{(info.row.original.source && info.row.original.source.address) ? info.row.original.source.address : '-'}
		</span>,
		footer: info => info.column.id,
	}),
	column.accessor('destination', {
		header: () => <span>Delivery Location</span>,
		cell: info => <span className="text-truncate">{(info.row.original.destination && info.row.original.destination.address) ? info.row.original.destination.address : '-'}</span>,
		footer: info => info.column.id,
	}),
	column.accessor('system_status', {
		header: () => <span>Status</span>,
		cell: info => <span className={info.getValue() ? `badge badge-${TripMap[info.getValue() as TripSystemStatus].fill}` : 'badge badge-danger'}>
			{info.getValue() ? TripMap[info.getValue() as TripSystemStatus].label : "OTHER"}
		</span>,
		footer: info => info.column.id,
	}),
];