import { ReactNode } from "react";
import {Dropdown} from "react-bootstrap";
import { TImage } from "../../modules/component";
import { DropDirection } from "react-bootstrap/esm/DropdownContext";

type DropdownComponentProps = TImage & {
  variant: string,
  className:string,
  children: ReactNode,
  drop?: DropDirection,
}

const DropdownComponent = ( { className, drop, variant, imgSrc , imgAlt , children }: DropdownComponentProps) => (
  <Dropdown className={className} drop={drop}>
    <Dropdown.Toggle variant={variant} size="sm">
      <img src={imgSrc} alt={imgAlt} width={16} height={16}/>
    </Dropdown.Toggle>
    <Dropdown.Menu>{children}</Dropdown.Menu>
  </Dropdown>
);

export default DropdownComponent;
